import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import Pagination from 'react-bootstrap/Pagination';

import { useWatchScreenWidth } from '../../../hooks/useWatchScreenWidth';
import '../../../css/ProjectFloorPlanTab.min.css';

const CONTENT = [
  "/klos-service/floor_plan_jpg/tmp/floor_plan_jpg/fl1.jpg",
  "/klos-service/floor_plan_jpg/tmp/floor_plan_jpg/fl2.jpg",
  "/klos-service/floor_plan_jpg/tmp/floor_plan_jpg/fl3.jpg",
  "/klos-service/floor_plan_jpg/tmp/floor_plan_jpg/fl4.jpg",
  "/klos-service/floor_plan_jpg/tmp/floor_plan_jpg/fl5.jpg",
  "/klos-service/floor_plan_jpg/tmp/floor_plan_jpg/fl6.jpg",
  "/klos-service/floor_plan_jpg/tmp/floor_plan_jpg/fl7.jpg",
  "/klos-service/floor_plan_jpg/tmp/floor_plan_jpg/fl8.jpg",
  "/klos-service/floor_plan_jpg/tmp/floor_plan_jpg/roof.jpg",
];
const base_url = process.env.REACT_APP_IMAGE_BASE_URL;
export default function ProjectFloorPlanTab() {
  const { screenWidth } = useWatchScreenWidth();
  const [page, setPage] = useState({
    activePage: 0,
    paginationItems: [0, 1, 2, 3, 4, 5, 6, 7, 8],
  });

  return (
    <div className="project-floor-plan-tab-container">
      <Carousel
        activeIndex={page.activePage}
        className={`project-floor-plan-tab-carousel ${CONTENT.length > 1 ? '' : 'hidden-indicators'}`}
        controls={CONTENT.length > 1}
        indicators={false}
        interval={5000}
        onSelect={(activePage) => setPage((prev) => ({ ...prev, activePage }))}
      >
        {CONTENT.map((image, index) => (
          <Carousel.Item key={index}>
            <Image alt="#" src={`${base_url}${image}`} fluid />
          </Carousel.Item>
        ))}
      </Carousel>
      <Pagination className="project-floor-plan-pagination">
        {page.paginationItems.map((item, index) => (
          <Pagination.Item
            key={index}
            active={page.activePage === index}
            className={`${screenWidth < 992 && page.activePage !== index ? 'd-none' : ''}`}
            onClick={() => setPage((prev) => ({ ...prev, activePage: index }))}
          >
            {index === page.paginationItems[page.paginationItems.length - 1] ? 'Roof' : `FL ${item + 1}`}
          </Pagination.Item>
        ))}
      </Pagination>
    </div>
  );
}
