import { axiosInstance } from '../../customAxios';

export default async function logout() {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/customer/logout`);
    return { status: true, data: response };
  } catch (error) {
    return { status: false, error };
  }
}
