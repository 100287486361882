import axios from 'axios';

export default async function loginVerifyOTP(data) {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/customer/login/otp-verify`, data);
    return { status: true, data: response?.data };
  } catch (error) {
    return { status: false, error };
  }
}
