import gsap from 'gsap';
import { capitalize } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';

import {
  InformationIconSVG,
  UnitCardOverlayHeartIconSVG,
  UnitHeartIconSVG,
  UnitHeartFilledIconSVG,
  UnitBedroomIconSVG,
  UnitSizeIconSVG,
  UnitFloorIconSVG,
  UnitCardOverlayEyeIconSVG,
} from '../../../assets';
import { favoriteUpsert } from '../../../api/favorite';
import LoginRegisterModal from '../../modals/login-register-modal';
import { getNumDecimals, formatNumber } from '../../../utils/numbers';
import { accessToken } from '../../../utils/tokens';

import '../../../css/UnitCard.min.css';

export default function UnitCard({
  thumbnail = null,
  numFavorites = null,
  numViews = null,
  isMyFavorite = false,
  favoriteRemove = () => null,
  unitType = null,
  unitSize = null,
  unitFloor = null,
  unitNumber = null,
  isSoldOut = false,
  unitPriceNow = null,
  unitPriceBefore = null,
  bookingPeriodID = null,
  unitID = null,
  showOverlay = true,
}) {
  const [location, setLocation] = useLocation();
  const { t } = useTranslation();

  const [isFavorite, setIsFavorite] = useState(isMyFavorite);
  // * isFavorite value for UnitCard in the same position won't reset when navigating pages without this
  useEffect(() => {
    setIsFavorite(isMyFavorite);
  }, [unitNumber]);
  const [showModal, setShowModal] = useState(false);
  const toggleIsFavorite = async () => {
    if (!accessToken) {
      setShowModal(true);
      return;
    }
    const { status } = await favoriteUpsert({ unit_id: unitID, is_like: !isFavorite });
    if (status) setIsFavorite((isFavorite) => !isFavorite);
  };

  const unitCardRef = useRef(null);
  const unitCardHoverAnimation = (instruction) => {
    if (instruction === -1) {
      gsap.fromTo(unitCardRef.current, { scale: 1.01 }, { duration: 0.3, scale: 1 });
    } else {
      gsap.fromTo(unitCardRef.current, { scale: 1 }, { duration: 0.3, scale: 1.01 });
    }
  };

  useEffect(() => {
    if (location === '/my-favorite' && !isFavorite) {
      unitCardRef.current?.remove();
      favoriteRemove();
    }
  }, [isFavorite]);

  return (
    <>
      <Card
        className="unit-card border-0"
        {...(!showOverlay ? { style: { boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.3)' } } : {})}
        ref={unitCardRef}
        onMouseEnter={unitCardHoverAnimation}
        onMouseLeave={() => unitCardHoverAnimation(-1)}
      >
        {showOverlay && (
          <Container onClick={toggleIsFavorite} bsPrefix="unit-card-overlay-favorite-icon">
            {isFavorite ? (
              // Temporary icon, couldn't export icon in design as the group included padding
              <Image height={26} width={24} src={UnitHeartFilledIconSVG} fluid />
            ) : (
              <Image height={26} width={24} src={UnitHeartIconSVG} fluid />
            )}
          </Container>
        )}

        <div onClick={() => setLocation(`/unit-details/${bookingPeriodID}/${unitID}`)}>
          {thumbnail ? <Card.Img alt="#" src={thumbnail} variant="top" /> : <div className="empty-unit-card-thumbnail"></div>}
          {showOverlay && (
            <Container className="unit-card-overlay-container">
              <Container bsPrefix="unit-card-overlay-stat-container">
                <Image src={UnitCardOverlayHeartIconSVG} fluid />
                <p>{formatNumber(numFavorites)}</p>
              </Container>
              <Container bsPrefix="unit-card-overlay-stat-container">
                <Image src={UnitCardOverlayEyeIconSVG} fluid />
                <p>{formatNumber(numViews)}</p>
              </Container>
            </Container>
          )}
          <Card.Body>
            <Row>
              <Col>
                <Row className="unit-card-body-row">
                  <Col>
                    <Image height={20} width={20} src={UnitBedroomIconSVG} fluid />
                  </Col>
                  <Col className="unit-other-detail-text">{(unitType?.label || unitType) ?? '-'}</Col>
                </Row>
              </Col>
              <Col className="unit-card-body-divider-col">
                <Row className="unit-card-body-row">
                  <Col>
                    <Image height={20} width={20} src={UnitSizeIconSVG} fluid />
                  </Col>
                  <Col className="unit-other-detail-text">{`${formatNumber(unitSize, getNumDecimals(unitSize))} ${t(
                    'square-meter'
                  )}`}</Col>
                </Row>
              </Col>
              <Col>
                <Row className="unit-card-body-row">
                  <Col>
                    <Image height={20} width={20} src={UnitFloorIconSVG} fluid />
                  </Col>
                  <Col className="unit-other-detail-text">{`${capitalize(t('floor'))} ${unitFloor?.label ?? '-'}`}</Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col>
                <Row className="unit-card-footer-row">
                  <Col>{capitalize(t('unit-number'))}</Col>
                  <Col id="unit-number">{unitNumber}</Col>
                </Row>
              </Col>
              <Col>
                <Row className="unit-card-footer-row">
                  <Col className="d-flex justify-content-end">{capitalize(t('price-per-unit'))}</Col>
                  <Col className="d-flex justify-content-end" id={isSoldOut ? '' : 'unit-price-now'}>
                    {isSoldOut ? (
                      <div className="unit-sold-out-div">
                        <Image height={16} width={16} src={InformationIconSVG} fluid />
                        <p>ขายแล้ว</p>
                      </div>
                    ) : (
                      formatNumber(unitPriceNow)
                    )}
                  </Col>
                  <Col className="d-flex justify-content-end">
                    {unitPriceBefore && !isSoldOut ? (
                      <p id="unit-price-before">{formatNumber(unitPriceBefore)}</p>
                    ) : (
                      // To keep card sizing consistent even if unit has no price before
                      <p className="invisible" id="unit-price-before">
                        0
                      </p>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Footer>
        </div>
      </Card>
      <LoginRegisterModal show={showModal} onHide={() => setShowModal(false)} />
    </>
  );
}
