import { axiosInstance } from '../../customAxios';
import qs from 'qs';

export default async function createBooking(bookingPeriodID, unitID) {
  try {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/booking/${bookingPeriodID}`,
      qs.stringify({ unit_id: unitID }),
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
    );
    return { status: true, data: response?.data?.result };
  } catch (error) {
    return { status: false, error };
  }
}
