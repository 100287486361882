import { capitalize } from 'lodash';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'wouter';

import { createBooking } from '../../../api/booking';
import { RecaptchaModalIconSVG } from '../../../assets';
import { sizedBoxVerticalS } from '../../sized-box';
import { capitalizeTranslationSection } from '../../../utils/formatTranslation';

import '../../../css/RecaptchaModal.min.css';

export default function RecaptchaModal({ show, onHide, enableBooking }) {
  const [, setLocation] = useLocation();
  const params = useParams();
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState(null);
  async function handleStartBooking() {
    const { status, data, error } = await createBooking(params?.bookingPeriodID, params?.unitID);
    if (status) {
      setLocation(`/booking/booking-details/${params?.bookingPeriodID}/${data?.id}/${params?.unitID}`);
      sessionStorage.setItem('expireAt', data?.expire);
    } else {
      setErrorMessage(error?.response?.data?.message);
    }
  }

  const handleHide = () => {
    setErrorMessage(null);
    onHide();
  };

  return (
    <Modal centered onHide={handleHide} show={show}>
      <Modal.Header closeButton closeVariant="white">
        <Image src={RecaptchaModalIconSVG} fluid />
        <Modal.Title>{capitalize(t('begin-booking'))}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="recaptcha-modal-body">
        {capitalizeTranslationSection(t('recaptcha-modal-body'), '. ')}
        {sizedBoxVerticalS}
        <p className="error-message">{!enableBooking ? 'การยืนยันด้วย reCAPTCHA ไม่สำเร็จ กรุณาลองใหม่' : errorMessage}</p>
      </Modal.Body>
      <Modal.Footer className="recaptcha-modal-footer">
        <Container className="recaptcha-modal-footer-container" fluid>
          <Button onClick={handleHide} variant="outline-danger">
            {capitalize(t('later'))}
          </Button>
          <Button disabled={!enableBooking} onClick={handleStartBooking} variant="danger">
            {capitalize(t('book-now'))}
          </Button>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}
