import { forwardRef } from 'react';

export const TransparentDropdown = forwardRef(({ children, onClick }, ref) => (
  <div
    href=""
    ref={ref}
    onClick={(e) => {
      onClick(e);
    }}
  >
    {children}
  </div>
));
