import axios from 'axios';
import dayjs from 'dayjs';
import gsap from 'gsap';
import { capitalize } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useParams } from 'wouter';

import {
  UnitDetailsPhoneIconSVG,
  UnitCardOverlayHeartIconSVG,
  UnitCardOverlayEyeIconSVG,
  UnitDetailsEmailIconSVG,
  UnitDetailsFacebookIconSVG,
  UnitDetailsHeartIconSVG,
  UnitDetailsHeartIconActiveSVG,
} from '../../../assets';
import { siteVerify } from '../../../api/auth';
import { listFloors, listUnitTypes } from '../../../api/master-data';
import getBookingPeriod from '../../../api/booking-period';
import { favoriteUpsert } from '../../../api/favorite';
import { getUnit } from '../../../api/units/';
import FloorplanLoader from '../../floorplan-loader';
import LoginRegisterModal from '../../modals/login-register-modal';
import RecaptchaModal from '../../modals/recaptcha-modal';
import { sizedBoxVerticalXS, sizedBoxVerticalS } from '../../sized-box';
import { formatNumber, getNumDecimals } from '../../../utils/numbers';
import { accessToken } from '../../../utils/tokens';
import { clearHistory, initHistory } from '../../../utils/history';

import '../../../css/UnitDetails.min.css';

export default function UnitDetails({ bookingInformation, periodInformation }) {
  const { t } = useTranslation();
  const params = useParams();

  const [timeDifference, setTimeDifference] = useState(0);
  useEffect(() => {
    clearHistory();
    initHistory();
    const getBookingPeriodInfo = async () => {
      const { status, data } = await getBookingPeriod();
      const {
        data: {
          result: { datetime },
        },
      } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/mst/times/current`);
      if (status) {
        const { start_date: startDate } = data;
        setTimeDifference(dayjs(startDate).diff(dayjs(datetime ?? new Date()), 'ms'));
      }
    };
    getBookingPeriodInfo();
  }, []);

  const [unitDetails, setUnitDetails] = useState({});
  const [isFavorite, setIsFavorite] = useState(false);
  const [unitStatus, setUnitStatus] = useState(undefined);
  useEffect(() => {
    async function getUnitDetails() {
      const { data: floors } = await listFloors();
      const { data: unitTypes } = await listUnitTypes();
      const { data } = await getUnit(params.bookingPeriodID, params.unitID);

      const currentFloor = floors?.find((option) => option?.value === data?.unit_floor_id);
      setUnitDetails({
        ...data,
        floor_plan: currentFloor,
        unit_floor: currentFloor?.label,
        unit_types: unitTypes?.find((option) => option?.value === data?.unit_types_id)?.label,
      });
      setIsFavorite(data?.is_liked);
      setUnitStatus(data?.sale_status);
    }
    getUnitDetails();
  }, [bookingInformation]);

  const [showImage, setShowImage] = useState({
    index: 0,
    show: false,
  });
  const [showRecaptcha, setShowRecaptcha] = useState(false);

  const toggleIsFavorite = async () => {
    if (!accessToken) {
      setShowLoginRegisterModal(true);
      return;
    }
    const { status } = await favoriteUpsert({ unit_id: params.unitID, is_like: !isFavorite });
    if (status) {
      setIsFavorite((isFavorite) => !isFavorite);
      favoriteAnimation();
    }
  };
  const [showLoginRegisterModal, setShowLoginRegisterModal] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [enableBooking, setEnableBooking] = useState(false);
  const handleVerifyRecaptcha = async () => {
    setEnableBooking(false);
    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available');
      return;
    }

    try {
      const token = await executeRecaptcha('booking');
      const { status, data } = await siteVerify(token);
      // console.log('reCAPTCHA data:', data);
      // * Comment out "&& data?.success" to test locally
      if (status && data?.success) setEnableBooking(true);
      setShowRecaptcha(true);
    } catch (error) {
      console.error(error);
    }
  };
  const handleBooking = () => {
    if (accessToken) {
      handleVerifyRecaptcha();
    } else {
      setShowLoginRegisterModal(true);
    }
  };

  // Return value to be used for disabling button (i.e. true means button is disabled)
  const socketEventToBoolean = (event) => {
    switch (event) {
      case 'BookingCustomerCanceled':
      case 'BookingExpired':
      case 'BookingFailed':
      case 'PeriodEventButton':
        return false;
      default:
        return true;
    }
  };

  const favoriteIconRef = useRef(null);
  const favoriteAnimation = () => {
    const timeline = gsap.timeline();
    timeline.to(favoriteIconRef.current, { duration: 0.3, scale: 1.1 });
    timeline.to(favoriteIconRef.current, { duration: 0.2, scale: 1 });
    timeline.play();
  };

  const shouldHidePrice =
    (bookingInformation?.data?.unit_id === unitDetails?.unit_id && bookingInformation?.event === 'BookingSucceed') ||
    unitStatus?.status === 'BookingSucceed';

  return (
    <>
      <Container className="unit-details-heading-container" fluid>
        <p id="unit-details-heading">
          {capitalize(t('unit-number'))}
          <br />
          <span id="unit-details-heading-number">{unitDetails.unit_no ?? '-'}</span>
        </p>
        <hr></hr>
      </Container>
      <Container bsPrefix="unit-details-container">
        <Row xs={1} sm={1} md={2}>
          <Col className="carousel-column">
            {unitDetails.images?.length > 0 ? (
              <>
                <Carousel
                  className={unitDetails.images?.length > 1 ? '' : 'hidden-indicators'}
                  controls={unitDetails.images?.length > 1}
                  interval={5000}
                >
                  {unitDetails.images?.map((image, index) => (
                    <Carousel.Item key={index} onClick={() => setShowImage({ index, show: true })}>
                      <Image alt="#" src={`${process.env.REACT_APP_IMAGE_BASE_URL}${image.full_path}`} fluid />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </>
            ) : (
              <div className="empty-unit-image"></div>
            )}
          </Col>
          <Col className="unit-details-column">
            <Container fluid>
              <Row xs={2} className="unit-details-heading-row">
                <Col className="unit-details-heading-column">{capitalize(t('room-details'))}</Col>
                <Container>
                  <Row className="unit-details-heading-icons-container">
                    <Col>
                      <Image height={16} width={16} src={UnitCardOverlayHeartIconSVG} />
                      <p>{unitDetails.favorite_count}</p>
                    </Col>
                    <Col>
                      <Image height={16} width={16} src={UnitCardOverlayEyeIconSVG} />
                      <p>{unitDetails.views}</p>
                    </Col>
                  </Row>
                </Container>
              </Row>

              {sizedBoxVerticalXS}
              <Row className="unit-details-row">
                <Col>
                  {capitalize(t('room-type'))}
                  <br />
                  <span className="unit-detail-highlight">{unitDetails.unit_types ?? '-'}</span>
                </Col>
                <Col>
                  <Row className="unit-detail-middle-row">
                    <Col className="divider-column">
                      <hr></hr>
                    </Col>
                    <Col>
                      {capitalize(t('room-size'))}
                      <br />
                      <span className="unit-detail-highlight">{`${formatNumber(
                        unitDetails.size,
                        getNumDecimals(unitDetails.size)
                      )} ${capitalize(t('square-meter'))}`}</span>
                    </Col>
                    <Col className="divider-column">
                      <hr></hr>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  {capitalize(t('floor'))}
                  <br />
                  <span className="unit-detail-highlight">{unitDetails.unit_floor ?? '-'}</span>
                </Col>
              </Row>
              {sizedBoxVerticalXS}

              <Row xs={1} className="unit-prices-row">
                <Col>
                  <Container className="unit-price-row-container">
                    <Row className="unit-price-row unit-normal-price-row">
                      <Col>{capitalize(t('normal-price'))}</Col>
                      <Col className="unit-price-amount-column">
                        {shouldHidePrice ? `-` : `${formatNumber(unitDetails.regular_price)} ${capitalize(t('baht'))}`}
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col>
                  <Container className="unit-price-row-container">
                    <Row className="unit-price-row">
                      <Col>{capitalize(t('special-price'))}</Col>
                      <Col className="unit-price-amount-column unit-special-price-text">
                        {shouldHidePrice
                          ? `-`
                          : unitDetails.net_price < unitDetails.regular_price
                          ? `${formatNumber(unitDetails.net_price)} ${capitalize(t('baht'))}`
                          : '-'}
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col>
                  <Container className="unit-price-row-container">
                    <Row className="unit-price-row unit-booking-fees-row">
                      <Col>{capitalize(t('booking-fees'))}</Col>
                      <Col className="unit-price-amount-column unit-booking-fees-text">
                        {shouldHidePrice ? `-` : `${formatNumber(unitDetails.booking_price)} ${capitalize(t('baht'))}`}
                      </Col>{' '}
                    </Row>
                  </Container>
                </Col>
              </Row>

              {sizedBoxVerticalS}
              <Row className="unit-details-icons-actions-row">
                <Col className="unit-details-icons-column">
                  <Row className="unit-details-icons-row">
                    <Col xs={2}>
                      <a
                        // href="https://www.facebook.com/sharer/sharer.php?u=https://www.forviz.com"
                        href="https://www.facebook.com/profile.php?id=61551475042677"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Image height={24} width={24} src={UnitDetailsFacebookIconSVG} fluid />
                      </a>
                    </Col>
                    <Col xs={2}>
                      <a href="mailto:th.fph.klosratchada@frasersproperty.com" rel="noreferrer" target="_blank">
                        <Image height={24} width={24} src={UnitDetailsEmailIconSVG} fluid />
                      </a>
                    </Col>
                    <Col xs={8}>
                      <p className="unit-details-phone-text">หรือโทร. 063-207-6000</p>
                      <a className="unit-details-phone-icon" href="tel:0632076000">
                        <Image height={24} width={24} src={UnitDetailsPhoneIconSVG} />
                      </a>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="unit-details-heart-icon-column" onClick={toggleIsFavorite}>
                      {isFavorite ? (
                        <Image ref={favoriteIconRef} src={UnitDetailsHeartIconActiveSVG} fluid />
                      ) : (
                        <Image ref={favoriteIconRef} src={UnitDetailsHeartIconSVG} fluid />
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col className="unit-details-button-column">
                  {periodInformation?.event !== 'PeriodEventButton' ? (
                    !bookingInformation?.data?.unit_id !== unitDetails?.unit_id ? (
                      <Button
                        disabled={unitStatus?.available === false || timeDifference > 0}
                        onClick={handleBooking}
                        variant="danger"
                      >
                        {unitStatus?.status === 'BookingSucceed' ? capitalize(t('sold')) : capitalize(t('book-now'))}
                      </Button>
                    ) : (
                      <Button
                        disabled={socketEventToBoolean(bookingInformation?.event) || timeDifference > 0}
                        onClick={handleBooking}
                        variant="danger"
                      >
                        {bookingInformation?.event === 'BookingSucceed' ? capitalize(t('sold')) : capitalize(t('book-now'))}
                      </Button>
                    )
                  ) : (
                    <Button disabled={unitStatus?.available === false} onClick={handleBooking} variant="danger">
                      {capitalize(t('book-now'))}
                    </Button>
                  )}
                </Col>
                {unitStatus?.available === false && unitStatus?.status !== 'BookingSucceed' && (
                  <Col xs={12} id="unit-unavailable-message">
                    * ขณะนี้มีลูกค้าท่านอื่นกำลังทำรายการอยู่ กรุณาลองใหม่อีกครั้งหลังผ่านไป 15 นาที
                  </Col>
                )}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

      <Container className="unit-location-container" fluid>
        <Row className="unit-location-row">
          <Col id="show-unit-location-text">{capitalize(t('show-room-location'))}</Col>
          <Col>
            {unitDetails.floor_plan ? (
              <FloorplanLoader
                floorplan={unitDetails.floor_plan}
                selectedUnit={unitDetails?.unit_no}
                floorName={unitDetails?.unit_floor}
              />
            ) : (
              <p className="empty-floors-text">ไม่มีข้อมูลชั้นให้แสดง ณ ขณะนี้</p>
            )}
          </Col>
        </Row>
      </Container>

      <Modal
        className="unit-details-modal"
        centered
        onHide={(prev) => setShowImage({ ...prev, show: false })}
        show={showImage.show}
      >
        <Modal.Body>
          <Image
            src={`${process.env.REACT_APP_IMAGE_BASE_URL}${
              unitDetails.images ? unitDetails.images[showImage.index]?.full_path : null
            }`}
            fluid
          />
        </Modal.Body>
      </Modal>

      <LoginRegisterModal onHide={() => setShowLoginRegisterModal(false)} show={showLoginRegisterModal} />
      <RecaptchaModal onHide={() => setShowRecaptcha(false)} show={showRecaptcha} enableBooking={enableBooking} />
    </>
  );
}
