import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import { useLocation } from 'wouter';

import { FailedIconSVG, SuccessIconSVG } from '../../../assets';

export default function RegisterResultModal({ show, onHide, isSuccess, message }) {
  const [location, setLocation] = useLocation();

  function handleResult() {
    if (isSuccess) {
      setLocation('/');
      window.location = window.location;
    } else {
      onHide();
    }
  }

  return (
    <Modal centered onHide={onHide} show={show}>
      <Modal.Header closeButton closeVariant="white">
        {isSuccess ? (
          <>
            <Image src={SuccessIconSVG} />
            <Modal.Title>ลงทะเบียนสำเร็จ</Modal.Title>
          </>
        ) : (
          <>
            <Image src={FailedIconSVG} />
            <Modal.Title>{location === '/edit-profile' ? 'การอัพเดทข้อมูลไม่สำเร็จ' : 'ลงทะเบียนไม่สำเร็จ'}</Modal.Title>
          </>
        )}
      </Modal.Header>
      {isSuccess ? (
        <Modal.Body>ลงทะเบียนสำเร็จ ท่านสามารถเข้าสู่ระบบเพื่อใช้งานระบบจองออนไลน์ได้ทันที</Modal.Body>
      ) : (
        <Modal.Body>{message ?? 'กรุณาตรวจสอบข้อมูลอีกครั้ง'}</Modal.Body>
      )}
      <Modal.Footer style={{ maxWidth: 198, width: '100%' }}>
        <Button onClick={handleResult} variant="danger">
          {isSuccess ? 'ไปยังหน้าเข้าสู่ระบบ' : 'ปิด'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
