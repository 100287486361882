import { capitalize } from 'lodash';
import Carousel from 'react-bootstrap/Carousel';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';

import { formatNumber, getNumDecimals } from '../../utils/numbers';
import '../../css/RoomDetailsSummarySection.min.css';

export default function RoomDetailsSummarySection({
  images = null,
  unitNumber = null,
  roomType = null,
  roomSize = null,
  floor = null,
}) {
  const { t } = useTranslation();

  return (
    <Container className="booking-details-all-unit-details-container" fluid>
      <Row className="booking-details-all-unit-details-row">
        <Col className="booking-details-unit-image-column">
          {images?.length > 0 ? (
            <Carousel className={images?.length > 1 ? '' : 'hidden-indicators'} controls={images?.length > 1} interval={5000}>
              {images?.map((image) => (
                <Carousel.Item>
                  <Image src={`${process.env.REACT_APP_IMAGE_BASE_URL}${image.full_path}`} fluid />
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <div className="empty-unit-image"></div>
          )}
        </Col>
        <Col className="booking-details-unit-details-column">
          <Container>
            <Row className="booking-details-unit-details-row">
              <Col id="booking-details-unit-details-heading">{capitalize(t('room-details'))}</Col>
              <Col className="booking-details-unit-details-text-container-column">
                <Row xs={2}>
                  <Col className="booking-details-unit-details-text-column first-item">
                    {capitalize(t('unit-number'))}
                    <br />
                    <span className="booking-details-unit-detail-highlight">{unitNumber ?? '-'}</span>
                  </Col>
                  <Col className="booking-details-unit-details-text-column second-item">
                    {capitalize(t('room-type'))}
                    <br />
                    <span className="booking-details-unit-detail-highlight">{roomType ?? '-'}</span>
                  </Col>
                </Row>
                <Row xs={2}>
                  <Col className="booking-details-unit-details-text-column third-item">
                    {capitalize(t('room-size'))}
                    <br />
                    <span className="booking-details-unit-detail-highlight">{`${formatNumber(
                      roomSize,
                      getNumDecimals(roomSize)
                    )} ${t('square-meter')}`}</span>
                  </Col>
                  <Col className="booking-details-unit-details-text-column last-item">
                    ชั้น
                    <br />
                    <span className="booking-details-unit-detail-highlight">{floor ?? '-'}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
