import { useEffect, useState } from 'react';

export const useWatchScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleScreenResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleScreenResize);
    return () => window.removeEventListener('resize', handleScreenResize);
  }, []);

  return { screenWidth };
};
