import { axiosInstance } from '../../customAxios';

export default async function getCustomerDetails() {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/v1/customers/me`);
    return { status: true, data: response?.data?.result };
  } catch (error) {
    return { status: false, error };
  }
}
