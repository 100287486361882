import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'wouter';

import { BookingStatusLogoSVG } from '../../../assets';
import {
  sizedBoxVerticalXXS,
  sizedBoxVerticalXS,
  sizedBoxVerticalS,
  sizedBoxVerticalM,
  sizedBoxVerticalL,
} from '../../sized-box';
import { formatNumber, getNumDecimals } from '../../../utils/numbers';
import { capitalizeTranslationSection, capitalizeTranslationWithAcronyms } from '../../../utils/formatTranslation';

import '../../../css/BookingDetailsCard.min.css';

export default function BookingDetailsCard({
  onlineBookingNumber = null,
  unitNumber = null,
  roomType = null,
  roomSize = null,
  floor = null,
  paymentDate = null,

  roomPrice = null,
  discount = null,
  netPrice = null,
  depositFees = null,
  contractFees = null,
  downPaymentNumberOfInstallments = null,
  downPaymentAmountPerInstallment = null,
  balloonPaymentNumberOfInstallments = null,
  balloonPaymentAmountPerInstallment = null,
  totalDownPaymentAmount = null,
  ownershipTransferFees = null,

  paymentType = null,
  amountPaid = null,
  fullName = null,
  thaiNationalIDCardNumber = null,
  phoneNumber = null,
  email = null,
  addressNumber = null,
  village = null,
  group = null,
  alley = null,
  street = null,
  province = null,
  district = null,
  subdistrict = null,
  postalCode = null,
}) {
  require('dayjs/locale/th');

  const [location] = useLocation();
  const params = useParams();
  const { t } = useTranslation();

  return (
    <div id="booking-details-card-background">
      <Container className="booking-details-card-container" fluid>
        {sizedBoxVerticalS}
        <Card className="booking-details-card">
          {!location.includes('booking-complete') && <Card.Img height={38} variant="top" src={BookingStatusLogoSVG} fluid />}
          <Card.Body>
            {location.includes('booking-details') && <Card.Title>{capitalize(t('details'))}</Card.Title>}
            {location.includes('booking-complete') && (
              <>
                <Row className="booking-details-card-heading-row">
                  <Col>
                    {capitalizeTranslationSection(t('online-booking-number'), ' ')}
                    <br />
                    <span className="booking-details-card-booking-number">{onlineBookingNumber}</span>
                  </Col>
                  <Col xs={12}>
                    <Row xs={1} sm={4} className="booking-details-card-unit-details-row">
                      <Col>
                        {capitalize(t('unit-number'))}
                        <br />
                        <span className="unit-details-highlight">{unitNumber}</span>
                      </Col>
                      <Col className="booking-details-card-column-with-seperator">
                        <div className="border-seperator border-seperator-left"></div>
                        <div>
                          {capitalize(t('room-type'))}
                          <br />
                          <span className="unit-details-highlight">{`${roomType}`}</span>
                        </div>
                        <div className="border-seperator border-seperator-right"></div>
                      </Col>
                      <Col className="booking-details-card-column-with-seperator">
                        <div>
                          {capitalize(t('room-size'))}
                          <br />
                          <span className="unit-details-highlight">{`${formatNumber(roomSize, getNumDecimals(roomSize))} ${t(
                            'square-meter'
                          )}`}</span>
                        </div>
                        <div className="border-seperator border-seperator-right"></div>
                      </Col>
                      <Col className="booking-details-card-column-without-seperator">
                        <div>
                          ชั้น
                          <br />
                          <span className="unit-details-highlight">{floor}</span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="booking-details-card-payment-date-column">
                    <Container className="booking-details-card-payment-date">
                      <p>{`${capitalize(t('payment-completed-on'))} - ${dayjs().locale('th').format('D MMMM YYYY')}`}</p>
                    </Container>
                  </Col>
                </Row>
              </>
            )}
            <Container>
              <Row className="booking-details-card-text-columns-row">
                <Col className="booking-details-card-text-column">
                  <Container className="booking-details-card-text-row-container">
                    <Row className="booking-details-card-text-row">
                      <Col>{capitalize(t('room-price'))}</Col>
                      <Col className="booking-details-card-number-column">{`${formatNumber(roomPrice)} ${capitalize(
                        t('baht')
                      )}`}</Col>
                    </Row>
                    <Row className="booking-details-card-text-row">
                      <Col>{capitalize(t('discount'))}</Col>
                      <Col className="booking-details-card-number-column red-highlight">
                        {`${formatNumber(discount)} ${capitalize(t('baht'))}`}
                      </Col>
                    </Row>
                    <Row id="booking-details-card-total-price-row">
                      <Col>{capitalize(t('net-price'))}</Col>
                      <Col className="booking-details-card-number-column red-highlight">
                        {`${formatNumber(netPrice)} ${capitalize(t('baht'))}`}
                      </Col>
                    </Row>
                  </Container>
                </Col>

                <Col className="booking-details-card-text-column">
                  <Container className="booking-details-card-text-row-container">
                    <Row className="booking-details-card-text-row">
                      <Col>{capitalize(t('deposit-fees'))}</Col>
                      <Col className="booking-details-card-number-column">{`${formatNumber(depositFees)} ${capitalize(
                        t('baht')
                      )}`}</Col>
                    </Row>
                    <Row className="booking-details-card-text-row">
                      <Col>{capitalize(t('contract-fees'))}</Col>
                      <Col className="booking-details-card-number-column">{`${formatNumber(contractFees)} ${capitalize(
                        t('baht')
                      )}`}</Col>
                    </Row>
                    <Row className="booking-details-card-text-row">
                      <Col>{`ผ่อนดาวน์ ${downPaymentNumberOfInstallments} งวด`}</Col>
                      <Col className="booking-details-card-number-column">
                        {`${formatNumber(downPaymentAmountPerInstallment)} ${capitalizeTranslationSection(
                          t('baht-per-installment'),
                          '/'
                        )}`}
                      </Col>
                    </Row>
                    <Row className="booking-details-card-text-row">
                      <Col>{`งวดบอลลูน ${balloonPaymentNumberOfInstallments} งวด`}</Col>
                      <Col className="booking-details-card-number-column">
                        {`${formatNumber(balloonPaymentAmountPerInstallment)} ${capitalizeTranslationSection(
                          t('baht-per-installment'),
                          '/'
                        )}`}
                      </Col>
                    </Row>
                    <Row id="booking-details-card-down-payment-row">
                      <Col>{capitalize(t('total-down-payment'))}</Col>
                      <Col className="booking-details-card-number-column">{`${formatNumber(totalDownPaymentAmount)} ${capitalize(
                        t('baht')
                      )}`}</Col>
                    </Row>
                  </Container>
                </Col>

                <Col id="booking-details-card-ownership-transfer-fees-column">
                  <Row>
                    <Col>{capitalize(t('ownership-transfer-fees'))}</Col>
                    <Col className="booking-details-card-number-column">{`${formatNumber(ownershipTransferFees)} ${capitalize(
                      t('baht')
                    )}`}</Col>
                  </Row>
                </Col>
              </Row>
            </Container>

            {!location.includes('booking-details') && (
              <>
                {sizedBoxVerticalM}
                <Row xs={1} sm={1} md={2} className="booking-details-card-summary-row">
                  <Col>
                    <Container>
                      <Row className="booking-details-card-summary-heading-row">
                        <Col className="booking-details-card-summary-heading">{capitalize(t('payment-details'))}</Col>
                        {sizedBoxVerticalXS}
                      </Row>
                    </Container>
                    <Container>
                      <Row>
                        <Col className="booking-details-card-summary-column-text">
                          <Row className="booking-details-card-summary-row-text booking-details-card-summary-row-with-border">
                            <Col>{capitalize(t('type'))}</Col>
                            <Col>{paymentType === 'CC' ? 'บัตรเครดิต' : paymentType === 'QR' ? 'พร้อมเพย์' : '-'}</Col>
                          </Row>
                          {sizedBoxVerticalXS}
                          <Row className="booking-details-card-summary-row-text booking-details-card-summary-row-with-border">
                            <Col>{capitalize(t('amount'))}</Col>
                            <Col>{`${formatNumber(amountPaid)} ${capitalize(t('baht'))}`}</Col>
                          </Row>
                          {sizedBoxVerticalXS}
                          <Row className="booking-details-card-summary-row-text">
                            <Col>{capitalize(t('date-of-payment'))}</Col>
                            <Col>{dayjs(paymentDate).locale('th').format('D MMMM YYYY')}</Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col className="booking-details-card-summary-personal-information-section">
                    <Container>
                      <Row className="booking-details-card-summary-heading-row">
                        <Col className="booking-details-card-summary-row-text booking-details-card-summary-heading">
                          {capitalize(t('personal-information'))}
                        </Col>
                        {sizedBoxVerticalXS}
                      </Row>
                    </Container>
                    <Container>
                      <Row>
                        <Col className="booking-details-card-summary-column-text">
                          <Row className="booking-details-card-summary-row-text booking-details-card-summary-row-with-border">
                            <Col>{capitalize(t('name'))}</Col>
                            <Col>{fullName}</Col>
                          </Row>
                          {sizedBoxVerticalXS}
                          <Row className="booking-details-card-summary-row-text booking-details-card-summary-row-with-border">
                            <Col>
                              {capitalizeTranslationWithAcronyms(t('thai-national-id-card-number-booking-details-card'), ' ', [
                                'id',
                              ])}
                            </Col>
                            <Col>{thaiNationalIDCardNumber || '-'}</Col>
                          </Row>
                          {sizedBoxVerticalXS}
                          <Row className="booking-details-card-summary-row-text booking-details-card-summary-row-with-border">
                            <Col>{capitalize(t('phone-number'))}</Col>
                            <Col>{[phoneNumber?.slice(0, 3), phoneNumber?.slice(3, 6), phoneNumber?.slice(6)].join('-')}</Col>
                          </Row>
                          {sizedBoxVerticalXS}
                          <Row className="booking-details-card-summary-row-text booking-details-card-summary-row-with-border">
                            <Col>{capitalize(t('email'))}</Col>
                            <Col>{email}</Col>
                          </Row>
                          {sizedBoxVerticalXS}
                          <Row className="booking-details-card-summary-row-text px-0">
                            <Col xs={4}>{capitalize(t('address'))}</Col>
                            <Col xs={8}>
                              <Row xs={1}>
                                <Container>
                                  <Col className="px-0">{`${addressNumber ? addressNumber : '-'} ${village ? village : '-'} ${
                                    group ? group : '-'
                                  }`}</Col>
                                  <Col className="px-0">{`${alley ? alley : '-'} ${street ? street : '-'}`}</Col>
                                  <Col className="px-0">{`${province ?? '-'} ${district ?? '-'}`}</Col>
                                  <Col className="px-0">{`${subdistrict ?? '-'} ${postalCode ?? '-'}`}</Col>
                                </Container>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </>
            )}

            {location.includes('booking-complete') && (
              <>
                {sizedBoxVerticalM}
                <Row className="booking-details-card-disclaimer-row">
                  <Col>{`* ${capitalize(t('booking-complete-payment-disclaimer'))}`}</Col>
                  {sizedBoxVerticalXXS}
                  <Col className="booking-details-card-disclaimer-red-text">
                    {`* ${capitalizeTranslationWithAcronyms(t('booking-complete-sms-disclaimer'), ' ', ['sms'])}`}
                  </Col>
                  {sizedBoxVerticalXXS}
                  <Col>
                    {`* กรุณาติดต่อที่สำนักงานขายเพื่อทำสัญญาจะซื้อจะขายห้องชุด ภายในวันที่ ${dayjs()
                      .add(14, 'day')
                      .locale('th')
                      .format('D MMMM YYYY')}`}
                  </Col>
                  <Col>ติดต่อฝ่ายขาย สอบถามรายละเอียดโทร 063-207-6000</Col>
                </Row>
              </>
            )}

            <Container
              className={`booking-details-card-button-container ${location.includes('booking-complete') ? 'booking-details-card-buttons-container' : ''
                }`}
            >
              {location.includes('booking-details') && (
                <Link href={`/personal-information/${params?.bookingPeriodID}/${params?.bookingID}/${params?.unitID}`}>
                  <Button variant="danger">{capitalize(t('next'))}</Button>
                </Link>
              )}
              {!location.includes('booking-details') && (
                <Button
                  onClick={() => window.print()}
                  variant={`${location.includes('booking-complete') ? 'outline-' : ''}danger`}
                >
                  {capitalize(t('print-document'))}
                </Button>
              )}
              {location.includes('booking-complete') && (
                <Link href="~/">
                  <Button variant="danger">{capitalize(t('return-to-home-page'))}</Button>
                </Link>
              )}
            </Container>
            {sizedBoxVerticalL}
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
