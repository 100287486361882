import axios from 'axios';

import refresh from './api/auth/refresh';
import { setAccessToken, setRefreshToken } from './utils/tokens';

export const axiosInstance = axios.create();

// Configure header with token before each request is made
axiosInstance.interceptors.request.use(
  async (request) => {
    request.headers = {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    };
    // In case of no .env file (URL contains undefined), don't send the request
    if (request.url.includes('undefined')) return;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// If an unauthorized error (i.e. expired token) is receieved as response, attempt the request again after a refresh
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (originalRequest.url.includes('me') && error.response.status === 400) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    }
    // If the response error is not due to being unauthorized, no need to resend request
    if (error.response.status !== 401) return Promise.reject(error);

    if (!originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const { status, data } = await refresh(localStorage.getItem('access_token'), localStorage.getItem('refresh_token'));
        if (status) {
          console.log('REFRESH SUCCESSFUL W/ AXIOS');
          setAccessToken(data?.access_token);
          setRefreshToken(data?.refresh_token);
        }
      } catch (error) {
        return error;
      }
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
      return axiosInstance(originalRequest);
    } else {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    }
    return Promise.reject(error);
  }
);
