import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { capitalize } from 'lodash';
import { FilePond, registerPlugin } from 'react-filepond';
import { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import validateThaiID from 'thai-id-validator';
import { useLocation, useParams } from 'wouter';

import { cancelBooking } from '../../../api/booking';
import getCustomerDetails from '../../../api/customer/getCustomerDetails';
import { listDistricts, listPostalCodes, listProvinces, listSubdistricts } from '../../../api/master-data';
import { SelectFileIconSVG } from '../../../assets';
import { capitalizeTranslationSection, capitalizeTranslationWithAcronymsAndWords } from '../../../utils/formatTranslation';
import PaymentMethodsModal from '../../modals/payment-methods-modal';
import WarningModal from '../../modals/warning-modal';
import { customStyles, DropdownIndicator } from '../../../reactSelectCustomStyles';
import { sizedBoxVerticalXXS, sizedBoxVerticalXS, sizedBoxVerticalL } from '../../sized-box/';
import { addToHistory, checkHistory } from '../../../utils/history';

import 'filepond/dist/filepond.min.css';
import '../../../css/PersonalInformation.min.css';

const SUPPORTED_FILE_TYPES = ['image/*'];

export default function PersonalInformation() {
  const [location, setLocation] = useLocation();
  const params = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const userDataRef = useRef(null);

  registerPlugin(FilePondPluginFileValidateSize);
  registerPlugin(FilePondPluginFileValidateType);
  const filePondRef = useRef(null);
  const [uploadedFileArray, setUploadedFileArray] = useState([]);
  const fileRemoveIcon = `
  <svg aria-hidden="true" width="26" height="26" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.33342 11.8332L0.166748 10.6665L4.83342 5.99984L0.166748 1.33317L1.33342 0.166504L6.00008 4.83317L10.6668 0.166504L11.8334 1.33317L7.16675 5.99984L11.8334 10.6665L10.6668 11.8332L6.00008 7.16651L1.33342 11.8332Z"
      fill="white"
    />
  </svg>
  `;

  function handleShowPlaceholder(fieldName) {
    const fieldValue = getValues(fieldName);
    // If the field is active when its value is the default value, set it to empty
    if (fieldValue === defaultValues[fieldName]) {
      setValue(fieldName, '');
      // If the user click out of the field when its value is empty (i.e. user deleted their input), set it to the default value
    } else if (fieldValue === '') {
      setValue(fieldName, defaultValues[fieldName]);
    }
    return null;
  }

  const defaultValues = {
    first_name: capitalize(t('first-name')),
    last_name: capitalize(t('last-name')),
    phone_number: capitalize(t('phone-number')),
    email: capitalize(t('email')),
    id_card_number: capitalize(t('identification-number')),
    id_card_image: '',
    home_address_address_number: capitalize(t('house-number')),
    home_address_village: capitalizeTranslationSection(t('building-village'), '/'),
    home_address_group: capitalize(t('moo')),
    home_address_alley: capitalize(t('soi')),
    home_address_street: capitalize(t('road')),
    home_address_province: '',
    home_address_district: '',
    home_address_sub_district: '',
    home_address_postal_code: '',
    deliveryAddress: 'identificationCard',
    mailing_address_address_number: capitalize(t('house-number')),
    mailing_address_village: capitalizeTranslationSection(t('building-village'), '/'),
    mailing_address_group: capitalize(t('moo')),
    mailing_address_alley: capitalize(t('soi')),
    mailing_address_street: capitalize(t('road')),
    mailing_address_province: '',
    mailing_address_district: '',
    mailing_address_sub_district: '',
    mailing_address_postal_code: '',
  };
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm({
    // reValidateMode: "onChange",
    defaultValues,
  });

  register('id_card_image', { required: 'กรุณาแนบไฟล์บัตร' });
  let fileDetailedErrorMessage = null;
  if (uploadedFileArray[0]?.file?.size / (1024 * 1024) > 5) {
    fileDetailedErrorMessage = 'กรุณาแนบไฟล์ขนาดไม่เกิน 5MB';
  } else if (uploadedFileArray.length > 0 && !uploadedFileArray[0]?.file?.type.includes('image')) {
    fileDetailedErrorMessage = 'กรุณาแนบชนิดไฟล์ให้ถูกต้อง';
  }
  const fileRef = useRef(null);
  useEffect(() => {
    const uploadedFile = uploadedFileArray[0]?.file; // Only one upload is allowed, the uploaded file is always at index 0
    // If uploaded file is not in the supported format or larger than 5MB, fail the validation
    if (uploadedFile?.type.includes('image') && uploadedFile?.size / (1024 * 1024) < 5) {
      setValue('id_card_image', uploadedFile);
      fileRef.current = uploadedFile;
    } else {
      setValue('id_card_image', undefined);
      fileRef.current = null;
    }
  }, [uploadedFileArray]);

  // * Force user to choose document delivery address in order to trigger auto-fill in the section if necessary
  const deliveryAddress = getValues('deliveryAddress');
  const useSameAddress = deliveryAddress === 'identificationCard';

  const formDataRef = useRef(null);
  const [show, setShow] = useState(false);
  function onSubmit(values) {
    const formattedValues = {};
    for (const [key, value] of Object.entries(values)) {
      if (defaultValues[key] === value && key !== 'deliveryAddress') {
        formattedValues[key] = '';
      } else {
        switch (key) {
          case 'home_address_province':
          case 'mailing_address_province':
            formattedValues[key] = provinces.find((province) => province.label === value || province.value === value)?.label;
            break;
          case 'home_address_district':
          case 'mailing_address_district':
            formattedValues[key] = districts.find((district) => district.label === value || district.value === value)?.label;
            break;
          case 'home_address_sub_district':
          case 'mailing_address_sub_district':
            formattedValues[key] = subdistricts.find(
              (subdistrict) => subdistrict.label === value || subdistrict.value === value
            )?.label;
            break;
          default:
            formattedValues[key] = value;
        }
      }
    }

    const fieldsToRemove = ['avatar', 'create_at', 'id', 'is_privacy_policy_consent', 'update_at'];
    fieldsToRemove.forEach((field) => delete values[field]);
    setShow(true);
    formDataRef.current = formattedValues;
    // console.log(formattedValues);
  }
  function onError(error) {
    console.log(error);
  }

  // Get select options for provinces/districts/subdistricts/postal codes
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [subdistricts, setSubdistricts] = useState([]);
  const [postalCodes, setPostalCodes] = useState([]);
  // Reference to the select components
  const provinceSelectRef = useRef(null);
  const districtSelectRef = useRef(null);
  const subdistrictSelectRef = useRef(null);
  const postalCodeSelectRef = useRef(null);
  useEffect(() => {
    const setupForm = async () => {
      const { data: provinces } = await listProvinces();
      const { data: districts } = await listDistricts();
      const { data: subdistricts } = await listSubdistricts();
      const { data: postalCodes } = await listPostalCodes();
      setProvinces(provinces);
      setDistricts(districts);
      setSubdistricts(subdistricts);
      setPostalCodes(postalCodes);

      const { status, data } = await getCustomerDetails();
      // Auto-fill user information, data individually placed to avoid spreading irrelevant fields
      if (status) {
        reset(
          {
            first_name: data.first_name,
            last_name: data.last_name,
            phone_number: data.phone_number,
            email: data.email,
            home_address_address_number:
              data.address_number === '' ? defaultValues['home_address_address_number'] : data.address_number,
            home_address_village: data.village === '' ? defaultValues['home_address_village'] : data.village,
            home_address_group: data.group === '' ? defaultValues['home_address_group'] : data.group,
            home_address_alley: data.alley === '' ? defaultValues['home_address_alley'] : data.alley,
            home_address_street: data.street === '' ? defaultValues['home_address_street'] : data.street,
            home_address_province: data.province,
            home_address_district: data.district,
            home_address_sub_district: data.sub_district,
            home_address_postal_code: data.postal_code,
            mailing_address_address_number:
              data.address_number === '' ? defaultValues['home_address_address_number'] : data.address_number,
            mailing_address_village: data.village === '' ? defaultValues['home_address_village'] : data.village,
            mailing_address_group: data.group === '' ? defaultValues['home_address_group'] : data.group,
            mailing_address_alley: data.alley === '' ? defaultValues['home_address_alley'] : data.alley,
            mailing_address_street: data.street === '' ? defaultValues['home_address_street'] : data.street,
            mailing_address_province: data.province,
            mailing_address_district: data.district,
            mailing_address_sub_district: data.sub_district,
            mailing_address_postal_code: data.postal_code,
          },
          { keepDefaultValues: true }
        );
        userDataRef.current = data;
        provinceRef.current = data.province;
        districtRef.current = data.district;
      }
      setIsLoading(false);
    };
    setupForm();
  }, []);

  function clearSelectValue(refs) {
    refs.forEach((ref) => ref.current?.clearValue());
  }

  /*
    ADDRESS FIELDS AS IN THAI NATIONAL ID CARD
  */
  const watchProvince = watch('home_address_province');
  const watchDistrict = watch('home_address_district');
  const watchSubdistrict = watch('home_address_sub_district');
  const watchDocumentDeliveryAddress = watch('deliveryAddress');
  // Reference to store the values
  const provinceRef = useRef(null);
  const districtRef = useRef(null);
  const subdistrictRef = useRef(null);
  // Reference to the select components
  const documentProvinceSelectRef = useRef(null);
  const documentDistrictSelectRef = useRef(null);
  const documentSubdistrictSelectRef = useRef(null);
  const documentPostalCodeSelectRef = useRef(null);

  const matchingProvince = provinces?.find((option) => option.label === watchProvince || option.value === watchProvince);
  const filteredDistricts = districts?.filter((district) => district.provinceValue === matchingProvince?.value);
  const matchingDistrict = filteredDistricts?.find((option) => option.label === watchDistrict || option.value === watchDistrict);
  const filteredSubdistricts = subdistricts?.filter((subdistrict) => subdistrict.districtValue === matchingDistrict?.value);
  const matchingSubdistrict = subdistricts
    ?.filter((subdistrict) => subdistrict.districtValue === matchingDistrict?.value)
    ?.find((option) => option.label === watchSubdistrict || option.value === watchSubdistrict);

  /*
    Handle province/district value changing after having selected province/district/subdistrict values.
    Also handle setting form value of postal code and
    updating form values of document address select fields (if user never switches to different delivery address)
  */
  const currentFormValues = getValues();
  if (provinceRef.current !== watchProvince) {
    reset(
      {
        ...currentFormValues,
        home_address_district: '',
        home_address_sub_district: '',
        home_address_postal_code: '',
      },
      { keepDefaultValues: true }
    );
    clearSelectValue([
      districtSelectRef,
      subdistrictSelectRef,
      postalCodeSelectRef,
      ...(watchDocumentDeliveryAddress === 'identificationCard'
        ? [documentDistrictSelectRef, documentSubdistrictSelectRef, documentPostalCodeSelectRef]
        : []),
    ]);
    if (useSameAddress) setValue('mailing_address_province', watchProvince);
    provinceRef.current = watchProvince;
  } else if (districtRef.current !== watchDistrict) {
    reset(
      {
        ...currentFormValues,
        home_address_sub_district: '',
        home_address_postal_code: '',
      },
      { keepDefaultValues: true }
    );
    clearSelectValue([
      subdistrictSelectRef,
      postalCodeSelectRef,
      ...(watchDocumentDeliveryAddress === 'identificationCard'
        ? [documentSubdistrictSelectRef, documentPostalCodeSelectRef]
        : []),
    ]);
    if (useSameAddress) setValue('mailing_address_district', watchDistrict);
    districtRef.current = watchDistrict;
  } else if (subdistrictRef.current !== watchSubdistrict) {
    if (useSameAddress) setValue('mailing_address_sub_district', watchSubdistrict);
    subdistrictRef.current = watchSubdistrict;
  }

  if (watchSubdistrict !== '') {
    const postalCodeLabel = postalCodes?.find((option) => option.value === matchingSubdistrict?.value)?.label;
    setValue('home_address_postal_code', postalCodeLabel);
    if (useSameAddress) setValue('mailing_address_postal_code', postalCodeLabel);
  }

  /*
    Handle changes to document delivery address.
    - If it is changed to identificationCard: set the values of fields for document address
    to be the same as the fields above
    - If it is changed to differentAddress: reset the values of fields for document address
    to allow users to fill in the details of the new address
  */
  useEffect(() => {
    const currentFormValues = getValues();
    if (watchDocumentDeliveryAddress === 'identificationCard') {
      reset(
        {
          ...currentFormValues,
          mailing_address_address_number: getValues('home_address_address_number'),
          mailing_address_village: getValues('home_address_village'),
          mailing_address_group: getValues('home_address_group'),
          mailing_address_alley: getValues('home_address_alley'),
          mailing_address_street: getValues('home_address_street'),
          mailing_address_province: getValues('home_address_province'),
          mailing_address_district: getValues('home_address_district'),
          mailing_address_sub_district: getValues('home_address_sub_district'),
          mailing_address_postal_code:
            postalCodes?.find((option) => option.value === getValues('home_address_sub_district'))?.label || '',
        },
        { keepDefaultValues: true }
      );
    } else {
      reset(
        {
          ...currentFormValues,
          mailing_address_address_number: capitalize(t('house-number')),
          mailing_address_village: capitalizeTranslationSection(t('building-village'), '/'),
          mailing_address_group: capitalize(t('moo')),
          mailing_address_alley: capitalize(t('soi')),
          mailing_address_street: capitalize(t('road')),
          mailing_address_province: '',
          mailing_address_district: '',
          mailing_address_sub_district: '',
          mailing_address_postal_code: '',
        },
        { keepDefaultValues: true }
      );
      clearSelectValue([
        documentProvinceSelectRef,
        documentDistrictSelectRef,
        documentSubdistrictSelectRef,
        documentPostalCodeSelectRef,
      ]);
    }
  }, [getValues, reset, setValue, postalCodes, watchDocumentDeliveryAddress]);

  /*
    DOCUMENT DELIVERY ADDRESS
  */
  const watchDocumentProvince = watch('mailing_address_province');
  const watchDocumentDistrict = watch('mailing_address_district');
  const watchDocumentSubdistrict = watch('mailing_address_sub_district');
  // Reference to store the values
  const documentProvinceRef = useRef(null);
  const documentDistrictRef = useRef(null);
  /*
    Handle province/district value changing after having selected province/district/subdistrict values.
    Also handle setting form value of postal code
  */
  if (!useSameAddress) {
    if (documentProvinceRef.current !== watchDocumentProvince) {
      reset(
        {
          ...currentFormValues,
          mailing_address_district: '',
          mailing_address_sub_district: '',
          mailing_address_postal_code: '',
        },
        { keepDefaultValues: true }
      );
      clearSelectValue([documentDistrictSelectRef, documentSubdistrictSelectRef, documentPostalCodeSelectRef]);
      documentProvinceRef.current = watchDocumentProvince;
    } else if (documentDistrictRef.current !== watchDocumentDistrict) {
      reset(
        {
          ...currentFormValues,
          mailing_address_sub_district: '',
          mailing_address_postal_code: '',
        },
        { keepDefaultValues: true }
      );
      clearSelectValue([documentSubdistrictSelectRef, documentPostalCodeSelectRef]);
      documentDistrictRef.current = watchDocumentDistrict;
    }
  }

  if (watchDocumentSubdistrict !== '') {
    const matchingProvince = provinces?.find(
      (option) => option.label === watchDocumentProvince || option.value === watchDocumentProvince
    );
    const filteredDistricts = districts?.filter((district) => district.provinceValue === matchingProvince?.value);
    const matchingDistrict = filteredDistricts?.find(
      (option) => option.label === watchDocumentDistrict || option.value === watchDocumentDistrict
    );
    const matchingSubdistrict = subdistricts
      ?.filter((subdistrict) => subdistrict.districtValue === matchingDistrict?.value)
      ?.find((option) => option.label === watchDocumentSubdistrict || option.value === watchDocumentSubdistrict);
    setValue('mailing_address_postal_code', postalCodes?.find((option) => option.value === matchingSubdistrict?.value)?.label);
  }

  const cancelCurrentBooking = async () => {
    const { status } = await cancelBooking(params.bookingID);
    return status;
  };
  const [showWarning, setShowWarning] = useState(false);
  if (checkHistory('personal-information') > 1 && cancelCurrentBooking()) setLocation('~/', { replace: true });
  useEffect(() => {
    addToHistory('personal-information');
    window.onbeforeunload = () => '';
    if (window.history.state !== 'back') {
      window.history.pushState('back', '', `/booking${location}`);
    }
    window.onpopstate = () => setShowWarning(true);

    return () => {
      window.onbeforeunload = null;
      window.onpopstate = null;
    };
  }, []);

  const onWarningConfirm = async () => {
    if (cancelCurrentBooking()) setLocation('~/', { replace: true });
  };

  return (
    <>
      <Container fluid>
        <Form id="personal-information-form" onSubmit={handleSubmit(onSubmit, onError)}>
          {isLoading ? (
            <Spinner animation="grow" role="status" variant="danger">
              <span className="visually-hidden">Loading form</span>
            </Spinner>
          ) : (
            <>
              <Container className="personal-information-form-container">
                <h3>{capitalize(t('personal-information'))}</h3>
                {sizedBoxVerticalXS}
                <Form.Group className="form-group">
                  <Row xs={1} md={2} className="personal-information-form-row">
                    <Col>
                      <Form.Control
                        className="required"
                        type="text"
                        {...register('first_name', {
                          validate: (value) => (defaultValues['first_name'] !== value && value !== '') || 'กรุณากรอกชื่อ',
                        })}
                        onFocus={() => handleShowPlaceholder('first_name')}
                        onBlur={() => handleShowPlaceholder('first_name')}
                      />
                      <p className="error-message">{errors.first_name?.message}</p>
                    </Col>
                    <Col>
                      <Form.Control
                        className="required"
                        type="text"
                        {...register('last_name', {
                          validate: (value) => (defaultValues['last_name'] !== value && value !== '') || 'กรุณากรอกนามสกุล',
                        })}
                        onFocus={() => handleShowPlaceholder('last_name')}
                        onBlur={() => handleShowPlaceholder('last_name')}
                      />
                      <p className="error-message">{errors.last_name?.message}</p>
                    </Col>
                    <Col>
                      <Form.Control disabled {...register('phone_number')} style={{ color: '#a0a0a0' }} />
                    </Col>
                    <Col>
                      <Form.Control
                        className="required"
                        type="text"
                        {...register('email', {
                          validate: (value) =>
                            defaultValues['email'] !== value && value !== ''
                              ? value.includes('@') || 'กรุณาใส่ @ ในอีเมลของท่าน'
                              : 'กรุณากรอกอีเมล',
                        })}
                        onFocus={() => handleShowPlaceholder('email')}
                        onBlur={() => handleShowPlaceholder('email')}
                      />
                      <p className="error-message">{errors.email?.message}</p>
                    </Col>
                  </Row>
                  <Row xs={1} md={2} className="personal-information-form-row">
                    <Col>
                      <Form.Control
                        style={{ paddingBottom: 8 }}
                        type="text"
                        {...register('id_card_number', {
                          validate: (value) =>
                            defaultValues['id_card_number'] !== value && value !== ''
                              ? validateThaiID(value) || 'หมายเลขบัตรประชาชนไม่ถูกต้อง'
                              : 'กรุณากรอกหมายเลขบัตรประชาชน',
                        })}
                        onFocus={() => handleShowPlaceholder('id_card_number')}
                        onBlur={() => handleShowPlaceholder('id_card_number')}
                      />
                      <p className="error-message">{errors.id_card_number?.message}</p>
                    </Col>
                    <Col id="filepond-column">
                      <div id="filepond-container">
                        <FilePond
                          acceptedFileTypes={SUPPORTED_FILE_TYPES}
                          labelFileTypeNotAllowed="ไม่สนับสนุนไฟล์"
                          ref={filePondRef}
                          files={uploadedFileArray}
                          onupdatefiles={setUploadedFileArray}
                          maxFiles={1}
                          labelIdle=""
                          iconRemove={fileRemoveIcon}
                          styleButtonRemoveItemPosition="right"
                          credits={false}
                          maxFileSize="5MB"
                          labelMaxFileSizeExceeded="ขนาดไฟล์ใหญ่เกินไป"
                          labelMaxFileSize="ขนาดไฟล์ไม่สามารถใหญ่เกิน 5MB"
                          labelMaxTotalFileSizeExceeded="ขนาดไฟล์ใหญ่เกินไป"
                          labelMaxTotalFileSize="ขนาดไฟล์ไม่สามารถใหญ่เกิน 5MB"
                        />
                        <Button id="select-file-button" onClick={() => filePondRef.current.browse()} variant="dark">
                          <Image height={16} width={16} src={SelectFileIconSVG} />
                          {capitalize(t('select-file'))}
                        </Button>
                        <p className={`filepond-custom-label ${uploadedFileArray.length > 0 && 'd-none'}`}>
                          แนบไฟล์บัตร (.jpg / .png)
                        </p>
                      </div>
                      {errors.id_card_image?.message ? (
                        <p className="error-message">
                          {fileDetailedErrorMessage ??
                            ((fileRef.current === uploadedFileArray[0]?.file || !uploadedFileArray[0]?.file) &&
                              errors.id_card_image?.message)}
                        </p>
                      ) : (
                        <>
                          {sizedBoxVerticalXXS}
                          <p id="file-upload-disclaimer">{`* ${capitalize(t('file-upload-disclaimer'))}`}</p>
                        </>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
                {sizedBoxVerticalL}

                <h3>
                  {capitalizeTranslationWithAcronymsAndWords(t('address-as-on-thai-national-id-card'), ' ', ['id'], ['thai'])}
                </h3>
                {sizedBoxVerticalXS}
                <Form.Group className="form-group">
                  <Row xs={1} md={2} className="personal-information-form-row">
                    <Col>
                      <Form.Control
                        type="text"
                        {...register('home_address_address_number', {
                          onBlur: (e) => {
                            handleShowPlaceholder('home_address_address_number');
                            useSameAddress && setValue('mailing_address_address_number', e.target.value);
                          },
                          validate: (value) =>
                            (defaultValues['home_address_address_number'] !== value && value !== '') || 'กรุณากรอกบ้านเลขที่',
                        })}
                        onFocus={() => handleShowPlaceholder('home_address_address_number')}
                      />

                      <p className="error-message">{errors.home_address_address_number?.message}</p>
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        {...register('home_address_village', {
                          onBlur: (e) => {
                            handleShowPlaceholder('home_address_village');
                            useSameAddress && setValue('mailing_address_village', e.target.value);
                          },
                          validate: (value) =>
                            (defaultValues['home_address_village'] !== value && value !== '') || 'กรุณากรอกอาคาร/หมู่บ้าน',
                        })}
                        onFocus={() => handleShowPlaceholder('home_address_village')}
                      />
                      <p className="error-message">{errors.home_address_village?.message}</p>
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        {...register('home_address_group', {
                          onBlur: (e) => {
                            handleShowPlaceholder('home_address_group');
                            useSameAddress && setValue('mailing_address_group', e.target.value);
                          },
                          validate: (value) => (defaultValues['home_address_group'] !== value && value !== '') || 'กรุณากรอกหมู่',
                        })}
                        onFocus={() => handleShowPlaceholder('home_address_group')}
                      />
                      <p className="error-message">{errors.home_address_group?.message}</p>
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        {...register('home_address_alley', {
                          onBlur: (e) => {
                            handleShowPlaceholder('home_address_alley');
                            useSameAddress && setValue('mailing_address_alley', e.target.value);
                          },
                        })}
                        onFocus={() => handleShowPlaceholder('home_address_alley')}
                      />
                      <p className="error-message">{errors.home_address_alley?.message}</p>
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        {...register('home_address_street', {
                          onBlur: (e) => {
                            handleShowPlaceholder('home_address_street');
                            useSameAddress && setValue('mailing_address_street', e.target.value);
                          },
                        })}
                        onFocus={() => handleShowPlaceholder('home_address_street')}
                      />
                      <p className="error-message">{errors.home_address_street?.message}</p>
                    </Col>
                    <Col className="d-flex flex-column align-items-start select-column">
                      <Controller
                        control={control}
                        name="home_address_province"
                        render={({ field, value }) => (
                          <Select
                            components={{ DropdownIndicator }}
                            defaultValue={provinces?.find((option) => option.label === userDataRef.current?.province)}
                            onChange={(option) => field.onChange(option.value)}
                            options={provinces}
                            placeholder={capitalize(t('province'))}
                            ref={provinceSelectRef}
                            styles={customStyles}
                            value={provinces?.find((option) => option.value === value)}
                          />
                        )}
                        rules={{ required: 'กรุณาเลือกจังหวัด' }}
                      />
                      <p className="error-message">{errors.home_address_province?.message}</p>
                    </Col>
                    <Col>
                      <Controller
                        control={control}
                        name="home_address_district"
                        render={({ field, value }) => {
                          return (
                            <Select
                              components={{ DropdownIndicator }}
                              defaultValue={filteredDistricts?.find((option) => option.label === userDataRef.current?.district)}
                              isDisabled={!watchProvince}
                              onChange={(option) => field.onChange(option?.value)}
                              options={filteredDistricts}
                              placeholder={capitalize(t('district'))}
                              ref={districtSelectRef}
                              styles={customStyles}
                              value={filteredDistricts?.find((option) => option.value === value)}
                            />
                          );
                        }}
                        rules={{ required: 'กรุณาเลือกอำเภอ/เขต' }}
                      />
                      <p className="error-message">{errors.home_address_district?.message}</p>
                    </Col>
                    <Col>
                      <Controller
                        control={control}
                        name="home_address_sub_district"
                        render={({ field, value }) => {
                          return (
                            <Select
                              components={{ DropdownIndicator }}
                              defaultValue={filteredSubdistricts?.find(
                                (option) => option.label === userDataRef.current?.sub_district
                              )}
                              isDisabled={!watchDistrict}
                              onChange={(option) => field.onChange(option?.value)}
                              options={filteredSubdistricts}
                              placeholder={capitalize(t('subdistrict'))}
                              ref={subdistrictSelectRef}
                              styles={customStyles}
                              value={filteredSubdistricts?.find((option) => option.value === value)}
                            />
                          );
                        }}
                        rules={{ required: 'กรุณาเลือกแขวง/ตำบล' }}
                      />

                      <p className="error-message">{errors.home_address_sub_district?.message}</p>
                    </Col>
                    <Col md={6}>
                      <Controller
                        control={control}
                        name="home_address_postal_code"
                        render={({ field }) => {
                          return (
                            <Select
                              components={{ DropdownIndicator }}
                              defaultValue={postalCodes?.find(
                                (option) => option.label === userDataRef.current?.postal_code && option.value !== '99990104'
                              )}
                              isDisabled
                              onChange={(option) => field.onChange(option?.value)}
                              options={postalCodes}
                              placeholder={capitalize(t('postal-code'))}
                              ref={postalCodeSelectRef}
                              styles={customStyles}
                              value={postalCodes?.find((option) => option.value === matchingSubdistrict?.value)}
                            />
                          );
                        }}
                        rules={{ required: 'กรุณาเลือกรหัสไปรษณีย์' }}
                      />
                      <p className="error-message">{errors.home_address_postal_code?.message}</p>
                    </Col>
                  </Row>
                </Form.Group>
                {sizedBoxVerticalL}

                <h3>{capitalize(t('address-for-document-delivery'))}</h3>
                {sizedBoxVerticalXS}
                <Container bsPrefix="radio-container">
                  <Form.Check
                    type="radio"
                    label={capitalizeTranslationWithAcronymsAndWords(
                      t('address-as-on-thai-national-id-card'),
                      ' ',
                      ['id'],
                      ['thai']
                    )}
                    name="deliveryAddress"
                    value="identificationCard"
                    inline
                    {...register('deliveryAddress', { required: 'กรุณาเลือกที่อยู่ในการจัดส่งเอกสาร' })}
                  />
                  <Form.Check
                    type="radio"
                    label={capitalize(t('will-specify'))}
                    name="deliveryAddress"
                    value="differentAddress"
                    inline
                    {...register('deliveryAddress', { required: 'กรุณาเลือกที่อยู่ในการจัดส่งเอกสาร' })}
                  />
                  <p className="error-message pt-0">{errors.deliveryAddress?.message}</p>
                </Container>
                {sizedBoxVerticalXS}
                <Form.Group className="form-group">
                  <Row xs={1} md={2} className="personal-information-form-row">
                    <Col>
                      <Form.Control
                        type="text"
                        disabled={useSameAddress}
                        {...register('mailing_address_address_number', {
                          validate: (value) =>
                            (defaultValues['mailing_address_address_number'] !== value && value !== '') || 'กรุณากรอกบ้านเลขที่',
                        })}
                        onFocus={() => handleShowPlaceholder('mailing_address_address_number')}
                        onBlur={() => handleShowPlaceholder('mailing_address_address_number')}
                      />
                      <p className="error-message">
                        {useSameAddress
                          ? errors.home_address_address_number && errors.mailing_address_address_number?.message
                          : errors.mailing_address_address_number?.message}
                      </p>
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        disabled={useSameAddress}
                        {...register('mailing_address_village', {
                          validate: (value) =>
                            (defaultValues['mailing_address_village'] !== value && value !== '') || 'กรุณากรอกอาคาร/หมู่บ้าน',
                        })}
                        onFocus={() => handleShowPlaceholder('mailing_address_village')}
                        onBlur={() => handleShowPlaceholder('mailing_address_village')}
                      />
                      <p className="error-message">
                        {useSameAddress
                          ? errors.home_address_village && errors.mailing_address_village?.message
                          : errors.mailing_address_village?.message}
                      </p>
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        disabled={useSameAddress}
                        {...register('mailing_address_group', {
                          validate: (value) =>
                            (defaultValues['mailing_address_group'] !== value && value !== '') || 'กรุณากรอกหมู่',
                        })}
                        onFocus={() => handleShowPlaceholder('mailing_address_group')}
                        onBlur={() => handleShowPlaceholder('mailing_address_group')}
                      />
                      <p className="error-message">
                        {useSameAddress
                          ? errors.home_address_group && errors.mailing_address_group?.message
                          : errors.mailing_address_group?.message}
                      </p>
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        disabled={useSameAddress}
                        {...register('mailing_address_alley')}
                        onFocus={() => handleShowPlaceholder('mailing_address_alley')}
                        onBlur={() => handleShowPlaceholder('mailing_address_alley')}
                      />
                      <p className="error-message">
                        {useSameAddress
                          ? errors.home_address_alley && errors.mailing_address_alley?.message
                          : errors.mailing_address_alley?.message}
                      </p>
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        disabled={useSameAddress}
                        {...register('mailing_address_street')}
                        onFocus={() => handleShowPlaceholder('mailing_address_street')}
                        onBlur={() => handleShowPlaceholder('mailing_address_street')}
                      />
                      <p className="error-message">
                        {useSameAddress
                          ? errors.home_address_street && errors.mailing_address_street?.message
                          : errors.mailing_address_street?.message}
                      </p>
                    </Col>
                    <Col className="d-flex flex-column">
                      <Controller
                        control={control}
                        name="mailing_address_province"
                        render={({ field, value }) => (
                          <Select
                            components={{ DropdownIndicator }}
                            isDisabled={useSameAddress}
                            onChange={(option) => field.onChange(option?.value)}
                            options={provinces}
                            placeholder={capitalize(t('province'))}
                            ref={documentProvinceSelectRef}
                            styles={customStyles}
                            value={provinces?.find((option) => {
                              if (watchDocumentDeliveryAddress === 'differentAddress') {
                                return option.value === value;
                              }
                              return option.label === watchProvince || option.value === watchProvince;
                            })}
                          />
                        )}
                        rules={{ required: 'กรุณาเลือกจังหวัด' }}
                      />
                      <p className="error-message text-left">{errors.mailing_address_province?.message}</p>
                    </Col>
                    <Col>
                      <Controller
                        control={control}
                        name="mailing_address_district"
                        render={({ field, value }) => (
                          <Select
                            components={{ DropdownIndicator }}
                            isDisabled={useSameAddress || !watchDocumentProvince}
                            onChange={(option) => field.onChange(option?.value)}
                            options={
                              watchDocumentDeliveryAddress === 'differentAddress'
                                ? districts?.filter((district) => district.provinceValue === watchDocumentProvince)
                                : districts
                            }
                            placeholder={capitalize(t('district'))}
                            ref={documentDistrictSelectRef}
                            styles={customStyles}
                            value={districts?.find((option) => {
                              if (watchDocumentDeliveryAddress === 'differentAddress') {
                                return option.value === value;
                              }
                              return option.label === watchDistrict || option.value === watchDistrict;
                            })}
                          />
                        )}
                        rules={{ required: 'กรุณาเลือกอำเภอ/เขต' }}
                      />
                      <p className="error-message">{errors.mailing_address_district?.message}</p>
                    </Col>
                    <Col>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="mailing_address_sub_district"
                        render={({ field, value }) => (
                          <Select
                            components={{ DropdownIndicator }}
                            isDisabled={useSameAddress || !watchDocumentDistrict}
                            onChange={(option) => field.onChange(option?.value)}
                            options={
                              watchDocumentDeliveryAddress === 'differentAddress'
                                ? subdistricts?.filter((subdistrict) => subdistrict.districtValue === watchDocumentDistrict)
                                : subdistricts
                            }
                            placeholder={capitalize(t('subdistrict'))}
                            ref={documentSubdistrictSelectRef}
                            styles={customStyles}
                            value={subdistricts?.find((option) => {
                              if (watchDocumentDeliveryAddress === 'differentAddress') {
                                return option.value === value;
                              }
                              return option.label === watchSubdistrict || option.value === watchSubdistrict;
                            })}
                          />
                        )}
                        rules={{ required: 'กรุณาเลือกแขวง/ตำบล' }}
                      />
                      <p className="error-message">{errors.mailing_address_sub_district?.message}</p>
                    </Col>
                    <Col md={6}>
                      <Controller
                        control={control}
                        name="mailing_address_postal_code"
                        render={({ field }) => {
                          return (
                            <Select
                              components={{ DropdownIndicator }}
                              isDisabled
                              onChange={(option) => field.onChange(option?.value)}
                              options={postalCodes}
                              placeholder={capitalize(t('postal-code'))}
                              ref={documentPostalCodeSelectRef}
                              styles={customStyles}
                              value={postalCodes?.find((option) => {
                                if (watchDocumentDeliveryAddress === 'differentAddress') {
                                  return option.value === watchDocumentSubdistrict;
                                }
                                return (
                                  option.label === matchingSubdistrict?.postalCode ||
                                  option.value === matchingSubdistrict?.postalCode
                                );
                              })}
                            />
                          );
                        }}
                        rules={{ required: 'กรุณาเลือกรหัสไปรษณีย์' }}
                      />
                      <p className="error-message">{errors.mailing_address_postal_code?.message}</p>
                    </Col>
                  </Row>
                </Form.Group>
              </Container>
              <Container className="personal-information-form-button-container">
                <Button type="submit" variant="danger">
                  {capitalize(t('confirm-booking'))}
                </Button>
              </Container>
            </>
          )}
        </Form>
      </Container>

      <PaymentMethodsModal show={show} onHide={() => setShow(false)} formData={formDataRef.current} />
      <WarningModal
        onConfirm={onWarningConfirm}
        onHide={() => {
          window.history.pushState('back', '', `/booking${location}`);
          setShowWarning(false);
        }}
        show={showWarning}
      />
    </>
  );
}
