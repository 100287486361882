import axios from 'axios';
import dayjs from 'dayjs';
import gsap from 'gsap';
import { useEffect, useRef, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import getBookingPeriod from '../../../api/booking-period';
import Banner from '../../banner';
import ProjectInformationSection from '../../project-information-section';
import UnitsSection from '../../units-section';

export default function Home({ bookingInformation }) {
  const [isLoading, setIsLoading] = useState(true);
  const timeDifferenceRef = useRef(0);
  const bookingPeriodRef = useRef('');
  useEffect(() => {
    gsap.fromTo('.banner', { autoAlpha: 0, y: -40 }, { autoAlpha: 1, y: 0, delay: 0.5 });

    const getBookingPeriodInfo = async () => {
      const { status, data } = await getBookingPeriod();
      const {
        data: {
          result: { datetime },
        },
      } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/mst/times/current`);
      if (status) {
        const { id, start_date: startDate } = data;
        bookingPeriodRef.current = id;
        timeDifferenceRef.current = dayjs(startDate).diff(dayjs(datetime ?? new Date()), 'ms');
      }
      setIsLoading(false);
    };
    getBookingPeriodInfo();
  }, []);

  return (
    <>
      <Banner
        className="banner"
        isCountdownActive={timeDifferenceRef.current > 0}
        isCountdownLoading={isLoading}
        isRegistrationOpen={true}
        timeDifference={timeDifferenceRef.current}
      />
      <ProjectInformationSection />
      {/* Prevents UnitsSection from loading first without bookingPeriodID */}
      {bookingPeriodRef.current ? (
        <UnitsSection
          bookingInformation={bookingInformation}
          bookingPeriodID={bookingPeriodRef.current}
          timeDifference={timeDifferenceRef.current}
        />
      ) : timeDifferenceRef.current ? (
        <div className="d-flex justify-content-center" style={{ paddingBottom: 8 }}>
          <Spinner animation="grow" role="status" variant="danger">
            <span className="visually-hidden">Loading units</span>
          </Spinner>
        </div>
      ) : null}
    </>
  );
}
