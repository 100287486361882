import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import AmenitiesTab from './amenities-tab/AmenitiesTab';
import '../../css/ProjectInformationSection.min.css';
import ProjectFloorPlanTab from './project-floor-plan-tab';
import ProjectInformationTab from './project-information-tab';

export default function ProjectInformationSection() {
  return (
    <Container className="project-information-container" fluid>
      <p id="project-information-heading">ข้อมูลโครงการ</p>
      <Tab.Container defaultActiveKey="project-information">
        <Row className="tab-container-row">
          <Col className="project-information-tabs-column">
            <Nav className="project-information-nav-tabs" justify variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="project-information">รายละเอียดโครงการ</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="project-floorplan">ผังโครงการ</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="amenities">สิ่งอำนวยความสะดวก</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col className="tab-content-column">
            <Tab.Content>
              <Tab.Pane eventKey="project-information">
                <ProjectInformationTab />
              </Tab.Pane>
              <Tab.Pane eventKey="project-floorplan">
                <ProjectFloorPlanTab />
              </Tab.Pane>
              <Tab.Pane eventKey="amenities">
                <AmenitiesTab />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
}
