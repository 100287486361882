import axios from 'axios';

export default async function listProvinces() {
  try {
    const {
      data: { result },
    } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/mst/provinces`);
    return { status: true, data: result.map((province) => ({ label: province.name_th, value: province.id })) };
  } catch (error) {
    return { status: false, error };
  }
}
