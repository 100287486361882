import Modal from 'react-bootstrap/Modal';
import '../../../css/RegisterPDPAModal.min.css';
import PrivacyPolicy from '../../pages/privacy-policy/PrivacyPolicy';

export default function RegisterPDPAModal({ show, onHide }) {
  return (
    <Modal className="pdpa-modal" centered onHide={onHide} show={show}>
      <Modal.Header closeButton closeVariant="white"></Modal.Header>
      <Modal.Body>
        <PrivacyPolicy />
      </Modal.Body>
    </Modal>
  );
}
