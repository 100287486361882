/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  lang: 'th',
  unitsOnSale: [],
};

export const entitiesStore = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.lang = _.get(action, ['payload']);
    },
    setUnitsOnSale: (state, action) => {
      state.unitsOnSale = _.get(action, ['payload']);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLanguage, setUnitsOnSale } = entitiesStore.actions;

export default entitiesStore.reducer;
