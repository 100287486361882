import axios from 'axios';

export default async function getBookingPeriod() {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/booking/periods`);
    return { status: true, data: response?.data?.result };
  } catch (error) {
    return { status: false, error };
  }
}
