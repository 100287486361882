import { useState } from 'react';
import { capitalize } from 'lodash';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'wouter';

import { confirmBooking } from '../../../api/booking';
import { CreditDebitCardsIconSVG, PromptPayIcon } from '../../../assets';
import { sizedBoxVerticalXS } from '../../sized-box';
import { capitalizeTranslationSection } from '../../../utils/formatTranslation';

import '../../../css/PaymentMethodsModal.min.css';

export default function PaymentMethodsModal({ show, onHide, formData }) {
  const [, setLocation] = useLocation();
  const params = useParams();
  const { t } = useTranslation();
  const [paymentMethod, setPaymentMethod] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  async function handleConfirmBooking() {
    formData['payment_channel'] = paymentMethod;
    const fieldsToRemove = ['avatar', 'create_at', 'id', 'is_privacy_policy_consent', 'update_at'];
    fieldsToRemove.forEach((field) => delete formData[field]);
    const { status, data, error } = await confirmBooking(params?.bookingPeriodID, params?.bookingID, formData);

    if (status) {
      sessionStorage.setItem('webPaymentUrl', data.respCode === '0000' ? data.webPaymentUrl : -1);
      setLocation(
        paymentMethod === 'CC'
          ? `/payment-cards/${params?.bookingPeriodID}/${params?.bookingID}/${params?.unitID}`
          : `/payment-promptpay/${params?.bookingPeriodID}/${params?.bookingID}/${params?.unitID}`
      );
    } else {
      setErrorMessage(error?.response?.data?.error?.detail);
    }
  }

  return (
    <Modal className="payment-methods-modal" centered onHide={onHide} show={show}>
      <Modal.Header className="payment-methods-modal-header" closeButton closeVariant="white">
        <Modal.Title>{capitalize(t('select-payment-method'))}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="payment-methods-modal-body">
        <Row className="payment-methods-modal-row">
          <Col>
            <Button
              onClick={() => setPaymentMethod('CC')}
              bsPrefix={`payment-method-button ${paymentMethod === 'CC' && 'active'}`}
            >
              {sizedBoxVerticalXS}
              <Image src={CreditDebitCardsIconSVG} fluid />
              {sizedBoxVerticalXS}
              {capitalizeTranslationSection(t('credit-debit-cards'), '/')}
              {sizedBoxVerticalXS}
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => setPaymentMethod('QR')}
              bsPrefix={`payment-method-button ${paymentMethod === 'QR' && 'active'}`}
            >
              {sizedBoxVerticalXS}
              <Image src={PromptPayIcon} fluid />
              {sizedBoxVerticalXS}
              {t('promptpay') === 'promptpay' ? 'PromptPay' : t('promptpay')}
              {sizedBoxVerticalXS}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="payment-methods-modal-footer">
        <Container className="payment-methods-modal-footer-button-container">
          <Button disabled={!paymentMethod} onClick={handleConfirmBooking} variant="danger">
            {capitalize(t('confirm'))}
          </Button>
          <p className="m-0 pt-1 text-center text-danger">{errorMessage}</p>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}
