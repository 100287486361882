import { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'wouter';

import { getCustomerUnits } from '../../../api/customer';
import { listFloors, listUnitTypes } from '../../../api/master-data';
import { LeftQuoteSVG, RightQuoteSVG } from '../../../assets';
import BookingDetailsCard from '../../cards/booking-details-card';
import { capitalizeTranslationSection } from '../../../utils/formatTranslation';

import '../../../css/BookingComplete.min.css';

export default function BookingComplete() {
  const [location, setLocation] = useLocation();
  const params = useParams();
  const { t } = useTranslation();
  sessionStorage.removeItem('expireAt');

  const [unitDetails, setUnitDetails] = useState({});
  useEffect(() => {
    async function getDocumentAndUnitDetails() {
      const {
        data: { result: allCustomerUnits },
      } = await getCustomerUnits();
      const { data: floors } = await listFloors();
      const { data: unitTypes } = await listUnitTypes();
      const currentUnit = allCustomerUnits?.find((unit) => unit.unit_id === params.unitID) ?? {};
      setUnitDetails({
        ...currentUnit,
        unit_floor: floors?.find((option) => option?.value === currentUnit?.unit_floor_id)?.label,
        unit_types: unitTypes?.find((option) => option?.value === currentUnit?.unit_types_id)?.label,
      });
    }
    getDocumentAndUnitDetails();

    if (window.history.state !== 'back') {
      window.history.pushState('back', '', `/booking${location}`);
    }
    window.onpopstate = () => setLocation('~/', { replace: true });

    return () => {
      window.onpopstate = null;
    };
  }, []);

  return (
    <>
      <Container className="booking-complete-heading-container" fluid>
        <Row className="booking-complete-heading-row">
          <Col className="booking-complete-heading">
            <Image className="heading-left-quote" src={LeftQuoteSVG} />
            {capitalizeTranslationSection(t('booking-complete-heading'), '. ')}
            <Image className="heading-right-quote" src={RightQuoteSVG} />
          </Col>
          <Col className="booking-complete-subheading">
            {capitalizeTranslationSection(t('booking-complete-subheading'), '. ')}
          </Col>
        </Row>
      </Container>
      <BookingDetailsCard
        onlineBookingNumber={unitDetails?.sale_status?.invoice_no}
        unitNumber={unitDetails?.unit_no}
        roomType={unitDetails?.unit_types}
        roomSize={unitDetails?.size}
        floor={unitDetails?.unit_floor}
        roomPrice={unitDetails?.regular_price}
        discount={unitDetails?.discount}
        netPrice={unitDetails?.net_price}
        depositFees={unitDetails?.booking_price}
        contractFees={unitDetails?.contract_fee}
        downPaymentNumberOfInstallments={unitDetails?.monthly_installment_count}
        downPaymentAmountPerInstallment={unitDetails?.monthly_installment}
        balloonPaymentNumberOfInstallments={unitDetails?.balloon_installment_count}
        balloonPaymentAmountPerInstallment={unitDetails?.balloon_installment}
        totalDownPaymentAmount={unitDetails?.total_down_payment}
        paymentType={unitDetails?.sale_status?.payment_channel}
        amountPaid={unitDetails?.sale_status?.payment_detail?.amount}
        paymentDate={unitDetails?.sale_status?.booked_at}
        ownershipTransferFees={unitDetails?.ownership_transfer_fee}
        fullName={`${unitDetails?.first_name} ${unitDetails?.last_name}`}
        thaiNationalIDCardNumber={unitDetails?.id_card_number}
        phoneNumber={unitDetails?.phone_number}
        email={unitDetails?.email}
        addressNumber={unitDetails?.mailing_address_address_number}
        village={unitDetails?.mailing_address_village}
        group={unitDetails?.mailing_address_group}
        alley={unitDetails?.mailing_address_alley}
        street={unitDetails?.mailing_address_street}
        province={unitDetails?.mailing_address_province}
        district={unitDetails?.mailing_address_district}
        subdistrict={unitDetails?.mailing_address_sub_district}
        postalCode={unitDetails?.mailing_address_postal_code}
      />
    </>
  );
}
