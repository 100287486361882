import React from 'react';

export default function NotFound() {
  return (
    <div style={{ alignItems: 'center', columnGap: 16, display: 'flex', flexGrow: 1, justifyContent: 'center', padding: 8 }}>
      <h1>404</h1>
      <h2>This page could not be found.</h2>
    </div>
  );
}
