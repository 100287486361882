import { capitalize } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Checkbox from 'react-custom-checkbox';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useLocation } from 'wouter';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import '../../css/RegisterForm.min.css';

import { register as customerRegister } from '../../api/auth';
import { getCustomerDetails, updateCustomerDetails } from '../../api/customer';
import { listDistricts, listPostalCodes, listProvinces, listSubdistricts } from '../../api/master-data';
import { capitalizeTranslationSection } from '../../utils/formatTranslation';
import RegisterPDPAModal from '../modals/register-pdpa-modal';
import RegisterResultModal from '../modals/register-result-modal';
import OTPModal from '../modals/otp-modal';
import { customStyles, DropdownIndicator } from '../../reactSelectCustomStyles';
import { sizedBoxVerticalL } from '../sized-box';
import { errorCodeToMessage } from '../../utils/errorCodeToMessage';

export default function RegisterForm({ submitButtonLabel = 'ยืนยัน' }) {
  const [location] = useLocation();
  const [hasAgreed, setHasAgreed] = useState(!(location === '/register'));
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const idRef = useRef(null);
  const userDataRef = useRef(null);
  const isKeyboardOpen = useDetectKeyboardOpen();
  console.log('isKeyboardOpen: ', isKeyboardOpen);

  const defaultValues = {
    first_name: capitalize(t('first-name')),
    last_name: capitalize(t('last-name')),
    phone_number: capitalize(t('phone-number')),
    email: capitalize(t('email')),
    address_number: capitalize(t('house-number')),
    village: capitalizeTranslationSection(t('building-village'), '/'),
    group: capitalize(t('moo')),
    alley: capitalize(t('soi')),
    street: capitalize(t('road')),
    province: '',
    district: '',
    sub_district: '',
    postal_code: '',
  };
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues,
  });

  const watchProvince = watch('province');
  const watchDistrict = watch('district');
  const watchSubdistrict = watch('sub_district');
  // Reference to store the values
  const provinceRef = useRef(null);
  const districtRef = useRef(null);
  // Reference to the select components
  const provinceSelectRef = useRef(null);
  const districtSelectRef = useRef(null);
  const subdistrictSelectRef = useRef(null);
  const postalCodeSelectRef = useRef(null);

  /*
    Set-up form: 
    1. Get select options for provinces/districts/subdistricts/postal codes
    2. Set form's default values to be user's details if in edit-profile page
  */
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [subdistricts, setSubdistricts] = useState([]);
  const [postalCodes, setPostalCodes] = useState([]);
  useEffect(() => {
    const setupForm = async () => {
      const { data: provinces } = await listProvinces();
      const { data: districts } = await listDistricts();
      const { data: subdistricts } = await listSubdistricts();
      const { data: postalCodes } = await listPostalCodes();
      setProvinces(provinces);
      setDistricts(districts);
      setSubdistricts(subdistricts);
      setPostalCodes(postalCodes);

      if (location === '/edit-profile') {
        const { status, data } = await getCustomerDetails();
        if (status) {
          idRef.current = data.id;
          userDataRef.current = data;
          for (const [key, value] of Object.entries(data)) {
            if (value === '') {
              setValue(key, defaultValues[key]);
            } else {
              switch (key) {
                case 'province':
                  setValue(key, provinces?.find((province) => province.label === value || province.value === value)?.label);
                  break;
                case 'district':
                  setValue(key, districts?.find((district) => district.label === value || district.value === value)?.label);
                  break;
                case 'sub_district':
                  setValue(
                    key,
                    subdistricts?.find((subdistrict) => subdistrict.label === value || subdistrict.value === value)?.label
                  );
                  break;
                default:
                  setValue(key, value);
              }
            }
          }
          provinceRef.current = userDataRef.current.province;
          districtRef.current = userDataRef.current.district;
        }
      }
      setIsLoading(false);
    };

    setupForm();
  }, []);

  /*
    Handle province/district value changing after having selected province/district/subdistrict values.
    Also handle setting form value of postal code
  */
  function clearSelectValue(refs) {
    refs.forEach((ref) => ref.current?.clearValue());
  }
  const currentFormValues = getValues();
  if (provinceRef.current !== watchProvince) {
    reset(
      {
        ...currentFormValues,
        district: '',
        sub_district: '',
        postal_code: '',
      },
      { keepDefaultValues: true }
    );
    clearSelectValue([districtSelectRef, subdistrictSelectRef, postalCodeSelectRef]);
    provinceRef.current = watchProvince;
  } else if (districtRef.current !== watchDistrict) {
    reset(
      {
        ...currentFormValues,
        sub_district: '',
        postal_code: '',
      },
      { keepDefaultValues: true }
    );
    clearSelectValue([subdistrictSelectRef, postalCodeSelectRef]);
    districtRef.current = watchDistrict;
  }
  if (watchSubdistrict !== '') {
    const matchingSubdistrict = subdistricts?.find(
      (option) => option.label === watchSubdistrict || option.value === watchSubdistrict
    );
    setValue('postal_code', postalCodes?.find((option) => option.value === matchingSubdistrict?.value)?.label);
  }

  function handleShowPlaceholder(fieldName) {
    const fieldValue = getValues(fieldName);
    // If the field is active when its value is the default value, set it to empty
    if (fieldValue === defaultValues[fieldName]) {
      setValue(fieldName, '');
      // If the user click out of the field when its value is empty (i.e. user deleted their input), set it to the default value
    } else if (fieldValue === '') {
      setValue(fieldName, defaultValues[fieldName]);
    }
    return null;
  }

  const responseStatusRef = useRef(null);
  const referenceIDRef = useRef(null);
  const errorMessageRef = useRef(null);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [showPDPAModal, setShowPDPAModal] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  async function onSubmit(values) {
    const formattedValues = {};
    for (const [key, value] of Object.entries(values)) {
      if (defaultValues[key] === value) {
        formattedValues[key] = '';
      } else {
        switch (key) {
          case 'province':
            formattedValues[key] = provinces.find((province) => province.label === value || province.value === value)?.label;
            break;
          case 'district':
            formattedValues[key] = districts.find((district) => district.label === value || district.value === value)?.label;
            break;
          case 'sub_district':
            formattedValues[key] = subdistricts.find(
              (subdistrict) => subdistrict.label === value || subdistrict.value === value
            )?.label;
            break;
          default:
            formattedValues[key] = value;
        }
      }
    }

    if (location === '/register') {
      const { status, data, error } = (await customerRegister({ ...formattedValues, is_privacy_policy_consent: true })) || {};
      responseStatusRef.current = status;
      if (!status) {
        if (!!getValues('phone_number')) {
          errorMessageRef.current = errorCodeToMessage(
            error?.response?.data?.code,
            getValues('phone_number'),
            error?.response?.data?.message
          );
        }
        setShowResultModal(true);
        return;
      }
      referenceIDRef.current = data.reference_id;
      setShowOTPModal(true);
    } else if (location === '/edit-profile') {
      const fieldsToRemove = ['avatar', 'create_at', 'id', 'is_privacy_policy_consent', 'update_at'];
      fieldsToRemove.forEach((field) => delete formattedValues[field]);
      const { status } = await updateCustomerDetails(idRef.current, formattedValues);
      if (status) {
        window.location = window.location;
      }
    }
  }
  function onError(error) {
    console.error(error);
    responseStatusRef.current = false;
    setShowResultModal(true);
  }

  const matchingProvince = provinces?.find((option) => option.label === watchProvince || option.value === watchProvince);
  const filteredDistricts = districts?.filter((district) => district.provinceValue === matchingProvince?.value);
  const matchingDistrict = filteredDistricts?.find((option) => option.label === watchDistrict || option.value === watchDistrict);
  const filteredSubdistricts = subdistricts?.filter((subdistrict) => subdistrict.districtValue === matchingDistrict?.value);
  const matchingSubdistrict = subdistricts
    ?.filter((subdistrict) => subdistrict.districtValue === matchingDistrict?.value)
    ?.find((option) => option.label === watchSubdistrict || option.value === watchSubdistrict);

  return (
    <>
      <Container className="register-form-container" fluid>
        <Form className="register-form" onSubmit={handleSubmit(onSubmit, onError)}>
          {isLoading ? (
            <Spinner animation="grow" role="status" variant="danger">
              <span className="visually-hidden">Loading form</span>
            </Spinner>
          ) : (
            <>
              <Container>
                <h3>{capitalize(t('personal-information'))}</h3>
                <Form.Group>
                  <Row xs={1} md={2} className="register-form-row">
                    <Col>
                      <Form.Control
                        className="required"
                        type="text"
                        {...register('first_name', {
                          validate: (value) => defaultValues['first_name'] !== value || 'กรุณากรอกชื่อ',
                        })}
                        onFocus={() => handleShowPlaceholder('first_name')}
                        onBlur={() => handleShowPlaceholder('first_name')}
                      />
                      <p className="error-message">{errors.first_name?.message}</p>
                    </Col>
                    <Col>
                      <Form.Control
                        className="required"
                        type="text"
                        {...register('last_name', {
                          validate: (value) => defaultValues['last_name'] !== value || 'กรุณากรอกนามสกุล',
                        })}
                        onFocus={() => handleShowPlaceholder('last_name')}
                        onBlur={() => handleShowPlaceholder('last_name')}
                      />
                      <p className="error-message">{errors.last_name?.message}</p>
                    </Col>
                    <Col>
                      <Form.Control
                        className="required"
                        disabled={location === '/edit-profile'}
                        type="tel"
                        {...register('phone_number', {
                          validate: (value) =>
                            defaultValues['phone_number'] !== value
                              ? value.length === 10 || 'กรุณาตรวจสอบจำนวนหมายเลข'
                              : 'กรุณากรอกเบอร์โทร',
                        })}
                        onFocus={() => handleShowPlaceholder('phone_number')}
                        onBlur={() => handleShowPlaceholder('phone_number')}
                      />
                      <p className="error-message">{errors.phone_number?.message}</p>
                    </Col>
                    <Col>
                      <Form.Control
                        className="required"
                        type="text"
                        {...register('email', {
                          validate: (value) =>
                            defaultValues['email'] !== value
                              ? value.includes('@') || 'กรุณาใส่ @ ในอีเมลของท่าน'
                              : 'กรุณากรอกอีเมล',
                        })}
                        onFocus={() => handleShowPlaceholder('email')}
                        onBlur={() => handleShowPlaceholder('email')}
                      />
                      <p className="error-message">{errors.email?.message}</p>
                    </Col>
                  </Row>
                </Form.Group>
              </Container>
              <Container>
                <h3>{capitalize(t('address'))}</h3>
                <Form.Group>
                  <Row xs={1} md={2} className="register-form-row">
                    <Col>
                      <Form.Control
                        type="text"
                        {...register('address_number')}
                        onFocus={() => handleShowPlaceholder('address_number')}
                        onBlur={() => handleShowPlaceholder('address_number')}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        {...register('village')}
                        onFocus={() => handleShowPlaceholder('village')}
                        onBlur={() => handleShowPlaceholder('village')}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        {...register('group')}
                        onFocus={() => handleShowPlaceholder('group')}
                        onBlur={() => handleShowPlaceholder('group')}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        {...register('alley')}
                        onFocus={() => handleShowPlaceholder('alley')}
                        onBlur={() => handleShowPlaceholder('alley')}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        {...register('street')}
                        onFocus={() => handleShowPlaceholder('street')}
                        onBlur={() => handleShowPlaceholder('street')}
                      />
                    </Col>
                    <Col className="select-column">
                      <Controller
                        control={control}
                        name="province"
                        render={({ field, value }) => (
                          <Select
                            components={{ DropdownIndicator }}
                            defaultValue={provinces?.find((option) => option.label === userDataRef.current?.province)}
                            onChange={(option) => field.onChange(option?.value)}
                            options={provinces}
                            placeholder={capitalize(t('province'))}
                            ref={provinceSelectRef}
                            styles={customStyles}
                            value={provinces?.find((option) => option.value === value)}
                          />
                        )}
                      />
                    </Col>
                    <Col>
                      <Controller
                        control={control}
                        name="district"
                        render={({ field, value }) => {
                          return (
                            <Select
                              components={{ DropdownIndicator }}
                              defaultValue={filteredDistricts?.find((option) => option.label === userDataRef.current?.district)}
                              isDisabled={!watchProvince}
                              onChange={(option) => field.onChange(option?.value)}
                              options={filteredDistricts}
                              placeholder={capitalize(t('district'))}
                              ref={districtSelectRef}
                              styles={customStyles}
                              value={filteredDistricts?.find((option) => option.value === value)}
                            />
                          );
                        }}
                      />
                    </Col>
                    <Col>
                      <Controller
                        control={control}
                        name="sub_district"
                        render={({ field, value }) => {
                          return (
                            <Select
                              components={{ DropdownIndicator }}
                              defaultValue={filteredSubdistricts?.find(
                                (option) => option.label === userDataRef.current?.sub_district
                              )}
                              isDisabled={!watchDistrict}
                              onChange={(option) => field.onChange(option?.value)}
                              options={filteredSubdistricts}
                              placeholder={capitalize(t('subdistrict'))}
                              ref={subdistrictSelectRef}
                              styles={customStyles}
                              value={filteredSubdistricts?.find((option) => option.value === value)}
                            />
                          );
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <Controller
                        control={control}
                        name="postal_code"
                        render={({ field }) => {
                          return (
                            <Select
                              components={{ DropdownIndicator }}
                              defaultValue={postalCodes?.find(
                                (option) => option.label === userDataRef.current?.postal_code && option.value !== '99990104'
                              )}
                              isDisabled
                              onChange={(option) => field.onChange(option?.value)}
                              options={postalCodes}
                              placeholder={capitalize(t('postal-code'))}
                              ref={postalCodeSelectRef}
                              styles={customStyles}
                              value={postalCodes?.find((option) => option.value === matchingSubdistrict?.value)}
                            />
                          );
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Container>
              <Container bsPrefix="register-form-confirmation">
                <div className="register-form-checkbox-container">
                  <Checkbox
                    borderColor="#ed2024"
                    borderRadius="0px"
                    icon={
                      <img
                        alt="Check icon"
                        src="data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4104 0.523438L4.2962 6.19559L1.71048 3.43619L0 5.039L4.17737 9.49698L12 2.24655L10.4104 0.523438Z' fill='%23ED2024'/%3E%3C/svg%3E%0A"
                      />
                    }
                    onChange={() => setHasAgreed((hasAgreed) => !hasAgreed)}
                    size="20px"
                    value={hasAgreed}
                  />
                  <p>
                    ข้าพเจ้าได้อ่านและยอมรับ <span onClick={() => setShowPDPAModal(true)}>ข้อกำหนด</span> และ{' '}
                    <a href="/terms-and-conditions" rel="noreferrer" target="_blank">
                      เงื่อนไข
                    </a>{' '}
                    ที่ระบุไว้ในคำชี้แจงสิทธิ์ส่วนบุคคลแล้วและยินยอมให้มีการเก็บรวบรวมประมวลผล และ/หรือ
                    เปิดเผยข้อมูลส่วนบุคคลที่ฉันให้ไว้เพื่อให้เป็นไปตามวัตถุประสงค์
                  </p>
                </div>
                {sizedBoxVerticalL}
              </Container>
              <Container className="register-form-button-container">
                {/* Submit button only initially disabled if on register page. User has to agree to terms and conditions first */}
                <Button disabled={!hasAgreed} type="submit" variant="danger">
                  {submitButtonLabel}
                </Button>
              </Container>
            </>
          )}
        </Form>
      </Container>

      {referenceIDRef.current && (
        <OTPModal
          show={showOTPModal}
          onHide={() => setShowOTPModal(false)}
          mode="register"
          initialReferenceValue={referenceIDRef.current}
          handleRegisterSuccess={() => {
            responseStatusRef.current = true;
            setShowResultModal(true);
          }}
        />
      )}
      <RegisterPDPAModal show={showPDPAModal} onHide={() => setShowPDPAModal(false)} />
      <RegisterResultModal
        show={showResultModal}
        onHide={() => setShowResultModal(false)}
        isSuccess={responseStatusRef.current}
        message={errorMessageRef.current}
      />
    </>
  );
}
