import axios from 'axios';

export default async function listPostalCodes() {
  try {
    const {
      data: { result },
    } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/mst/subdistricts`);
    return {
      status: true,
      data: result.map((subdistrict) => ({ label: subdistrict.postcode, value: subdistrict.id })),
    };
  } catch (error) {
    return { status: false, error };
  }
}
