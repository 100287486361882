import axios from 'axios';

export default async function register(data) {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/customer/register`, data);
    return { status: true, data: response?.data?.result };
  } catch (error) {
    return { status: false, error };
  }
}
