// roundingMethod: ceil/floor
export const formatNumber = (number, decimalCount = 0, roundingMode = 'floor', locale = 'th-TH') => {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: decimalCount,
    maximumFractionDigits: decimalCount,
    roundingMode,
  }).format(number);
};

export const getNumDecimals = (number) => {
  const splittedNumber = String(number).split('.');
  return splittedNumber.length === 2 ? splittedNumber.pop().length : 0;
};
