export const errorCodeToMessage = (code, userInformation = '', errorMessage) => {
  switch (code) {
    case '2000':
      return `เบอร์โทร ${userInformation} ไม่ถูกต้อง`;
    case '5000':
      return `OTP ของท่านได้หมดอายุแล้ว`;
    case '5001':
      return `ระบบไม่สามารถส่ง OTP ให้ท่านได้ในขณะนี้`;
    case '5005':
      return `เบอร์โทร ${userInformation} ไม่ได้ทำการลงทะเบียนไว้`;
    case '5014':
      return `เบอร์โทร ${userInformation} ได้ถูกลงทะเบียนแล้ว`;
    default:
      return `${errorMessage} (รหัส error ${code})`;
  }
};
