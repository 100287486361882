import { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Countdown from 'react-countdown';
import { OTPInput } from 'input-otp';

import { loginVerifyOTP, loginResendOTP, registerResendOTP, registerVerifyOTP } from '../../../api/auth';
import { setAccessToken, setRefreshToken } from '../../../utils/tokens';

import OTPIconSVG from '../../../assets/otp-icon.svg';

import '../../../css/OTPModal.min.css';

export default function OTPModal({ show, onHide, mode = 'login', initialReferenceValue = null, handleRegisterSuccess }) {
  const [otp, setOTP] = useState('');
  // Used to trigger reset for Countdown component
  const [numReset, setNumReset] = useState(0);
  // Store any updated referenceValue when a new OTP is requested
  const updatedReferenceValueRef = useRef(null);
  const referenceValue = updatedReferenceValueRef.current || initialReferenceValue;
  const [startTime, setStartTime] = useState(null);
  useEffect(() => {
    if (show) {
      setStartTime(Date.now());
    }
  }, [show]);

  const [errorMessage, setErrorMessage] = useState('');
  async function handleVerifyOTP() {
    const { status, data, error } =
      mode === 'login'
        ? await loginVerifyOTP({ otp, ref_code: referenceValue })
        : await registerVerifyOTP({ otp, reference_id: referenceValue });
    if (status) {
      const { access_token: accessToken, refresh_token: refreshToken } = data?.result;
      onHide();
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      if (mode === 'login') {
        window.location = window.location;
      } else {
        handleRegisterSuccess();
      }
    } else {
      setErrorMessage(error?.response?.data?.message);
    }
  }

  async function handleResendOTP() {
    const { status, data } =
      mode === 'login'
        ? await loginResendOTP({ reference_id: referenceValue })
        : await registerResendOTP({ reference_id: referenceValue });
    if (status) {
      setNumReset((num) => num + 1);
      updatedReferenceValueRef.current = data?.ref_code || data?.reference_id;
      setStartTime(Date.now());
    }
  }

  const renderer = ({ minutes, seconds }) => {
    const formattedSeconds = minutes > 0 ? seconds + minutes * 60 : seconds;
    return (
      <p>
        กรุณากรอกรหัสภายใน <span id="countdown-highlight">{formattedSeconds}</span> วินาที
      </p>
    );
  };

  return (
    <Modal centered onHide={onHide} show={show}>
      <Modal.Header closeButton closeVariant="white">
        <Image src={OTPIconSVG} fluid />
        <Modal.Title>กรุณากรอกรหัส OTP</Modal.Title>
      </Modal.Header>
      <Modal.Body bsPrefix="otp-modal-body">
        <OTPInput
          containerClassName="otp-container"
          maxLength={6}
          onChange={setOTP}
          render={({ slots }) =>
            slots.map((slot, index) => {
              return (
                <div className={`otp-input-field ${index < otp.length ? 'otp-input-field-active' : ''}`}>
                  {slot?.char ?? null}
                  {slot?.hasFakeCaret && (
                    <div className="fake-caret-container">
                      <div style={{ width: 1, height: 24, background: '#333333' }}></div>
                    </div>
                  )}
                </div>
              );
            })
          }
        />
        <div className="otp-modal-body-text-container">
          {startTime && <Countdown key={numReset} date={startTime + 75000} renderer={renderer} />}
          <p className="resend-otp-text" onClick={handleResendOTP}>
            ส่งรหัสใหม่
          </p>
          <p className="error-message">{errorMessage}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Container bsPrefix="otp-confirm-container">
          <Button disabled={otp.length !== 6} onClick={handleVerifyOTP} variant="danger">
            ยืนยัน
          </Button>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}
