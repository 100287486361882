import Container from 'react-bootstrap/Container';
import { useLocation } from 'wouter';

import RegisterForm from '../../register-form';

import '../../../css/Register.min.css';

export default function Register() {
  const [, setLocation] = useLocation();
  if (!!localStorage.getItem('access_token')) setLocation('/');

  return (
    <Container className="register-page-container">
      <Container>
        <h1>ลงทะเบียนสำหรับจอง</h1>
        <p>ลงทะเบียนเพื่อเข้าใช้งานระบบจองออนไลน์</p>
        <hr></hr>
      </Container>
      <RegisterForm />
    </Container>
  );
}
