import { capitalize } from 'lodash';
import { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'wouter';

import { loginRequestOTP } from '../../../api/auth';
import { LoginRegisterIconSVG } from '../../../assets';
import { errorCodeToMessage } from '../../../utils/errorCodeToMessage';

import OTPModal from '../otp-modal';

import '../../../css/LoginPhoneNumberModal.min.css';

export default function LoginPhoneNumberModal({ show, onHide }) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState({
    phoneNumber: '',
    message: '',
  });

  const defaultValues = {
    phone_number: capitalize(t('phone-number')),
  };
  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
  } = useForm({
    defaultValues,
  });

  function handleShowPlaceholder(fieldName) {
    const fieldValue = getValues(fieldName);
    // If the field is active when its value is the default value, set it to empty
    if (fieldValue === defaultValues[fieldName]) {
      setValue(fieldName, '');
      // If the user click out of the field when its value is empty (i.e. user deleted their input), set it to the default value
    } else if (fieldValue === '') {
      setValue(fieldName, defaultValues[fieldName]);
    }
    return null;
  }

  const referenceCodeRef = useRef(null);
  async function onSubmit(values) {
    const { status, data, error } = (await loginRequestOTP(values)) || {};
    if (!status) {
      const phoneNumber = getValues('phone_number');
      setError({
        phoneNumber,
        message: errorCodeToMessage(error?.response?.data?.code, phoneNumber, error?.response?.data?.message),
      });
    }
    if (status) {
      referenceCodeRef.current = data?.ref_code;
      // Close self, then show the next modal
      onHide();
      setShowModal(true);
    }
  }
  function onError(error) {
    console.error(error);
  }

  return (
    <>
      <Modal className="login-phone-number-modal" centered onHide={onHide} show={show}>
        <Modal.Header closeButton closeVariant="white">
          <Image src={LoginRegisterIconSVG} fluid />
          <Modal.Title>เข้าสู่ระบบ</Modal.Title>
        </Modal.Header>
        <Modal.Body className="login-phone-number-modal-body">
          <Form className="phone-number-form" id="phone-number-form" onSubmit={handleSubmit(onSubmit, onError)}>
            <Form.Control
              className="required"
              type="tel"
              {...register('phone_number', {
                required: 'กรุณากรอกเบอร์โทร',
                validate: (value) => value.length === 10 || 'กรุณาตรวจสอบจำนวนหมายเลข',
              })}
              onFocus={() => handleShowPlaceholder('phone_number')}
              onBlur={() => handleShowPlaceholder('phone_number')}
            />
            <p className="error-message">
              {errors.phone_number?.message || (getValues('phone_number') === error.phoneNumber ? error.message : null)}
            </p>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button form="phone-number-form" type="submit" variant="danger">
            เข้าสู่ระบบ
          </Button>
          <p>
            คุณยังไม่มีบัญชี ?{' '}
            <Link href="/register" onClick={onHide}>
              <span id="register-highlight">ลงทะเบียน</span>
            </Link>
          </p>
        </Modal.Footer>
      </Modal>
      <OTPModal show={showModal} onHide={() => setShowModal(false)} initialReferenceValue={referenceCodeRef.current} />
    </>
  );
}
