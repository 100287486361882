import React, { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/FloorplanLoader.min.css';

function DrawRect({ onHandleUnit = () => null, item = {} }) {
  return (
    <rect
      x={_.get(item, ['x'])}
      y={_.get(item, ['y'])}
      width={_.get(item, ['width'])}
      height={_.get(item, ['height'])}
      style={{
        fill: 'red',
        stroke: 'red',
        strokeWidth: 1,
        opacity: 0.5,
      }}
      id={_.get(item, ['id'])}
      onClick={() => onHandleUnit(_.get(item, ['id']))}
    />
  );
}

function DrawPolygon({
  onHandleUnit = () => null,
  item = {},
  highlightId = '',
  rect = {},
  roomStatus,
  onHover = () => null,
  onClickUnit = () => null,
  selectedUnit = null,
  setUnitHover = () => null,
  width = null,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const divRef = useRef(null);

  const getXPosition = () => {
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect();

      return rect.x;
    }
  };
  const getYPosition = () => {
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect();

      return rect.y;
    }
  };
  const handleMouseEnter = () => {
    const refX = getXPosition();
    const refY = getYPosition();

    if (_.isNil(selectedUnit)) {
      setIsHovered(true);
      onHover(_.get(item, ['data-name']), refX, refY);
      //
      setUnitHover({
        unitDetails: {},
        unitNumber: _.get(item, ['data-name']),
        unitPosition: { top: -120, right: -30 },
      });
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    onHover(null);
    setUnitHover({ unitDetails: {}, unitNumber: null, unitPosition: {} });
  };
  const handleClickUnit = useCallback((unitId) => {
    onClickUnit(unitId);
  }, []);
  return (
    <g
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => handleClickUnit(_.get(item, ['data-name']))}
    >
      {!_.isUndefined(roomStatus) && (
        <polygon
          points={_.get(item, ['points'])}
          id={_.get(item, ['id'])}
          style={{
            fill: highlightId === _.get(item, ['id']) ? 'white' : '#ed2024',
            stroke: highlightId === _.get(item, ['id']) ? 'white' : 'transparent',
            strokeWidth: 1,
            opacity: isHovered ? 0.2 : 0,
          }}
        />
      )}

      {selectedUnit === null && !_.isUndefined(roomStatus) ? (
        <circle
          ref={divRef}
          cx={_.get(rect, ['x']) + rect?.width / 2}
          cy={_.get(rect, ['y']) + rect?.height / 2}
          r="18"
          style={{
            pointerEvents: 'none',
            fill:
              roomStatus === null || roomStatus?.available === true
                ? '#14D527'
                : roomStatus?.status === 'BookingPending' ||
                  roomStatus?.status === 'BookingConfirmed' ||
                  roomStatus?.status === 'BookingCreated'
                  ? '#E87A14'
                  : '#ed2024',
            stroke: 'white',
            strokeWidth: 2,
            opacity: 1,
          }}
          id={_.get(rect, ['id'])}
        />
      ) : (
        _.get(item, ['data-name']) === selectedUnit && (
          <circle
            cx={_.get(rect, ['x']) + rect?.width / 2}
            cy={_.get(rect, ['y']) + rect?.height / 2}
            r="18"
            style={{
              fill: '#4381EF',
              stroke: 'white',
              strokeWidth: 2,
              opacity: 1,
            }}
            id={_.get(rect, ['id'])}
            onClick={() => onHandleUnit(_.get(rect, ['id']))}
          />
        )
      )}
    </g>
  );
}
function DrawingSvg({
  floorplan,
  onClickUnit = () => null,
  onHover = () => null,
  highlightId = '',
  selectedUnit = null,
  floorName = null,
  setUnitHover = () => null,
}) {
  const baseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  const imagePlan = floorplan?.floorplan?.planImage?.full_path;
  const width = _.get(floorplan, ['floorplan', 'plan', 'width']);
  const height = _.get(floorplan, ['floorplan', 'plan', 'height']);
  const units = _.get(floorplan, ['floorplan', 'plan', 'units']);

  const planImagePath = `${baseUrl}${imagePlan}`;
  return (
    <Container bsPrefix="floorplan-container">
      {imagePlan && <img src={planImagePath} alt="floorplan" className="img-plan " />}
      {!_.isNil(selectedUnit) ? (
        <Col>
          <div className="legend-container">
            <div className="legend-container-row outer-legend-container-row">
              <Container bsPrefix="legend-container-row">
                <div className="legend-select-unit" />
                <span>{`ห้องที่คุณเลือก : ${selectedUnit}`}</span>
              </Container>
            </div>
            <div className="legend-container-row-last">
              <span>{`ชั้น : ${_.isNil(floorName) ? _.get(floorplan, ['label']) : floorName}`}</span>
            </div>
          </div>
        </Col>
      ) : (
        <Col>
          <div className="legend-container">
            <div className="legend-container-row outer-legend-container-row">
              <Container bsPrefix="legend-container-row">
                <div className="legend-available" />
                <span>ว่าง</span>
              </Container>
              <Container bsPrefix="legend-container-row">
                <div className="legend-booking" />
                <span>กำลังจอง</span>
              </Container>
              <Container bsPrefix="legend-container-row">
                <div className="legend-sold" />
                <span>Sold out</span>
              </Container>
            </div>
            <div className="legend-container-row-last">
              <span>{`ชั้น : ${_.get(floorplan, ['floorplan', 'label'])}`}</span>
            </div>
          </div>
        </Col>
      )}
      <svg viewBox={`0 0 ${width} ${height}`} style={{ position: 'absolute', margin: 0, padding: 0 }}>
        {_.map(units, (item, key) => {
          if (_.includes(_.get(item, ['id']), 'lift') ||
            _.includes(_.get(item, ['id']), 'space') ||
            _.includes(_.get(item, ['id']), 'toilet') ||
            _.includes(_.get(item, ['id']), 'lobby') ||
            _.includes(_.get(item, ['id']), 'corridor') ||
            _.includes(_.get(item, ['id']), 'walk') ||
            _.includes(_.get(item, ['id']), 'office') ||
            _.includes(_.get(item, ['id']), 'wait') ||
            _.includes(_.get(item, ['id']), 'swim') ||
            _.includes(_.get(item, ['id']), 'drop') ||
            _.includes(_.get(item, ['id']), 'terrace')
          ) {
            return;
          }
          if (item['polygon'] && item['polygon'].points !== '') {
            return <DrawPolygon
              key={key}
              item={item['polygon']}
              rect={item['rect']}
              onHandleUnit={onClickUnit}
              highlightId={highlightId}
              onHover={onHover}
              roomStatus={item['status']}
              onClickUnit={onClickUnit}
              selectedUnit={selectedUnit}
              setUnitHover={setUnitHover}
              width={width}
            />;
          }
        })}
      </svg>
    </Container>
  );
}

export default DrawingSvg;
