import axios from 'axios';
import qs from 'qs';

// To be called when the user's access token expires
export default async function refresh(accessToken, refreshToken) {
  if (!refreshToken) return;
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/auth/customer/refresh`,
      qs.stringify({ refresh_token: refreshToken }),
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    return { status: true, data: response?.data?.result };
  } catch (error) {
    return { status: false, error };
  }
}
