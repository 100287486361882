import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import { ConfirmationIconSVG } from '../../../assets';
import '../../../css/WarningModal.min.css';

export default function WarningModal({ onConfirm, onHide, show }) {
  return (
    <Modal centered onHide={onHide} show={show}>
      <Modal.Header closeButton closeVariant="white">
        <Image src={ConfirmationIconSVG} />
        <Modal.Title>ต้องการยกเลิกรายการชำระหรือไม่</Modal.Title>
      </Modal.Header>
      <Modal.Footer className="warning-modal-footer" style={{ paddingTop: 24 }}>
        <Row xs={2}>
          <Col className="warning-modal-button-column">
            <Button variant="outline-danger" onClick={onHide}>
              ยกเลิก
            </Button>
          </Col>
          <Col className="warning-modal-button-column">
            <Button variant="danger" onClick={onConfirm}>
              ตกลง
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}
