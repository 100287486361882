import axios from 'axios';

export default async function listUnitSizes(sortDirection = 'asc') {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/units/size?sort_column=size&sort_direction=${sortDirection}`
    );
    return {
      status: true,
      data: response?.data?.result?.map((option) => ({
        label: option.title_th,
        value: option.value,
      })),
    };
  } catch (error) {
    return { status: false, error };
  }
}
