export const initHistory = () => {
  sessionStorage.setItem(['history'], []);
}

export const addToHistory = (newRoute) => {
  let previousRoutes = null;
  if (typeof sessionStorage.getItem(['history']) === 'string') {
    previousRoutes = [sessionStorage.getItem(['history'])];
  } else {
    previousRoutes = sessionStorage.getItem(['history'])
  }
  sessionStorage.setItem(['history'], previousRoutes ? [...previousRoutes, newRoute] : [newRoute]);
}

export const checkHistory = (route) => {
  const history = sessionStorage.getItem(['history'])?.split(',')?.filter((item) => item);
  return history?.filter((item) => item === route).length;
}

export const clearHistory = () => {
  sessionStorage.removeItem(['history']);
}
