import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'wouter';

import { listFloors, listUnitTypes } from '../../../api/master-data';
import { cancelBooking } from '../../../api/booking';
import { getUnit } from '../../../api/units/';
import BookingDetailsCard from '../../cards/booking-details-card';
import WarningModal from '../../modals/warning-modal';
import RoomDetailsSummarySection from '../../room-details-summary-section';
import { addToHistory, checkHistory } from '../../../utils/history';

export default function BookingDetails() {
  const [location, setLocation] = useLocation();
  const params = useParams();

  const [unitDetails, setUnitDetails] = useState({});
  useEffect(() => {
    async function getUnitDetails() {
      const { data: floors } = await listFloors();
      const { data: unitTypes } = await listUnitTypes();
      const { data } = await getUnit(params.bookingPeriodID, params.unitID);
      setUnitDetails({
        ...data,
        unit_floor: floors?.find((option) => option?.value === data.unit_floor_id)?.label,
        unit_types: unitTypes?.find((option) => option?.value === data.unit_types_id)?.label,
      });
    }
    getUnitDetails();
  }, []);

  const cancelCurrentBooking = async () => {
    const { status } = await cancelBooking(params.bookingID);
    return status;
  };
  const [showWarning, setShowWarning] = useState(false);
  if (checkHistory('booking-details') > 1 && cancelCurrentBooking()) setLocation('~/', { replace: true });
  useEffect(() => {
    addToHistory('booking-details');
    window.onbeforeunload = () => '';
    if (window.history.state !== 'back') {
      window.history.pushState('back', '', `/booking${location}`);
    }
    window.onpopstate = () => setShowWarning(true);

    return () => {
      window.onbeforeunload = null;
      window.onpopstate = null;
    };
  }, []);

  const onWarningConfirm = async () => {
    if (cancelCurrentBooking()) setLocation('~/', { replace: true });
  };

  return (
    <>
      <RoomDetailsSummarySection
        images={unitDetails.images ? unitDetails.images : null}
        unitNumber={unitDetails.unit_no}
        roomType={unitDetails.unit_types}
        roomSize={unitDetails.size}
        floor={unitDetails.unit_floor}
      />
      <BookingDetailsCard
        roomPrice={unitDetails.regular_price}
        discount={unitDetails.discount}
        netPrice={unitDetails.net_price}
        depositFees={unitDetails.booking_price}
        contractFees={unitDetails.contract_fee}
        downPaymentNumberOfInstallments={unitDetails.monthly_installment_count}
        downPaymentAmountPerInstallment={unitDetails.monthly_installment}
        balloonPaymentNumberOfInstallments={unitDetails.balloon_installment_count}
        balloonPaymentAmountPerInstallment={unitDetails.balloon_installment}
        totalDownPaymentAmount={unitDetails.total_down_payment}
        ownershipTransferFees={unitDetails.ownership_transfer_fee}
      />
      <WarningModal
        onConfirm={onWarningConfirm}
        onHide={() => {
          window.history.pushState('back', '', `/booking${location}`);
          setShowWarning(false);
        }}
        show={showWarning}
      />
    </>
  );
}
