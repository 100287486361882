import SizedBox from './SizedBox';

const sizeXXXS = 4;
const sizeXXS = 8;
const sizeXS = 16;
const sizeS = 20;
const sizeM = 32;
const sizeL = 40;
const sizeXL = 64;
const sizeXXL = 80;
const sizeXXXL = 128;

export const sizedBoxVerticalXXXS = <SizedBox height={sizeXXXS} />;
export const sizedBoxVerticalXXS = <SizedBox height={sizeXXS} />;
export const sizedBoxVerticalXS = <SizedBox height={sizeXS} />;
export const sizedBoxVerticalS = <SizedBox height={sizeS} />;
export const sizedBoxVerticalM = <SizedBox height={sizeM} />;
export const sizedBoxVerticalL = <SizedBox height={sizeL} />;
export const sizedBoxVerticalXL = <SizedBox height={sizeXL} />;
export const sizedBoxVerticalXXL = <SizedBox height={sizeXXL} />;
export const sizedBoxVerticalXXXL = <SizedBox height={sizeXXXL} />;

export const sizedBoxHorizontalXXXS = <SizedBox width={sizeXXXS} />;
export const sizedBoxHorizontalXXS = <SizedBox width={sizeXXS} />;
export const sizedBoxHorizontalXS = <SizedBox width={sizeXS} />;
export const sizedBoxHorizontalS = <SizedBox width={sizeS} />;
export const sizedBoxHorizontalM = <SizedBox width={sizeM} />;
export const sizedBoxHorizontalL = <SizedBox width={sizeL} />;
export const sizedBoxHorizontalXL = <SizedBox width={sizeXL} />;
export const sizedBoxHorizontalXXL = <SizedBox width={sizeXXL} />;
export const sizedBoxHorizontalXXXL = <SizedBox width={sizeXXXL} />;

export const sizedBoxXXXS = <SizedBox height={sizeXXS} width={sizeXXXS} />;
export const sizedBoxXXS = <SizedBox height={sizeXXS} width={sizeXXS} />;
export const sizedBoxXS = <SizedBox height={sizeXS} width={sizeXS} />;
export const sizedBoxS = <SizedBox height={sizeS} width={sizeS} />;
export const sizedBoxM = <SizedBox height={sizeM} width={sizeM} />;
export const sizedBoxL = <SizedBox height={sizeL} width={sizeL} />;
export const sizedBoxXL = <SizedBox height={sizeXL} width={sizeXL} />;
export const sizedBoxXXL = <SizedBox height={sizeXXL} width={sizeXXL} />;
export const sizedBoxXXXL = <SizedBox height={sizeXXL} width={sizeXXXL} />;
