import { axiosInstance } from '../../customAxios';
import qs from 'qs';

export default async function getCustomerUnits(data) {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/v1/booking/my-units?${qs.stringify(data)}`);
    return { status: true, data: response?.data };
  } catch (error) {
    return { status: false, error };
  }
}
