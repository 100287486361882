import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'wouter';

import { LoginRegisterIconSVG } from '../../../assets';

import LoginPhoneNumberModal from '../login-phone-number-modal';

export default function LoginRegisterModal({ show, onHide }) {
  const [showModal, setShowModal] = useState(false);
  const handleSwitchModal = () => {
    onHide();
    setShowModal(true);
  };

  return (
    <>
      <Modal centered onHide={onHide} show={show}>
        <Modal.Header closeButton closeVariant="white">
          <Image src={LoginRegisterIconSVG} fluid />
          <Modal.Title>โปรดเข้าสู่ระบบหรือลงทะเบียน เพื่อทำการจอง</Modal.Title>
        </Modal.Header>
        <Modal.Body>กรุณาเข้าสู่ระบบ หรือลงทะเบียนเพื่อทำการจอง</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSwitchModal} variant="danger">
            เข้าสู่ระบบ
          </Button>
          <Link href="/register" onClick={onHide}>
            <Button className="w-100" variant="outline-danger">
              ลงทะเบียน
            </Button>
          </Link>
        </Modal.Footer>
      </Modal>
      <LoginPhoneNumberModal show={showModal} onHide={() => setShowModal(false)} />
    </>
  );
}
