import { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import { listFloors, listUnitTypes } from '../../../api/master-data';
import { getCustomerUnits } from '../../../api/customer';
import { NoBookingIconSVG } from '../../../assets';
import BookingCard from '../../cards/booking-card';
import CustomPagination from '../../pagination';

import '../../../css/MyBooking.min.css';

export default function MyBooking() {
  const [floors, setFloors] = useState(null);
  const [unitTypes, setUnitTypes] = useState(null);
  const [page, setPage] = useState({
    activePage: 1,
    content: [],
    paginationItems: [],
  });

  useEffect(() => {
    const getMyBookings = async () => {
      const { data: floors } = await listFloors();
      const { data: unitTypes } = await listUnitTypes();
      setFloors(floors);
      setUnitTypes(unitTypes);

      const { data } = await getCustomerUnits({ page: page.activePage, per_page: 6 });
      setPage((prev) => ({ ...prev, content: data?.result }));
      if (page.paginationItems.length === 0) {
        const paginationItems = [];
        for (let i = 1; i <= Math.ceil(data?.total_data / 6); i++) {
          paginationItems.push(i);
        }
        setPage((prev) => ({ ...prev, paginationItems }));
      }
    };

    getMyBookings();
  }, [page.activePage]);

  return (
    <Container
      className="my-booking-page-container"
      fluid
      {...(page.content?.length === 0 ? { style: { paddingBottom: 0 } } : {})}
    >
      <p id="my-booking-heading">My Booking</p>
      {page.content?.length === 0 ? (
        <Container className="no-content-container">
          <Image src={NoBookingIconSVG} />
          <p>No Booking</p>
        </Container>
      ) : (
        <Row className="bookings-row">
          {page.content?.map((unit) => (
            <Col>
              <BookingCard
                thumbnail={
                  unit?.thumbnail?.full_path ? `${process.env.REACT_APP_IMAGE_BASE_URL}${unit?.thumbnail?.full_path}` : null
                }
                bookingNumber={unit.sale_status.invoice_no}
                receipt={unit?.receipt_file}
                unitProject="Klos Ratchada 7"
                unitNumber={unit.unit_no}
                bookingDate={unit.sale_status.booked_at}
                unitType={unitTypes.find((option) => option?.value === unit.unit_types_id)?.label}
                unitSize={unit.size}
                unitFloor={floors.find((option) => option?.value === unit.unit_floor_id)?.label}
                unitID={unit.unit_id}
              />
            </Col>
          ))}
        </Row>
      )}

      {page.paginationItems.length > 1 && <CustomPagination page={page} setPage={setPage} />}
    </Container>
  );
}
