import axios from 'axios';

import { axiosInstance } from '../../customAxios';
import { accessToken } from '../../utils/tokens';

export default async function getUnit(bookingPeriod, unitID) {
  const axiosInstanceNoAuthorization = axios.create();
  if (!accessToken) {
    delete axiosInstanceNoAuthorization.defaults.headers.common['Authorization'];
  }

  try {
    const response = await (accessToken ? axiosInstance : axiosInstanceNoAuthorization).get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/booking/periods/units/${bookingPeriod}/${unitID}`
    );
    return { status: true, data: response?.data?.result };
  } catch (error) {
    return { status: false, error };
  }
}
