import Container from 'react-bootstrap/Container';

import '../../../css/PrivacyPolicy.min.css';

export default function PrivacyPolicy() {
  return (
    <Container className="privacy-policy-container" fluid>
      <h1 className="privacy-policy-heading">นโยบายความเป็นส่วนตัว</h1>
      <p>
        บริษัท เฟรเซอร์ส พร็อพเพอร์ตี้ (ประเทศไทย) จำกัด (มหาชน) และ บริษัทในเครือ ( "
        <span className="privacy-policy-text-emphasis">บริษัทฯ</span>" "<span className="privacy-policy-text-emphasis">เรา</span>"
        "<span className="privacy-policy-text-emphasis">พวกเรา</span>" หรือ "
        <span className="privacy-policy-text-emphasis">ของเรา</span>") มีความมุ่งมั่นที่จะคุ้มครองข้อมูลส่วนบุคคล
        (ตามคำนิยามด้านล่าง) ของท่าน บริษัทฯ ตระหนักดีว่าท่านให้ความสนใจเกี่ยวกับวิธีการที่บริษัทฯ เก็บรวบรวม ใช้ เปิดเผย และ/หรือ
        โอนข้อมูลของท่านไปยังต่างประเทศ ข้อมูลส่วนบุคคลที่ท่านให้ไว้กับบริษัทฯ จะช่วยให้บริษัทฯ
        สามารถจัดหาผลิตภัณฑ์และบริการได้อย่างเหมาะสมตามความต้องการของท่าน ซึ่งไม่จำกัดเฉพาะผลิตภัณฑ์และบริการของบริษัทฯ เท่านั้น
        แต่ยังรวมถึงผลิตภัณฑ์และบริการในเครือเฟรเซอร์ส พร็อพเพอร์ตี้ บริษัทฯ ขอขอบพระคุณที่ท่านมอบความไว้วางใจให้แก่บริษัทฯ
        และบริษัทฯ
        จะจัดการข้อมูลส่วนบุคคลของท่านอย่างรอบคอบและสมเหตุสมผลไปพร้อมกับการส่งมอบประสบการณ์เฉพาะบุคคลและการบริการลูกค้าให้แก่ท่าน
      </p>
      <p>
        ในนโยบายความเป็นส่วนตัว ("<span className="privacy-policy-text-emphasis">นโยบาย</span>") นี้จะอธิบายการเก็บรวบรวม ใช้
        เปิดเผย และโอนข้อมูลส่วนบุคคล (ตามคำนิยามด้านล่าง)
        ตลอดจนสิทธิในเรื่องการคุ้มครองข้อมูลส่วนบุคคลของบุคคลภายนอกองค์กรที่มีปฏิสัมพันธ์กับบริษัทฯ และ มีข้อมูลส่วนบุคคล
        (ตามคำนิยามด้านล่าง) ซึ่งอยู่ในการจัดการของบริษัทฯ อันเป็นส่วนหนึ่งของการดำเนินธุรกิจของ บริษัทฯ
        หรือที่เกี่ยวกับผลิตภัณฑ์และบริการของบริษัทฯ อันประกอบไปด้วย (1) ลูกค้าบุคคลธรรมดา (2) บุคคลสำหรับติดต่อ ลูกจ้าง บุคลากร
        ผู้ได้รับอนุญาต ผู้แทน ตัวแทน กรรมการ ผู้ถือหุ้น หรือบุคคลใดก็ตามที่มีอำนาจในการก่อความสัมพันธ์ทางธุรกิจ
        หรือทำรายการธุรกรรมในนามของลูกค้านิติบุคคลของบริษัทฯ และบริษัทในเครือของลูกค้า (3)
        ผู้ใช้งานและผู้เยี่ยมชมเว็บไซต์ของบริษัทฯ (4) ผู้ซึ่งได้รับผลิตภัณฑ์และบริการรายอื่น ๆ ของบริษัทฯ และ (5)
        บุคคลอื่นที่บริษัทฯ ได้รับข้อมูลส่วนบุคคล (ตามคำนิยามด้านล่าง) อันเกี่ยวกับบุคคลดังกล่าว (เรียกรวมกันว่า "
        <span className="privacy-policy-text-emphasis">ท่าน</span>" หรือ "
        <span className="privacy-policy-text-emphasis">ของท่าน</span>") นโยบายความเป็นส่วนตัวนี้ยังใช้กับเว็บไซต์
        แอปพลิเคชันอุปกรณ์เคลื่อนที่ เว็บไซต์เครือข่ายสังคมออนไลน์ ช่องทางสื่อสารออนไลน์ งานกิจกรรมและกิจกรรมต่างๆ
        ตลอดจนสถานที่อื่นๆ ที่บริษัทฯ เก็บรวบรวมข้อมูลส่วนบุคคลของท่าน ทั้งนี้
        โปรดอ่านนโยบายความเป็นส่วนตัวนี้ประกอบกับข้อกำหนดและเงื่อนไขของบริการที่ท่านใช้
        ซึ่งอาจมีรายละเอียดแยกไว้ในเรื่องข้อมูลส่วนบุคคลเกี่ยวกับท่านที่บริษัทฯ ทำการเก็บรวบรวม
      </p>
      <p>
        บริษัทฯ อาจมีการทบทวนและแก้ไขเพิ่มเติมนโยบายความเป็นส่วนตัวนี้เป็นครั้งคราว
        เพื่อให้สอดคล้องกับการแก้ไขเพิ่มเติมกฎหมายที่ใช้บังคับและวิธีการที่บริษัทฯ ใช้จัดการกับข้อมูลส่วนบุคคล บริษัทฯ
        ขอแนะนำให้ท่านกลับมาเยี่ยมชมนโยบายความเป็นส่วนตัวเป็นครั้งคราวเพื่อรับทราบการแก้ไขเพิ่มเติมดังกล่าว
        การเปลี่ยนแปลงใดก็ตามของนโยบายความเป็นส่วนตัวนี้จะมีผลบังคับเมื่อบริษัทฯ
        เผยแพร่นโยบายความเป็นส่วนตัวฉบับปรับปรุงล่าสุดทางเว็บไซต์ แอปพลิเคชัน หรือช่องทางสื่อสารอื่นของบริษัทฯ
        หากมีการเปลี่ยนแปลงสำคัญใดๆ บริษัทฯ จะแจ้งให้ท่านทราบเพิ่มเติมต่อไป
      </p>
      <ol>
        <li className="privacy-policy-section-title">ข้อมูลส่วนบุคคลที่บริษัทฯ เก็บรวบรวม</li>
        <p>
          "<span className="privacy-policy-text-emphasis">ข้อมูลส่วนบุคคล</span>"
          หมายถึงข้อมูลที่ระบุตัวบุคคลหรือสามารถระบุตัวบุคคลเกี่ยวกับท่านตามที่ได้ระบุรายละเอียดไว้ด้านล่าง
          ซึ่งในการจัดหาผลิตภัณฑ์และบริการของบริษัทฯ แก่ท่าน บริษัทฯ อาจเก็บรวบรวมข้อมูลส่วนบุคคลโดยตรง (เช่น ผ่านตัวแทน พนักงาน
          ผู้จัดการฝ่ายลูกค้าสัมพันธ์ พนักงานขาย เจ้าหน้าที่ศูนย์บริการข้อมูลลูกค้า แอปพลิเคชัน)
          หรือโดยอ้อมจากท่านหรือแหล่งข้อมูลอื่น (เช่น สื่อสังคมออนไลน์ แพลตฟอร์มออนไลน์ของบุคคลภายนอก หรือช่องทางสาธารณะอื่นๆ)
          หรือผ่านบริษัทแม่ของบริษัทฯ บริษัทในเครือ บริษัทย่อย พันธมิตรทางธุรกิจ เจ้าหน้าที่ราชการ หรือบุคคลภายนอก
          ทั้งนี้ประเภทของข้อมูลส่วนบุคคลที่เก็บรวบรวมจะขึ้นอยู่กับลักษณะการติดต่อของท่านกับบริษัทฯ บริการ
          หรือผลิตภัณฑ์ที่ท่านต้องการได้รับจากบริษัทฯ และจากภายในกลุ่มเฟรเซอร์ส พร็อพเพอร์ตี้
        </p>
        <p>
          "<span className="privacy-policy-text-emphasis">ข้อมูลอ่อนไหว</span>"
          หมายถึงข้อมูลส่วนบุคคลที่กฎหมายจัดประเภทให้เป็นข้อมูลอ่อนไหว บริษัทฯ จะเก็บรวบรวม ใช้
          และเปิดเผยข้อมูลส่วนบุคคลที่ละเอียดอ่อน
          และโอนข้อมูลส่วนบุคคลดังกล่าวไปยังต่างประเทศเมื่อได้รับความยินยอมโดยชัดแจ้งจากท่านหรือตามที่กฎหมายกำหนดไว้เท่านั้น
          ข้อมูลส่วนบุคคลต่อไปนี้ได้รับการจัดประเภทตามลักษณะธุรกิจที่มีความหลากหลายของกลุ่มเฟรเซอร์ส พร็อพเพอร์ตี้{' '}
        </p>
        <ol>
          <li className="privacy-policy-text-emphasis">
            1.1. <span className="privacy-policy-subsection-title">โรงแรม</span>
          </li>
          <ul>
            <li>
              <span className="privacy-policy-text-emphasis">1) รายละเอียดส่วนบุคค</span> เช่น คำนำหน้าชื่อ ชื่อ นามสกุล เพศ อายุ
              สัญชาติ วันเกิด ชื่อตำแหน่ง ตำแหน่ง สถานที่ทำงาน ข้อมูลในบัตรที่ทางราชการออกให้ (เช่น หมายเลขบัตรประจำตัวประชาชน
              เลขที่หนังสือเดินทาง หรือข้อมูลระบุตัวบุคคลอื่น ๆ) รายละเอียดในทะเบียนบ้าน ภาพถ่าย งานอดิเรก และความสนใจของท่าน
              เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">2) รายละเอียดสำหรับติดต่อ</span> เช่น ที่อยู่ทางไปรษณีย์
              หมายเลขโทรศัพท์ อีเมล์ LINE ID WhatsApp ID WeChat ID บัญชีเฟซบุ๊ก และบัญชีอินสตาแกรม เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">3) รายละเอียดทางการเงิน</span> เช่น รายได้ เงินเดือน
              รายละเอียดบัญชีธนาคาร รายละเอียดบัตรเครดิต/เดบิต และข้อมูลอื่น ๆ ที่เกี่ยวกับการเงิน เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">4) ข้อมูลอ่อนไหว</span> เช่น
              ข้อมูลอ่อนไหวตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ หรือชาติพันธุ์) เป็นต้น
            </li>
          </ul>
          <li className="privacy-policy-text-emphasis">
            1.2. <span className="privacy-policy-subsection-title">อสังหาริมทรัพย์เพื่อการอยู่อาศัย</span>
          </li>
          <ul>
            <li>
              <span className="privacy-policy-text-emphasis">1) รายละเอียดส่วนบุคคล</span> เช่น คำนำหน้าชื่อ ชื่อ นามสกุล เพศ อายุ
              สัญชาติ วันเกิด ชื่อตำแหน่ง ตำแหน่ง สถานที่ทำงาน ประวัติการทำงาน ประวัติการศึกษา ข้อมูลในบัตรที่ทางราชการออกให้
              ตลอดจนวันที่ออกบัตรและวันที่บัตรหมดอายุ (เช่น หมายเลขบัตรประจำตัวประชาชน เลขที่หนังสือเดินทาง)
              รายละเอียดในทะเบียนบ้าน ภาพถ่าย สถานภาพสมรส งานอดิเรก และความสนใจของท่าน เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">2) รายละเอียดสำหรับติดต่อ</span> เช่น ที่อยู่ทางไปรษณีย์
              ที่อยู่ตามทะเบียนบ้าน ที่อยู่ของสถานที่ทำงาน หมายเลขโทรศัพท์ อีเมล LINE ID WhatsApp ID WeChat ID และบัญชีเฟซบุ๊ก
              เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">3) รายละเอียดทางการเงิน</span> เช่น รายได้ เงินเดือน
              รายละเอียดข้อมูลบัญชีธนาคาร (เช่น เลขที่บัญชี ชื่อธนาคาร สาขา ประเภทบัญชี) หมายเลขเช็ค รหัส SWIFT
              รายละเอียดการชำระเงิน รายละเอียดบัตรเครดิต/เดบิต และข้อมูลอื่นๆ ที่เกี่ยวกับการเงิน เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">4) รายละเอียดผลิตภัณฑ์/บริการ</span> เช่น จำนวนหน่วยที่จอง
              งบประมาณที่คาดการณ์ เลขที่ห้อง ชั้น ประเภทของห้อง ประเภทของห้องนอน พื้นที่ห้อง ราคาซื้อ และรหัสลูกค้า เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">5) ข้อมูลอ่อนไหว</span> เช่น
              ข้อมูลอ่อนไหวตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ หรือชาติพันธุ์) เป็นต้น
            </li>
          </ul>
          <li className="privacy-policy-text-emphasis">
            1.3. <span className="privacy-policy-subsection-title">อสังหาริมทรัพย์เพื่อการพาณิชยกรรม</span>
          </li>
          <ul>
            <li>
              <span className="privacy-policy-text-emphasis">1) รายละเอียดส่วนบุคคล</span> เช่น คำนำหน้าชื่อ ชื่อ นามสกุล เพศ อายุ
              สัญชาติ วันเกิด ชื่อตำแหน่ง ตำแหน่ง สถานที่ทำงาน ประวัติการทำงาน ข้อมูลในบัตรที่ทางราชการออกให้
              ตลอดจนวันที่ออกบัตรและวันที่บัตรหมดอายุ (เช่น หมายเลขบัตรประจำตัวประชาชน หรือเลขที่หนังสือเดินทาง)
              รายละเอียดในทะเบียนบ้าน ใบอนุญาตทำงาน หมายเลขทะเบียนรถ ภาพถ่าย งานอดิเรก และความสนใจของท่าน เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">2) รายละเอียดสำหรับติดต่อ</span> เช่น ที่อยู่ทางไปรษณีย์
              หมายเลขโทรศัพท์ ข้อมูลตำแหน่งที่อยู่ อีเมล์ LINE ID WhatsApp ID WeChat ID และบัญชีเฟซบุ๊ก เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">3) รายละเอียดทางการเงิน</span> เช่น รายได้ เงินเดือน
              รายละเอียดของบัญชีธนาคาร (เช่น เลขที่บัญชี ชื่อธนาคาร สาขา ประเภทบัญชี) และข้อมูลอื่น ๆ ที่เกี่ยวกับการเงิน เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">4) ข้อมูลอ่อนไหว</span> เช่น
              ข้อมูลอ่อนไหวตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ หรือชาติพันธุ์) ข้อมูลทางพันธุกรรม (เช่น
              ลำดับพันธุกรรมของบุคคล) ข้อมูลชีวภาพ (เช่น ลายนิ้วมือ ข้อมูลการจำลองใบหน้า หรือข้อมูลการสแกนม่านตา)
              และข้อมูลสหภาพแรงงาน เป็นต้น
            </li>
          </ul>
          <li className="privacy-policy-text-emphasis">
            1.4. <span className="privacy-policy-subsection-title">ห้างสรรพสินค้า</span>
          </li>
          <ul>
            <li>
              <span className="privacy-policy-text-emphasis">1) รายละเอียดส่วนบุคคล</span> เช่น คำนำหน้าชื่อ ชื่อ นามสกุล เพศ อายุ
              สัญชาติ วันเกิด ชื่อตำแหน่ง ตำแหน่ง สถานที่ทำงาน ประวัติการทำงาน ข้อมูลในบัตรที่ทางราชการออกให้
              ตลอดจนวันที่ออกบัตรและวันที่บัตรหมดอายุ (เช่น หมายเลขบัตรประจำตัวประชาชน เลขที่หนังสือเดินทาง)
              รายละเอียดในทะเบียนบ้าน ใบอนุญาตทำงาน หมายเลขทะเบียนรถ และภาพถ่าย เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">2) รายละเอียดสำหรับติดต่อ</span> เช่น ที่อยู่ทางไปรษณีย์
              หมายเลขโทรศัพท์ ข้อมูลตำแหน่งที่อยู่ อีเมล์ LINE ID WhatsApp ID WeChat ID บัญชีเฟซบุ๊ก และคิวอาร์โค้ด เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">3) รายละเอียดทางการเงิน</span> เช่น รายได้ เงินเดือน
              รายละเอียดของบัญชีธนาคาร (เช่น เลขที่บัญชี ชื่อธนาคาร สาขา ประเภทบัญชี) และข้อมูลอื่นๆ ที่เกี่ยวกับการเงิน เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">4) รายละเอียดเกี่ยวกับธุรกรรม</span> เช่น
              รายละเอียดเกี่ยวกับการชำระเงินให้แก่ท่านหรือที่ได้รับจากท่าน วันที่ และ/หรือเวลาชำระเงิน จำนวนเงินและวิธีการชำระเงิน
              รายละเอียดเกี่ยวกับรายการแลกรางวัล วันที่และสถานที่ ในการซื้อ เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">5) รายละเอียดทางเทคนิค</span> เช่น เลขที่อยู่ไอพี (IP Address)
              และเลขประจำการ์ดเครือข่าย (MAC Address) เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">6) รายละเอียดทางพฤติกรรม</span> เช่น ข้อมูลเกี่ยวกับพฤติกรรม
              วิถีชีวิต งานอดิเรก ความสนใจ ทัศนคติ ความเชื่อ และข้อมูลการติดต่อสื่อสารของท่าน เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">7) รายละเอียดส่วนบุคคล</span> เช่น หมายเลขสมาชิกแอปพลิเคชัน เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">8) ข้อมูลของบุคคลอื่น</span> เช่น ชื่อบิดา มารดา หรือผู้ปกครอง
              เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">9) ข้อมูลอ่อนไหว</span> เช่น
              ข้อมูลอ่อนไหวที่ละเอียดอ่อนตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ ชาติพันธุ์) ข้อมูลชีวภาพ (เช่น
              ลายนิ้วมือ ข้อมูลการจำลองใบหน้า ข้อมูลการสแกนม่านตา) และประวัติอาชญากรรม เป็นต้น
            </li>
          </ul>
          <li className="privacy-policy-text-emphasis">
            1.5. <span className="privacy-policy-subsection-title">ศูนย์ประชุม</span>
          </li>
          <ul>
            <li>
              <span className="privacy-policy-text-emphasis">1) รายละเอียดส่วนบุคคล</span> เช่น คำนำหน้าชื่อ ชื่อ นามสกุล เพศ อายุ
              สัญชาติ วันเกิด ชื่อตำแหน่ง ตำแหน่ง ข้อมูลในบัตรที่ทางราชการออกให้ (เช่น หมายเลขบัตรประจำตัวประชาชน
              เลขที่หนังสือเดินทาง) รายละเอียดในทะเบียนบ้าน ใบอนุญาตทำงาน และภาพถ่าย เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">2) รายละเอียดสำหรับติดต่อ</span> เช่น ที่อยู่ทางไปรษณีย์
              หมายเลขโทรศัพท์ อีเมล์ LINE ID บัญชีเฟซบุ๊ก บัญชี WhatsApp บัญชี WeChat บัญชีทวิตเตอร์ บัญชีอินสตาแกรม
              บัญชีแมสเซนเจอร์ และID ของเว็บไซต์เครือข่ายสังคมออนไลน์อื่น ๆ เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">3) รายละเอียดทางการเงิน</span> เช่น รายได้ รายละเอียดของบัญชีธนาคาร
              หมายเลขบัตรเครดิต/เดบิต และข้อมูลอื่นๆ ที่เกี่ยวกับการเงิน เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">4) ข้อมูลส่วนอ่อนไหว</span> เช่น
              ข้อมูลส่วนอ่อนไหวตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ ชาติพันธุ์) ข้อมูลชีวภาพ (เช่น ลายนิ้วมือ
              ข้อมูลการจำลองใบหน้า หรือข้อมูลการสแกนม่านตา) ประวัติอาชญากรรม และข้อมูลสุขภาพ เป็นต้น
            </li>
          </ul>
          <li className="privacy-policy-text-emphasis">
            1.6. <span className="privacy-policy-subsection-title">ดาต้าเซ็นเตอร์</span>
          </li>
          <ul>
            <li>
              <span className="privacy-policy-text-emphasis">1) รายละเอียดส่วนบุคคล</span> เช่น คำนำหน้าชื่อ ชื่อ นามสกุล
              ชื่อตำแหน่ง ตำแหน่ง สถานที่ทำงาน ข้อมูลในบัตรที่ทางราชการออกให้ (เช่น หมายเลขบัตรประจำตัวประชาชน เลขหนังสือเดินทาง)
              ภาพถ่าย และข้อมูลอื่น ๆในนามบัตร เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">2) รายละเอียดสำหรับติดต่อ</span> เช่น ที่อยู่ทางไปรษณีย์
              หมายเลขโทรศัพท์ อีเมล์ เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">3) รายละเอียดทางการเงิน</span> เช่น เงินเดือน
              รายละเอียดของบัญชีธนาคาร เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">4) ข้อมูลอ่อนไหว</span> เช่น
              ข้อมูลอ่อนไหวตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ ชาติพันธุ์) ข้อมูลชีวภาพ (เช่น ลายนิ้วมือ
              ข้อมูลการจำลองใบหน้า ข้อมูลการสแกนม่านตา) ประวัติอาชญากรรม ข้อมูลสุขภาพ เป็นต้น
            </li>
          </ul>
          <li className="privacy-policy-text-emphasis">
            1.7. <span className="privacy-policy-subsection-title">คลังสินค้าและโรงงาน</span>
          </li>
          <ul>
            <li>
              <span className="privacy-policy-text-emphasis">1) รายละเอียดส่วนบุคคล</span> เช่น คำนำหน้าชื่อ ชื่อ นามสกุล
              ชื่อตำแหน่ง ตำแหน่ง สถานที่ทำงาน ภาพถ่าย เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">2) รายละเอียดสำหรับติดต่อ</span> เช่น ที่อยู่ทางไปรษณีย์
              หมายเลขโทรศัพท์ อีเมล์ LINE ID บัญชีเฟซบุ๊ก บัญชี WhatsApp บัญชี WeChat บัญชีทวิตเตอร์ บัญชีอินสตาแกรม
              บัญชีแมสเซนเจอร์ และID ของเว็บไซต์เครือข่ายสังคมออนไลน์อื่น ๆ เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">3) รายละเอียดทางการเงิน</span> เช่น เงินเดือน
              รายละเอียดของบัญชีธนาคาร เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">4) ข้อมูลส่วนอ่อนไหว</span> เช่น
              ข้อมูลอ่อนไหวตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ ชาติพันธุ์) เป็นต้น
            </li>
          </ul>
          <li className="privacy-policy-text-emphasis">
            1.8. <span className="privacy-policy-subsection-title">โคเวิร์คกิ้งสเปซ (Co-working)</span>
          </li>
          <ul>
            <li>
              <span className="privacy-policy-text-emphasis">1) รายละเอียดส่วนบุคคล</span> เช่น คำนำหน้าชื่อ ชื่อ นามสกุล เพศ อายุ
              สัญชาติ วันเกิด ชื่อตำแหน่ง ตำแหน่ง สถานที่ทำงาน ประวัติการทำงาน ประวัติการศึกษา ภาษาที่เลือกใช้
              ข้อมูลในบัตรที่ทางราชการออกให้ (เช่น หมายเลขบัตรประจำตัวประชาชนและวันที่ออกบัตร เลขที่หนังสือเดินทาง
              รายละเอียดใบอนุญาตขับรถยนต์) หมายเลขบัตรประจำตัวนักเรียนและวันที่ออกบัตร รายละเอียดในทะเบียนบ้าน ภาพถ่าย วิดีโอ
              บันทึกเสียง และข้อมูลตำแหน่งทางภูมิศาสตร์ เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">2) รายละเอียดสำหรับติดต่อ</span> เช่น ที่อยู่ทางไปรษณีย์
              ที่อยู่สำหรับส่งใบเรียกเก็บเงิน หมายเลขโทรศัพท์ อีเมล์ และบัญชีสื่อสังคมออนไลน์ เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">3) รายละเอียดทางการเงิน</span> เช่น รายได้ รายละเอียดของบัญชีธนาคาร
              รายละเอียดการชำระเงิน และรายละเอียดบัตรเครดิต/เดบิต เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">4) รายละเอียดทางพฤติกรรม</span> เช่น ข้อมูลเกี่ยวกับพฤติกรรม
              วิถีชีวิต ทัศนคติ ความเชื่อ และข้อมูลการติดต่อสื่อสารของท่าน เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">5) รายละเอียดส่วนบุคคล</span> เช่น หมายเลขบัตรอนุญาตเข้าถึง (Access
              card ID) ชื่อผู้ใช้งาน ชื่อที่ปรากฏ รหัสผ่าน และการตั้งค่าผู้ใช้ เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">6) ข้อมูลอ่อนไหว</span> เช่น
              ข้อมูลอ่อนไหวตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ หรือชาติพันธุ์) ข้อมูลสุขภาพ และความพิการ เป็นต้น
            </li>
          </ul>
          <li className="privacy-policy-text-emphasis">
            1.9. <span className="privacy-policy-subsection-title">ปัญญาประดิษฐ์ (AI)</span>
          </li>
          <ul>
            <li>
              <span className="privacy-policy-text-emphasis">1) รายละเอียดส่วนบุคคล</span> เช่น คำนำหน้าชื่อ ชื่อ นามสกุล
              ชื่อตำแหน่ง ตำแหน่ง สถานที่ทำงาน ประวัติการทำงาน หมายเลขทะเบียนรถ ภาพถ่าย เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">2) รายละเอียดสำหรับติดต่อ</span> เช่น ที่อยู่ทางไปรษณีย์
              หมายเลขโทรศัพท์ อีเมล์ เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">3) รายละเอียดทางการเงิน</span> เช่น รายละเอียดของบัญชีธนาคาร เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">4) ข้อมูลส่วนบุคคลที่ละเอียดอ่อน</span> เช่น ข้อมูลการจำลองใบหน้า
              เป็นต้น
            </li>
          </ul>
          <li className="privacy-policy-text-emphasis">
            1.10. <span className="privacy-policy-subsection-title">การค้าส่ง</span>
          </li>
          <ul>
            <li>
              <span className="privacy-policy-text-emphasis">1) รายละเอียดส่วนบุคคล</span> เช่น คำนำหน้าชื่อ ชื่อ นามสกุล เพศ อายุ
              สัญชาติ วันเกิด ข้อมูลในบัตรที่ทางราชการออกให้ (เช่น หมายเลขบัตรประจำตัวประชาชน เลขที่หนังสือเดินทาง)
              รายละเอียดในทะเบียนบ้าน ใบอนุญาตทำงาน ภาพถ่าย เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">2) รายละเอียดสำหรับติดต่อ</span> เช่น ที่อยู่ทางไปรษณีย์
              หมายเลขโทรศัพท์ ข้อมูลตำแหน่งที่อยู่ LINE ID บัญชีเฟซบุ๊ก คิวอาร์โค้ด เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">3) รายละเอียดทางการเงิน</span> เช่นรายได้ รายละเอียดของบัญชีธนาคาร
              และข้อมูลอื่น ๆ เกี่ยวกับการเงิน เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">4) รายละเอียดทางเทคนิค</span> เช่น หมายเลขไอพี (IP Address)
              เลขประจำการ์ดเครือข่าย (MAC Address) เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">5) ข้อมูลอ่อนไหว</span> เช่น
              ข้อมูลอ่อนไหวตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ ชาติพันธุ์) ข้อมูลชีวภาพ (เช่น ลายนิ้วมือ
              ข้อมูลการจำลองใบหน้า ข้อมูลการสแกนม่านตา) ประวัติอาชญากรรม เป็นต้น
            </li>
          </ul>
          <li className="privacy-policy-text-emphasis">
            1.11. <span className="privacy-policy-subsection-title">การค้าปลีก</span>
          </li>
          <ul>
            <li>
              <span className="privacy-policy-text-emphasis">1) รายละเอียดส่วนบุคคล</span> เช่น คำนำหน้าชื่อ ชื่อ นามสกุล เพศ อายุ
              สัญชาติ วันเกิด ข้อมูลในบัตรที่ทางราชการออกให้ (เช่น หมายเลขบัตรประจำตัวประชาชน หรือเลขที่หนังสือเดินทาง)
              และรายละเอียดในทะเบียนบ้าน เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">2) รายละเอียดสำหรับติดต่อ</span> เช่น ที่อยู่ทางไปรษณีย์
              หมายเลขโทรศัพท์ อีเมล์ และ LINE ID เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">3) รายละเอียดทางการเงิน</span> เช่น รายได้ กรอบรายได้
              รายละเอียดของบัญชีธนาคาร รายละเอียดการชำระเงิน รายละเอียดบัตรเครดิต/เดบิต และข้อมูลอื่นๆ ที่เกี่ยวกับการเงิน เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">4) รายละเอียดเกี่ยวกับธุรกรรม</span> เช่น
              รายละเอียดเกี่ยวกับการชำระเงินให้แก่ท่านหรือที่ได้รับจากท่าน วันที่และ/หรือเวลาชำระเงิน จำนวนเงินและวิธีการชำระเงิน
              รายละเอียดเกี่ยวกับรายการแลกรางวัล วันที่และสถานที่ ในการซื้อ
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">5) รายละเอียดทางพฤติกรรม</span> เช่น ข้อมูลเกี่ยวกับพฤติกรรมของท่าน
              ความสนใจ งานอดิเรก วิถีชีวิต ทัศนคติ ความเชื่อ และข้อมูลการติดต่อสื่อสาร เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">6) ข้อมูลอ่อนไหว</span> เช่น
              ข้อมูลอ่อนไหวตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ หรือชาติพันธุ์) เป็นต้น
            </li>
          </ul>
          <li className="privacy-policy-text-emphasis">
            1.12. <span className="privacy-policy-subsection-title">Smart City/ศูนย์ศิลปะและวัฒนธรรม</span>
          </li>
          <ul>
            <li>
              <span className="privacy-policy-text-emphasis">1) รายละเอียดส่วนบุคคล</span> เช่น คำนำหน้าชื่อ ชื่อ นามสกุล เพศ อายุ
              สัญชาติ วันเกิด ชื่อตำแหน่ง ตำแหน่ง สถานที่ทำงาน ประวัติการทำงาน ข้อมูลในบัตรที่ทางราชการออกให้
              ตลอดจนวันที่ออกบัตรและวันที่บัตรหมดอายุ (เช่น หมายเลขบัตรประจำตัวประชาชน เลขที่หนังสือเดินทาง) ภาพถ่าย วิดีโอ
              หมายเลขทะเบียนรถ เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">2) รายละเอียดสำหรับติดต่อ</span> เช่น ที่อยู่ทางไปรษณีย์
              หมายเลขโทรศัพท์ ข้อมูลตำแหน่งที่อยู่ อีเมล์ LINE ID WhatsApp ID WeChat ID เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">3) รายละเอียดทางการเงิน</span> เช่น รายได้ เงินเดือน
              รายละเอียดของบัญชีธนาคาร (เช่น เลขที่บัญชี ชื่อธนาคาร สาขา ประเภทบัญชี) และข้อมูลอื่น ๆ เกี่ยวกับการเงิน เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">4) ข้อมูลอ่อนไหว</span> เช่น ข้อมูลการจำลองใบหน้า เป็นต้น
            </li>
          </ul>
          <li className="privacy-policy-text-emphasis">
            1.13. <span className="privacy-policy-subsection-title">ที่ปรึกษาด้านเทคโนโลยีสารสนเทศ</span>
          </li>
          <ul>
            <li>
              <span className="privacy-policy-text-emphasis">1) รายละเอียดส่วนบุคคล</span> เช่น คำนำหน้าชื่อ ชื่อ นามสกุล
              สถานที่ทำงาน ตำแหน่งงาน และข้อมูลอื่น ๆ ในนามบัตร เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">2) รายละเอียดสำหรับติดต่อ</span> เช่น หมายเลขโทรศัพท์และอีเมล์
              เป็นต้น
            </li>
          </ul>
          <li className="privacy-policy-text-emphasis">
            1.14. <span className="privacy-policy-subsection-title">บริการเกตเวย์ (Gateway)</span>
          </li>
          <ul>
            <li>
              <span className="privacy-policy-text-emphasis">1) รายละเอียดส่วนบุคคล</span> เช่น คำนำหน้าชื่อ ชื่อ นามสกุล
              และรายละเอียดอื่น ๆ เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">2) รายละเอียดสำหรับติดต่อ</span> เช่น หมายเลขโทรศัพท์และอีเมล์
              เป็นต้น
            </li>
          </ul>
          <li className="privacy-policy-text-emphasis">
            1.15. <span className="privacy-policy-subsection-title">บริการเทคโนโลยี Leap Solutions Asia (LSA)</span>
          </li>
          <ul>
            <li>
              <span className="privacy-policy-text-emphasis">1) รายละเอียดส่วนบุคคล</span> เช่น คำนำหน้าชื่อ ชื่อ นามสกุล
              สถานที่ทำงาน ตำแหน่งงาน ข้อมูลตำแหน่งที่อยู่ และข้อมูลอื่น ๆ ในนามบัตร เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">2) รายละเอียดสำหรับติดต่อ</span> เช่น หมายเลขโทรศัพท์และอีเมล์
              เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">3) ข้อมูลอ่อนไหว</span> เช่น
              ข้อมูลส่วนบุคคลที่ละเอียดอ่อนตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ ชาติพันธุ์) ข้อมูลชีวภาพ เป็นต้น
            </li>
          </ul>
          <li className="privacy-policy-text-emphasis">
            1.16. <span className="privacy-policy-subsection-title">Shinasup (SNS)</span>
          </li>
          <ul>
            <li>
              <span className="privacy-policy-text-emphasis">1) รายละเอียดส่วนบุคคล</span> เช่น คำนำหน้าชื่อ ชื่อ นามสกุล
              สถานที่ทำงาน ตำแหน่งงาน ข้อมูลในบัตรที่ทางราชการออกให้ (เช่น หมายเลขบัตรประจำตัวประชาชน เลขที่หนังสือเดินทาง)
              และข้อมูลอื่น ๆ ในนามบัตร เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">2) รายละเอียดสำหรับติดต่อ</span> เช่น หมายเลขโทรศัพท์และอีเมล์
              เป็นต้น
            </li>
            <li>
              <span className="privacy-policy-text-emphasis">3) ข้อมูลส่วนบุคคลที่ละเอียดอ่อน</span> เช่น
              ข้อมูลส่วนบุคคลที่ละเอียดอ่อนตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ ชาติพันธุ์) เป็นต้น
            </li>
          </ul>
        </ol>
        <p>
          ในบางกรณี บริษัทฯ อาจเก็บรวบรวมข้อมูลอื่น อาทิ คุกกี้ (Cookie) และพฤติกรรมการสืบค้นทางอินเทอร์เน็ต ข้อมูล
          การลงชื่อเข้าใช้ ประเภทของการสืบค้นประวัติการค้นหา ภาษาในการสืบค้น เลขที่อยู่ไอพี (IP Address) ข้อมูลเกี่ยวกับ
          วิธีที่ท่านใช้และมีปฏิสัมพันธ์กับโฆษณาหรือบริการออนไลน์ของบริษัทฯ (รวมถึงหน้าเว็บไซต์ที่เข้าชม เนื้อหาที่เข้าชม
          ลิงก์ที่คลิก หรือฟีเจอร์ที่ใช้) เวลาและความถี่ที่ท่านใช้บริการออนไลน์ของบริษัทฯ
          หน้าเว็บไซต์ต้นทางที่ท่านคลิกลิงก์เชื่อมโยงมายังบริการออนไลน์ของบริษัทฯ (เช่น ที่อยู่เว็บไซต์อ้างอิง (Referrer URL))
          และรายงานเว็บไซต์ล่ม เป็นต้น ทั้งนี้ ในกรณีที่อาจมีการนำข้อมูลใดก็ตามผนวกเข้ากับข้อมูลส่วนบุคคลของท่าน
          หรือหากมีการนำข้อมูลอื่นมาใช้ ในการสร้างประวัติบุคคลแล้วนั้น บริษัทฯ
          จะจัดการข้อมูลอื่นดังกล่าวหรือข้อมูลที่มีการผสมผสานนั้นเฉกเช่น ข้อมูลส่วนบุคคล อย่างไรก็ดี
          ท่านสามารถปฏิเสธหรือลบคุกกี้ได้ตลอดเวลาด้วยการตั้งค่าความเป็นส่วนตัว ในเว็บเบราว์เซอร์ของท่าน
          โดยอาจเลือกปฏิเสธคุกกี้ส่วนใดส่วนหนึ่งหรือทั้งหมดก็ได้ หากท่านใช้เว็บไซต์โดยไม่ได้ลบ
          หรือปฏิเสธคุกกี้จะถือว่าท่านยินยอมให้บริษัทฯ ใช้และจัดเก็บคุกกี้ในอุปกรณ์ของท่าน
        </p>
        <p>
          หากท่านได้ให้ข้อมูลส่วนบุคคลของบุคคลที่สามแก่บริษัทฯ เช่น ชื่อ นามสกุล รายละเอียดที่อยู่
          หมายเลขโทรศัพท์สำหรับติดต่อในกรณีฉุกเฉิน และรายได้ของสมาชิกในครอบครัว เป็นต้น
          กรุณาแจ้งนโยบายความเป็นส่วนตัวนี้ให้บุคคลดังกล่าวรับทราบและ/หรือขอความยินยอมจากบุคคลนั้น (ในกรณีที่จำเป็น)
        </p>
        <p>
          บริษัทฯ จะเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลที่ละเอียดอ่อน
          เมื่อได้รับความยินยอมโดยชัดแจ้งจากท่านหรือตามที่กฎหมายกำหนดไว้เท่านั้น บริษัทฯ จะเก็บรวบรวมข้อมูลส่วนบุคคลของผู้เยาว์
          คนเสมือนไร้ความสามารถ และคนไร้ความสามารถเฉพาะเมื่อผู้ปกครองผู้อนุบาล หรือผู้พิทักษ์ แล้วแต่กรณี
          ได้ให้ความยินยอมแก่บริษัทฯ แล้วเท่านั้น ทั้งนี้ ในกรณีที่บริษัทฯ
          สามารถรับรู้ได้ถึงความบกพร่องทางความสามารถตามกฎหมายของเจ้าของข้อมูล บริษัทฯ
          จะไม่เก็บรวบรวมข้อมูลส่วนบุคคลจากลูกค้าที่มีอายุต่ำกว่า 20 ปี หากไม่ได้รับความยินยอมจากผู้ปกครอง
          (ในกรณีที่จำเป็นต้องขอความยินยอม) และจะไม่เก็บรวบรวมข้อมูลส่วนบุคคลจากคนเสมือนไร้ความสามารถหรือคนไร้ความสามารถ
          หากไม่ได้รับความยินยอมจากผู้อนุบาล หรือ ผู้พิทักษ์ตามกฎหมายของบุคคลนั้น หากพบว่าบริษัทฯ
          ได้จัดเก็บข้อมูลส่วนบุคคลจากผู้ที่มีอายุต่ำกว่า 20 ปีโดยไม่ได้รับการยินยอมจากผู้ปกครอง (ในกรณีที่จำเป็นต้องขอความยินยอม)
          หรือจากคนเสมือนไร้ความสามารถหรือคนไร้ความสามารถโดยไม่ได้รับการยินยอมจากผู้อนุบาลหรือผู้พิทักษ์ตามกฎหมาย โดยที่บริษัทฯ
          ไม่เจตนาแล้วนั้น บริษัทฯ จะดำเนินการลบข้อมูลส่วนบุคคลดังกล่าวทันที หรือจะประมวลผลข้อมูลส่วนบุคคลเฉพาะเมื่อบริษัทฯ
          สามารถอ้างอิงฐานกฎหมายอื่นที่นอกเหนือจากฐานความยินยอมได้เท่านั้น
        </p>
        <li className="privacy-policy-section-title">วัตถุประสงค์ในการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน</li>
        <p>
          บริษัทฯ อาจเก็บรวบรวม ใช้ เปิดเผย
          และ/หรือโอนข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่ละเอียดอ่อนของท่านไปยังต่างประเทศเพื่อวัตถุประสงค์ดังต่อไปนี้
        </p>
        <ol>
          <li className="privacy-policy-text-emphasis">2.1. วัตถุประสงค์ที่ต้องมีการให้ความยินยอม</li>
          <p>บริษัทฯ จะต้องได้รับความยินยอมจากท่านเพื่อวัตถุประสงค์ดังต่อไปนี้</p>
          <ol>
            <li className="privacy-policy-text-emphasis">
              2.1.1. <span className="privacy-policy-subsection-title">โรงแรม</span>
            </li>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) การตลาดและการสื่อสาร: </span>เพื่อจัดให้มีการสื่อสารทางการตลาด
                การขาย ข้อเสนอพิเศษ การส่งเสริม การขาย ประกาศ ข่าวสาร และข้อมูลเกี่ยวกับผลิตภัณฑ์และบริการจากกลุ่มเฟรเซอร์ส
                พร็อพเพอร์ตี้ ตลอดจนบริษัทในเครือและบริษัทย่อยของบริษัทฯ บุคคลภายนอก และ/หรือพันธมิตรทางธุรกิจ ในกรณีที่บริษัทฯ
                ไม่สามารถอาศัยฐานกฎหมายอื่นได้
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">2) บริการวิเคราะห์: </span>
                เพื่อให้บริการวิเคราะห์ข้อมูลจากข้อมูลส่วนบุคคลของท่าน ไม่ว่าจะเป็นการให้บริการภายในกลุ่ม เฟรเซอร์ส พร็อพเพอร์ตี้
                ตลอดจนบริษัทในเครือและบริษัทย่อยของบริษัทฯ หรือให้บริการดังกล่าวแก่บุคคลภายนอก และ/หรือ
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">3) ข้อมูลอ่อนไหว: </span>บริษัทฯ
                อาจใช้ข้อมูลอ่อนไหวของท่านเพื่อวัตถุประสงค์ดังนี้
              </li>
              <ul className="privacy-policy-styled-unordered-list">
                <li>
                  ข้อมูลอ่อนไหวตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ หรือชาติพันธุ์)
                  เพื่อวัตถุประสงค์ในการพิสูจน์และยืนยันตัวตน
                </li>
                <li>ข้อมูลสุขภาพเพื่อให้ความร่วมมือกับผู้ให้บริการทางการแพทย์</li>
              </ul>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.1.2. <span className="privacy-policy-subsection-title">อสังหาริมทรัพย์เพื่อการอยู่อาศัย</span>
            </li>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) การตลาดและการสื่อสาร: </span>
                เพื่อจัดให้มีการสื่อสารทางการตลาด การขาย ข้อเสนอพิเศษ การส่งเสริม การขาย ประกาศ ข่าวสาร
                และข้อมูลเกี่ยวกับผลิตภัณฑ์และบริการจากบริษัท เฟรเซอร์ส พร็อพเพอร์ตี้ โฮม
                ตลอดจนบริษัทในเครือและบริษัทย่อยของบริษัทฯ บุคคลภายนอก และ/หรือพันธมิตรทางธุรกิจ ในกรณีที่บริษัทฯ
                ไม่สามารถอาศัยฐานกฎหมายอื่นได้
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">2) บริการวิเคราะห์: </span>
                เพื่อให้บริการวิเคราะห์ข้อมูลจากข้อมูลส่วนบุคคลของท่าน ไม่ว่าจะเป็นการให้บริการภายในกลุ่ม เฟรเซอร์ส
                พร็อพเพอร์ตี้ตลอดจนบริษัทในเครือและบริษัทย่อยของบริษัทฯ หรือให้บริการดังกล่าวแก่บุคคลภายนอก และ/หรือ
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">3) ข้อมูลอ่อนไหว: </span> บริษัทฯ
                อาจใช้ข้อมูลอ่อนไหวของท่านเพื่อวัตถุประสงค์ดังนี้
              </li>
              <ul className="privacy-policy-styled-unordered-list">
                <li>
                  ข้อมูลส่วนอ่อนไหวตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ หรือชาติพันธุ์)
                  เพื่อวัตถุประสงค์ในการพิสูจน์และยืนยันตัวตน
                </li>
              </ul>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.1.3. <span className="privacy-policy-subsection-title">อสังหาริมทรัพย์เพื่อการพาณิชยกรรม</span>
            </li>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) การตลาดและการสื่อสาร: </span>เพื่อจัดให้มีการสื่อสารทางการตลาด
                การขาย ข้อเสนอพิเศษ การส่งเสริม การขาย ประกาศ ข่าวสาร และข้อมูลเกี่ยวกับผลิตภัณฑ์และบริการจาก กลุ่มบริษัท
                เฟรเซอร์ส พร็อพเพอร์ตี้ ตลอดจนบริษัทในเครือและบริษัทย่อยของบริษัทฯ บุคคลภายนอก และ/หรือพันธมิตรทางธุรกิจ
                ในกรณีที่บริษัทฯ ไม่สามารถอาศัยฐานกฎหมายอื่นได้
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">2) บริการวิเคราะห์: </span>
                เพื่อให้บริการวิเคราะห์ข้อมูลจากข้อมูลส่วนบุคคลของท่าน ไม่ว่าจะเป็นการให้บริการภายในกลุ่มเฟรเซอร์ส พร็อพเพอร์ตี้
                ตลอดจนบริษัทในเครือและบริษัทย่อยของบริษัทฯ หรือให้บริการดังกล่าวแก่บุคคลภายนอก และ/หรือ
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">3) ข้อมูลอ่อนไหว: </span>บริษัทฯ
                อาจใช้ข้อมูลอ่อนไหวของท่านเพื่อวัตถุประสงค์ดังนี้
              </li>
              <ul className="privacy-policy-styled-unordered-list">
                <li>
                  ข้อมูลส่วนบุคคลที่ละเอียดอ่อนตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ หรือชาติพันธุ์)
                  เพื่อวัตถุประสงค์ในการพิสูจน์และยืนยันตัวตน
                </li>
                <li>
                  ข้อมูลชีวภาพ (เช่น ลายนิ้วมือ ข้อมูลการจำลองใบหน้า หรือข้อมูลการสแกนม่านตา)
                  เพื่ออนุญาตให้เข้าอาคารสถานที่ของบริษัทฯ เพื่อวางระบบควบคุมการเข้าถึงลิฟต์ และ/หรือ
                </li>
              </ul>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.1.4. <span className="privacy-policy-subsection-title">ห้างสรรพสินค้า</span>
            </li>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) การตลาดและการสื่อสาร: </span>เพื่อจัดให้มีการสื่อสารทางการตลาด
                การขาย ข้อเสนอพิเศษ การส่งเสริม การขาย ประกาศ ข่าวสาร และข้อมูลเกี่ยวกับผลิตภัณฑ์และบริการจาก กลุ่มเฟรเซอร์ส
                พร็อพเพอร์ตี้ ตลอดจนบริษัทในเครือและบริษัทย่อยของบริษัทฯ บุคคลภายนอก และ/หรือพันธมิตรทางธุรกิจ ในกรณีที่บริษัทฯ
                ไม่สามารถอาศัยฐานกฎหมายอื่นได้
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">2) บริการวิเคราะห์: </span>
                เพื่อให้บริการวิเคราะห์ข้อมูลจากข้อมูลส่วนบุคคลของท่าน ไม่ว่าจะเป็นการให้บริการภายในกลุ่มบริษัทเฟรเซอร์ส
                พร็อพเพอร์ตี้ ตลอดจนบริษัทในเครือและบริษัทย่อยของบริษัทฯ หรือให้บริการดังกล่าวแก่บุคคลภายนอก
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">3) ข้อมูลอ่อนไหว: </span>บริษัทฯ
                อาจใช้ข้อมูลอ่อนไหวของท่านเพื่อวัตถุประสงค์ดังนี้
              </li>
              <ul className="privacy-policy-styled-unordered-list">
                <li>
                  ข้อมูลอ่อนไหวตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ หรือชาติพันธุ์)
                  เพื่อวัตถุประสงค์ในการพิสูจน์และยืนยันตัวตน
                </li>
                <li>
                  ข้อมูลชีวภาพ (เช่น ลายนิ้วมือ ข้อมูลการจำลองใบหน้า หรือข้อมูลการสแกนม่านตา)
                  เพื่อวัตถุประสงค์ในการเข้าอาคารสถานที่และวัตถุประสงค์ด้านความปลอดภัย
                </li>
                <li>ประวัติอาชญากรรม เพื่อวัตถุประสงค์ด้านความปลอดภัย</li>
              </ul>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.1.5. <span className="privacy-policy-subsection-title">ศูนย์ประชุม</span>
            </li>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) การตลาดและการสื่อสาร: </span>เพื่อจัดให้มีการสื่อสารทางการตลาด
                การขาย ข้อเสนอพิเศษ การส่งเสริม การขาย ประกาศ ข่าวสาร และข้อมูลเกี่ยวกับผลิตภัณฑ์และบริการจาก กลุ่มเฟรเซอร์ส
                พร็อพเพอร์ตี้ตลอดจนบริษัทในเครือและบริษัทย่อยของบริษัทฯ บุคคลภายนอก และ/หรือพันธมิตรทางธุรกิจ ในกรณีที่บริษัทฯ
                ไม่สามารถอาศัยฐานกฎหมายอื่นได้
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">2) บริการวิเคราะห์: </span>
                เพื่อให้บริการวิเคราะห์ข้อมูลจากข้อมูลส่วนบุคคลของท่านแก่บุคคลภายนอก และ/หรือ
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">3) ข้อมูลอ่อนไหว: </span>บริษัทฯ
                อาจใช้ข้อมูลอ่อนไหวของท่านเพื่อวัตถุประสงค์ดังนี้
              </li>
              <ul className="privacy-policy-styled-unordered-list">
                <li>
                  ข้อมูลอ่อนไหวตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ หรือชาติพันธุ์)
                  เพื่อวัตถุประสงค์ในการพิสูจน์และยืนยันตัวตน
                </li>
                <li>ข้อมูลเกี่ยวกับศาสนา เพื่อการจัดเตรียมกิจกรรมทางศาสนา</li>
                <li>
                  ข้อมูลชีวภาพ (เช่น ลายนิ้วมือ ข้อมูลการจำลองใบหน้า หรือข้อมูลการสแกนม่านตา)
                  เพื่อวัตถุประสงค์ในการเข้าอาคารสถานที่และวัตถุประสงค์ด้านความปลอดภัย ข้อมูลสุขภาพ
                  เพื่อการจัดเตรียมอาหารและเครื่องดื่ม
                </li>
              </ul>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.1.6. <span className="privacy-policy-subsection-title">ดาต้าเซ็นเตอร์</span>
            </li>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) ข้อมูลอ่อนไหว: </span>
                เราอาจใช้ข้อมูลอ่อนไหวของท่านเพื่อวัตถุประสงค์ดังนี้
              </li>
              <ul className="privacy-policy-styled-unordered-list">
                <li>
                  ข้อมูลอ่อนไหวตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ ชาติพันธุ์)
                  เพื่อวัตถุประสงค์ในการพิสูจน์และยืนยันตัวตน
                </li>
                <li>
                  ข้อมูลชีวภาพ (เช่น ลายนิ้วมือ ข้อมูลการจำลองใบหน้า ข้อมูลการสแกนม่านตา)
                  เพื่อวัตถุประสงค์ในการเข้าอาคารสถานที่และวัตถุประสงค์ด้านความปลอดภัย
                </li>
                <li>ประวัติอาชญากรรม เพื่อวัตถุประสงค์ด้านความปลอดภัย</li>
              </ul>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.1.7. <span className="privacy-policy-subsection-title">คลังสินค้าและโรงงาน</span>
            </li>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) การตลาดและการสื่อสาร: </span>เพื่อจัดการสื่อสารทางการตลาด การขาย
                ข้อเสนอพิเศษ การส่งเสริมการขาย ประกาศ และข้อมูลข่าวสารเกี่ยวกับผลิตภัณฑ์และบริการจาก กลุ่มเฟรเซอร์ส พร็อพเพอร์ตี้
                ตลอดจนบริษัทในเครือและบริษัทย่อยของเรา บุคคลภายนอก
                และ/หรือพันธมิตรทางธุรกิจในกรณีที่เราไม่สามารถอาศัยฐานกฎหมายอื่นได้
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">2) ข้อมูลอ่อนไหว: </span>
                เราอาจใช้ข้อมูลอ่อนไหวของท่านเพื่อวัตถุประสงค์ดังนี้
              </li>
              <ul className="privacy-policy-styled-unordered-list">
                <li>
                  ข้อมูลอ่อนไหวตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ ชาติพันธุ์)
                  เพื่อวัตถุประสงค์ในการพิสูจน์และยืนยันตัวตน
                </li>
              </ul>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.1.8. <span className="privacy-policy-subsection-title">โคเวิร์คกิ้งสเปซ (Co-working)</span>
            </li>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) การตลาดและการสื่อสาร: </span>เพื่อจัดให้มีการสื่อสารทางการตลาด
                การขาย ข้อเสนอพิเศษ การส่งเสริม การขาย ประกาศ และข้อมูลข่าวสารเกี่ยวกับผลิตภัณฑ์และบริการจาก กลุ่มเฟรเซอร์ส
                พร็อพเพอร์ตี้ ตลอดจนบริษัทในเครือและบริษัทย่อยของบริษัทฯ บุคคลภายนอก และ/หรือพันธมิตรทางธุรกิจ ในกรณีที่บริษัทฯ
                ไม่สามารถอาศัยฐานกฎหมายอื่นได้
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">2) ข้อมูลอ่อนไหว: </span>บริษัทฯ
                อาจใช้ข้อมูลอ่อนไหวของท่านเพื่อวัตถุประสงค์ดังนี้
              </li>
              <ul className="privacy-policy-styled-unordered-list">
                <li>
                  ข้อมูลอ่อนไหวตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ ชาติพันธุ์)
                  เพื่อวัตถุประสงค์ในการพิสูจน์และยืนยันตัวตน
                </li>
              </ul>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.1.9. <span className="privacy-policy-subsection-title">ปัญญาประดิษฐ์ (AI)</span>
            </li>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) ข้อมูลอ่อนไหว: </span>
                เราอาจใช้ข้อมูลอ่อนไหวของท่านเพื่อวัตถุประสงค์ดังนี้
              </li>
              <ul className="privacy-policy-styled-unordered-list">
                <li>ข้อมูลการจำลองใบหน้า: เพื่อวัตถุประสงค์ในการพิสูจน์และยืนยันตัวตน และเพื่อวัตถุประสงค์ด้านความปลอดภัย</li>
              </ul>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.1.10. <span className="privacy-policy-subsection-title">การค้าส่ง</span>
            </li>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) การตลาดและการสื่อสาร: </span>เพื่อจัดการสื่อสารทางการตลาด การขาย
                ข้อเสนอพิเศษ การส่งเสริมการขาย ประกาศ และข้อมูลข่าวสารเกี่ยวกับผลิตภัณฑ์และบริการจากกลุ่ม เฟรเซอร์ส พร็อพเพอร์ตี้
                ตลอดจนบริษัทในเครือและบริษัทย่อยของเรา บุคคลภายนอก
                และ/หรือพันธมิตรทางธุรกิจในกรณีที่เราไม่สามารถอาศัยฐานกฎหมายอื่นได้
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">2) บริการวิเคราะห์: </span>
                เพื่อให้บริการวิเคราะห์ข้อมูลจากข้อมูลส่วนบุคคลของท่านแก่บุคคลภายนอก และ/หรือ
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">3) ข้อมูลอ่อนไหว: </span>
                เราอาจใช้ข้อมูลอ่อนไหวของท่านเพื่อวัตถุประสงค์ดังนี้
              </li>
              <ul className="privacy-policy-styled-unordered-list">
                <li>
                  ข้อมูลอ่อนไหวตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ ชาติพันธุ์)
                  เพื่อวัตถุประสงค์ในการพิสูจน์และยืนยันตัวตน
                </li>
                <li>
                  ข้อมูลชีวภาพ (เช่น ลายนิ้วมือ ข้อมูลการจำลองใบหน้า ข้อมูลการสแกนม่านตา)
                  เพื่อวัตถุประสงค์ในการเข้าอาคารสถานที่และวัตถุประสงค์ด้านความปลอดภัย
                </li>
                <li>ประวัติอาชญากรรม เพื่อวัตถุประสงค์ด้านความปลอดภัย</li>
              </ul>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.1.11. <span className="privacy-policy-subsection-title">การค้าปลีก</span>
            </li>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) การตลาดและการสื่อสาร: </span>เพื่อจัดให้มีการสื่อสารทางการตลาด
                การขาย ข้อเสนอพิเศษ การส่งเสริม การขาย ประกาศ และข้อมูลข่าวสารเกี่ยวกับผลิตภัณฑ์และบริการจาก กลุ่มเฟรเซอร์ส
                พร็อพเพอร์ตี้ ตลอดจนบริษัทในเครือและบริษัทย่อยของบริษัทฯ บุคคลภายนอก และ/หรือพันธมิตรทางธุรกิจ ในกรณีที่บริษัทฯ
                ไม่สามารถอาศัยฐานกฎหมายอื่นได้
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">2) บริการวิเคราะห์: </span>
                เพื่อให้บริการวิเคราะห์ข้อมูลจากข้อมูลส่วนบุคคลของท่าน ไม่ว่าจะเป็นการให้บริการภายในกลุ่มเฟอร์เซอร์ส พร็อพเพอร์ตี้
                ตลอดจนบริษัทในเครือและบริษัทย่อยของบริษัทฯ หรือให้บริการดังกล่าวแก่บุคคลภายนอก และ/หรือ
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">3) ข้อมูลอ่อนไหว: </span>บริษัทฯ
                อาจใช้ข้อมูลอ่อนไหวของท่านเพื่อวัตถุประสงค์ดังนี้
              </li>
              <ul className="privacy-policy-styled-unordered-list">
                <li>
                  ข้อมูลอ่อนไหวตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ หรือชาติพันธุ์)
                  เพื่อวัตถุประสงค์ในการพิสูจน์และยืนยันตัวตน
                </li>
              </ul>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.1.12. <span className="privacy-policy-subsection-title">Smart City/ศูนย์ศิลปะและวัฒนธรรม</span>
            </li>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) การตลาดและการสื่อสาร: </span>เพื่อจัดการสื่อสารทางการตลาด การขาย
                ข้อเสนอพิเศษ การส่งเสริมการขาย ประกาศ และข้อมูลข่าวสารเกี่ยวกับผลิตภัณฑ์และบริการจาก กลุ่มเฟรเซอร์ส พร็อพเพอร์ตี้
                ตลอดจนบริษัทในเครือและบริษัทย่อยของเรา บุคคลภายนอก
                และ/หรือพันธมิตรทางธุรกิจในกรณีที่เราไม่สามารถอาศัยฐานกฎหมายอื่นได้
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">2) บริการวิเคราะห์: </span>
                เพื่อให้บริการวิเคราะห์ข้อมูลจากข้อมูลส่วนบุคคลของท่านแก่บุคคลภายนอก และ/หรือ
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">3) ข้อมูลอ่อนไหว: </span>
                เราอาจใช้ข้อมูลอ่อนไหวของท่านเพื่อวัตถุประสงค์ดังนี้
              </li>
              <ul className="privacy-policy-styled-unordered-list">
                <li>การจดจำใบหน้า: เพื่อวัตถุประสงค์ในการตรวจสอบยืนยันตัวตนและความปลอดภัย</li>
              </ul>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.1.13. <span className="privacy-policy-subsection-title">บริการเทคโนโลยี Leap Solutions Asia (LSA)</span>
            </li>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) ข้อมูลอ่อนไหว: </span>
                เราอาจใช้ข้อมูลอ่อนไหวของท่านเพื่อวัตถุประสงค์ดังนี้
              </li>
              <ul className="privacy-policy-styled-unordered-list">
                <li>
                  ข้อมูลอ่อนไหวตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ ชาติพันธุ์)
                  เพื่อวัตถุประสงค์ในการตรวจสอบและยืนยันตัวตน
                </li>
              </ul>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.1.14. <span className="privacy-policy-subsection-title">Shinasub (SNS)</span>
            </li>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) ข้อมูลอ่อนไหว: </span>
                เราอาจใช้ข้อมูลอ่อนไหวของท่านเพื่อวัตถุประสงค์ดังนี้
              </li>
              <ul className="privacy-policy-styled-unordered-list">
                <li>
                  ข้อมูลอ่อนไหวตามที่ปรากฏในเอกสารระบุตัวตน (เช่น ศาสนา เชื้อชาติ ชาติพันธุ์)
                  เพื่อวัตถุประสงค์ในการตรวจสอบและยืนยันตัวตน
                </li>
              </ul>
            </ul>
          </ol>
          <p>
            ในกรณีที่บริษัทฯ อาศัยความยินยอมเป็นฐานทางกฎหมาย ท่านมีสิทธิในการถอนความยินยอมได้ตลอดเวลา โดยสามารถติดต่อบริษัทฯ
            ได้ตามที่ระบุไว้ท้ายนโยบายคามเป็นส่วนตัวนี้ เพื่อถอนความยินยอมดังกล่าว การถอนความยินยอมจะ
            ไม่กระทบต่อความชอบธรรมในการเก็บรวบรวม ใช้
            และเปิดเผยข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่ละเอียดอ่อนจากการยินยอมของท่านที่มีอยู่ก่อนการเพิกถอนแต่อย่างใด
          </p>
          <li className="privacy-policy-text-emphasis">
            2.2. วัตถุประสงค์และฐานทางกฎหมายที่บริษัทฯ อาจอาศัยในการประมวลผลข้อมูลส่วนบุคคลของท่าน
          </li>
          <p>
            บริษัทฯ อาจอาศัย (1) ฐานการปฏิบัติตามสัญญาในการเข้าทำหรือปฏิบัติตามสัญญากับท่าน (2)
            ฐานการปฏิบัติตามกฎหมายเพื่อปฏิบัติหน้าที่ตามกฎหมาย (3) ฐานประโยชน์โดยชอบด้วยกฎหมาย
            เพื่อประโยชน์โดยชอบด้วยกฎหมายทั้งของบริษัทฯ และบุคคลภายนอก (4) ฐานประโยชน์ต่อชีวิต
            เพื่อป้องกันและยับยั้งอันตรายต่อชีวิต ร่างกาย หรือสุขภาพ และ/หรือ (5) ฐานประโยชน์สาธารณะ
            เพื่อการปฏิบัติภารกิจเพื่อประโยชน์สาธารณะ หรือเพื่อการใช้อำนาจรัฐ
          </p>
          <ol>
            <li className="privacy-policy-text-emphasis">
              2.2.1. <span className="privacy-policy-subsection-title">โรงแรม</span>
            </li>
            <p>บริษัทฯ อาจเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ดังต่อไปนี้</p>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) เพื่อจัดหาผลิตภัณฑ์และบริการให้แก่ท่าน: </span>
                เพื่อเข้าทำสัญญาและจัดการความสัมพันธ์ตามสัญญาที่มีกับท่าน เพื่อจัดทำรายละเอียดสัญญา คำสั่งกำหนดราคา
                ธุรกรรมทางการเงิน และบริการต่างๆ เกี่ยวกับการชำระเงิน ซึ่งรวมถึงการตรวจสอบรายการธุรกรรม
                การตรวจสอบยืนยันความถูกต้อง และการยกเลิก เพื่อให้การสนับสนุนและ จัดกิจกรรมอื่นๆ
                ที่เกี่ยวกับบริการหรือผลิตภัณฑ์ดังกล่าว เพื่อประมวลผลการจอง การคืนเงิน
                และการแลกเปลี่ยนผลิตภัณฑ์หรือบริการให้แก่ท่าน เพื่อบันทึกประวัติสำหรับการจองครั้งต่อไป
                เพื่อออกใบเสร็จรับเงินและใบแจ้งราคาเพื่อเชิญท่านเข้าร่วมงาน และบริการต่างๆ ของบริษัทฯ และเพื่อจัดเตรียมงาน สถานที่
                และบริการอื่นๆ ให้แก่ท่าน
              </li>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.2.2. <span className="privacy-policy-subsection-title">อสังหาริมทรัพย์เพื่อการอยู่อาศัย</span>
            </li>
            <p>บริษัทฯ อาจเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ดังนี้</p>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) เพื่อจัดหาผลิตภัณฑ์และบริการให้แก่ท่าน: </span>
                เพื่อเข้าทำสัญญาและจัดการความสัมพันธ์ตามสัญญาที่มีกับท่าน เพื่อจัดทำรายละเอียดสัญญา คำสั่งกำหนดราคา ใบเสนอราคา
                เงื่อนไขการชำระเงิน ธุรกรรมทางการเงิน และบริการต่างๆ เกี่ยวกับการชำระเงิน ซึ่งรวมถึงการตรวจสอบรายการธุรกรรม
                การตรวจสอบยืนยันความถูกต้อง และการยกเลิก เพื่อนัดหมายเยี่ยมชมสำนักงานขาย
                เพื่อแจ้งให้ท่านทราบเกี่ยวกับระยะเวลาผ่อนชำระ เพื่อจัดเตรียมคำขอจองซื้อผลิตภัณฑ์ของบริษัทฯ ให้แก่ท่าน เช่น
                บ้านหรือสิทธิการเช่าห้องพักอาศัยระยะยาว เพื่อออกใบเสร็จรับเงินและใบแจ้งราคา เพื่อเก็บบันทึกหลักฐาน
                เพื่อพิจารณาสินเชื่อที่อยู่อาศัย สัญญาเช่า และสัญญาอื่นๆ ที่เกี่ยวกับผลิตภัณฑ์และ/หรือบริการของบริษัทฯ
                เพื่อแจ้งให้ท่านทราบเกี่ยวกับข่าวสารและความคืบหน้าในบริการหลังการขาย เช่น บริการดูแลบ้าน/ที่อยู่อาศัย
                เพื่อเชิญท่านเข้าร่วมงานต่างๆ และบริการของบริษัทฯ และเพื่อจัดเตรียมงาน สถานที่ และบริการอื่นๆ ให้แก่ท่าน
              </li>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.2.3. <span className="privacy-policy-subsection-title">อสังหาริมทรัพย์เพื่อการพาณิชยกรรม</span>
            </li>
            <p>บริษัทฯ อาจเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ดังต่อไปนี้</p>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) เพื่อจัดหาผลิตภัณฑ์และบริการให้แก่ท่าน: </span>
                เพื่อเข้าทำสัญญาและจัดการความสัมพันธ์ตามสัญญาที่มี กับท่าน เพื่อจัดทำรายละเอียดสัญญา ธุรกรรมทางการเงิน
                และบริการต่างๆ เกี่ยวกับการชำระเงิน ซึ่งรวมถึงการตรวจสอบรายการธุรกรรม การตรวจสอบยืนยันความถูกต้อง และการยกเลิก
                เพื่อนัดหมายเยี่ยมชมสำนักงานขาย เพื่อออกใบเสร็จรับเงินและใบแจ้งราคา การเบิกจ่ายเงินสด เงินค้างชำระ
                และหลักฐานการซื้อ เพื่อเก็บบันทึกหลักฐาน เพื่อบริหารการใช้บัตรเข้าสถานที่ เพื่อเชิญท่านเข้าร่วมงานต่างๆ
                และบริการของบริษัทฯ และเพื่อจัดเตรียมงาน สถานที่ และบริการอื่นๆ ให้แก่ท่าน
              </li>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.2.4. <span className="privacy-policy-subsection-title">ห้างสรรพสินค้า</span>
            </li>
            <p>บริษัทฯ อาจเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ดังต่อไปนี้</p>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) เพื่อจัดหาผลิตภัณฑ์และบริการให้แก่ท่าน: </span>
                เพื่อเข้าทำสัญญาและจัดการความสัมพันธ์ตามสัญญาที่มี กับท่าน เพื่อจัดทำรายละเอียดสัญญา ธุรกรรมทางการเงิน
                และบริการต่างๆ เกี่ยวกับการชำระเงิน ซึ่งรวมถึงการตรวจสอบรายการธุรกรรม การตรวจสอบยืนยันความถูกต้อง และการยกเลิก
                เพื่อออกใบเสร็จรับเงินและใบแจ้งราคา การเบิกจ่าย เงินสด เงินค้างชำระ และหลักฐานแสดงการซื้อ เพื่อเก็บบันทึกหลักฐาน
                เพื่อดำเนินโครงการแลกของรางวัล เพื่อเชิญท่านเข้าร่วมงานต่าง ๆ และบริการของบริษัทฯ และเพื่อจัดเตรียมงาน สถานที่
                และบริการอื่นๆ ให้แก่ท่าน
              </li>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.2.5. <span className="privacy-policy-subsection-title">ศูนย์ประชุม</span>
            </li>
            <p>บริษัทฯ อาจเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ดังต่อไปนี้</p>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) เพื่อจัดหาผลิตภัณฑ์และบริการให้แก่ท่าน: </span>
                เพื่อเข้าทำสัญญาและจัดการความสัมพันธ์ตามสัญญาที่มี กับท่าน เพื่อจัดทำรายละเอียดสัญญา ธุรกรรมทางการเงิน
                และบริการต่างๆ เกี่ยวกับการชำระเงิน ซึ่งรวมถึงการตรวจสอบรายการธุรกรรม การตรวจสอบยืนยันความถูกต้อง และการยกเลิก
                เพื่อออกใบเสร็จรับเงิน ใบแจ้งราคา และหลักฐานการซื้อ เพื่อเชิญท่านเข้าร่วมงานต่างๆ และบริการของบริษัทฯ
                เพื่อจัดเตรียมงาน สถานที่ และบริการอื่นๆ ให้แก่ท่าน และเพื่อเก็บบันทึกเป็นหลักฐาน
              </li>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.2.6. <span className="privacy-policy-subsection-title">ดาต้าเซ็นเตอร์</span>
            </li>
            <p>เราอาจเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ดังนี้</p>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) เพื่อส่งมอบผลิตภัณฑ์และบริการให้แก่ท่าน: </span>
                เพื่อเข้าทำสัญญาและจัดการความสัมพันธ์ตามสัญญาที่มีกับท่าน เพื่อจัดทำรายละเอียดสัญญา ธุรกรรมทางการเงิน
                และบริการต่าง ๆ เกี่ยวกับการชำระเงิน ซึ่งรวมถึงการตรวจสอบรายการธุรกรรม การตรวจยืนยันความถูกต้อง และการยกเลิก
                เพื่ออนุญาตให้เข้าถึงบริเวณสถานที่ของดาต้าเซ็นเตอร์ และเพื่อวัตถุประสงค์ด้านความปลอดภัย
              </li>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.2.7. <span className="privacy-policy-subsection-title">คลังสินค้าและโรงงาน</span>
            </li>
            <p>เราอาจเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ดังนี้</p>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) เพื่อส่งมอบผลิตภัณฑ์และบริการให้แก่ท่าน: </span>
                เพื่อเข้าทำสัญญาและจัดการความสัมพันธ์ตามสัญญาที่มีกับท่าน เพื่อจัดทำรายละเอียดสัญญา ธุรกรรมทางการเงิน
                และบริการต่าง ๆ เกี่ยวกับการชำระเงิน ซึ่งรวมถึงการตรวจสอบรายการธุรกรรม การตรวจยืนยันความถูกต้อง และการยกเลิก
                เพื่อออกใบเสร็จรับเงินและใบแจ้งราคา
              </li>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.2.8. <span className="privacy-policy-subsection-title">โคเวิร์คกิ้งสเปซ (Co-working)</span>
            </li>
            <p>บริษัทฯ อาจเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ดังต่อไปนี้</p>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) เพื่อจัดหาผลิตภัณฑ์และบริการให้แก่ท่าน: </span>
                เพื่อเข้าทำสัญญาและจัดการความสัมพันธ์ตามสัญญาที่มี กับท่าน เพื่อจัดทำรายละเอียดสัญญา ธุรกรรมทางการเงิน
                และบริการต่างๆ เกี่ยวกับการชำระเงิน ซึ่งรวมถึงการตรวจสอบรายการธุรกรรม การตรวจสอบยืนยันความถูกต้อง และการยกเลิก
                เพื่อบริหารการใช้บัตรเข้าสถานที่ เพื่อเก็บบันทึกหลักฐาน และเพื่อเชิญท่านเข้าร่วมงานต่างๆ และบริการของบริษัทฯ
                เพื่อจัดเตรียมงาน สถานที่ และบริการอื่นๆ ให้แก่ท่าน
              </li>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.2.9. <span className="privacy-policy-subsection-title">ปัญญาประดิษฐ์ (AI)</span>
            </li>
            <p>เราอาจเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ดังนี้</p>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) เพื่อส่งมอบผลิตภัณฑ์และบริการให้แก่ท่าน: </span>
                เพื่อเข้าทำสัญญาและจัดการความสัมพันธ์ตามสัญญาที่มีกับท่าน เพื่อจัดทำรายละเอียดสัญญา ธุรกรรมทางการเงิน
                และบริการต่าง ๆ เกี่ยวกับการชำระเงิน ซึ่งรวมถึงการตรวจสอบรายการธุรกรรม การตรวจยืนยันความถูกต้อง และการยกเลิก
              </li>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.2.10. <span className="privacy-policy-subsection-title">การค้าส่ง</span>
            </li>
            <p>เราอาจเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ดังนี้</p>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) เพื่อส่งมอบผลิตภัณฑ์และบริการให้แก่ท่าน: </span>
                เพื่อเข้าทำสัญญาและจัดการความสัมพันธ์ตามสัญญาที่มีกับท่าน เพื่อจัดทำรายละเอียดสัญญา ธุรกรรมทางการเงิน
                และบริการต่าง ๆ เกี่ยวกับการชำระเงิน ซึ่งรวมถึงการตรวจสอบรายการธุรกรรม การตรวจยืนยันความถูกต้อง และการยกเลิก
                เพื่อออกใบเสร็จรับเงินและใบแจ้งราคา การเบิกจ่ายเงินสด และเงินค้างชำระ
              </li>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.2.11. <span className="privacy-policy-subsection-title">การค้าปลีก</span>
            </li>
            <p>บริษัทฯ อาจเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ดังต่อไปนี้</p>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) เพื่อจัดหาผลิตภัณฑ์และบริการให้แก่ท่าน: </span>
                เพื่อเข้าทำสัญญาและจัดการความสัมพันธ์ตามสัญญาที่มีกับท่าน เพื่อจัดทำรายละเอียดสัญญา ธุรกรรมทางการเงิน
                และบริการต่าง ๆ เกี่ยวกับการชำระเงิน ซึ่งรวมถึงการตรวจสอบรายการธุรกรรม การตรวจสอบยืนยันความถูกต้อง และการยกเลิก
                เพื่อออกใบเสร็จรับเงินและใบแจ้งราคา การเบิกจ่าย เงินสด และเงินค้างชำระ เพื่อดำเนินการสมัครสมาชิกของท่าน
                เพื่อเก็บบันทึกหลักฐาน เพื่อเชิญท่านเข้าร่วมงานต่างๆ และบริการของบริษัทฯ และเพื่อจัดเตรียมงาน สถานที่
                และบริการอื่นๆ ให้แก่ท่าน
              </li>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.2.12. <span className="privacy-policy-subsection-title">Smart City/ศูนย์ศิลปะและวัฒนธรรม</span>
            </li>
            <p> เราอาจเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ดังนี้</p>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) เพื่อส่งมอบผลิตภัณฑ์และบริการให้แก่ท่าน: </span>
                เพื่อเข้าทำสัญญาและจัดการความสัมพันธ์ตามสัญญาที่มีกับท่าน เพื่อจัดทำรายละเอียดสัญญา ธุรกรรมทางการเงิน
                และบริการต่าง ๆ เกี่ยวกับการชำระเงิน ซึ่งรวมถึงการตรวจสอบรายการธุรกรรม การตรวจยืนยันความถูกต้อง
                และการยกเลิกเพื่ออนุญาตให้เข้าสถานที่ เพื่อดำเนินกลยุทธ์หลากหลายช่องทาง ลานจอดรถ บ้านอัจฉริยะ การบริหารพื้นที่
                การมีส่วนร่วมก่อนการเดินทาง (Pre-trip engagement) การบริหารสำนักงาน และโปรแกรมออนไลน์ และเพื่อให้บริการไวไฟ (WiFi)
              </li>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.2.13. <span className="privacy-policy-subsection-title">ที่ปรึกษาด้านเทคโนโลยีสารสนเทศ</span>
            </li>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) เพื่อส่งมอบผลิตภัณฑ์และบริการให้แก่ท่าน: </span>
                เพื่อเข้าทำสัญญาและจัดการความสัมพันธ์ตามสัญญาที่มีกับท่าน เพื่อจัดทำรายละเอียดสัญญา ธุรกรรมทางการเงิน
                และบริการต่าง ๆ เกี่ยวกับการชำระเงิน ซึ่งรวมถึงการตรวจสอบรายการธุรกรรม การตรวจยืนยันความถูกต้อง และการยกเลิก
              </li>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.2.14. <span className="privacy-policy-subsection-title">บริการเกตเวย์ (Gateway)</span>
            </li>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) เพื่อส่งมอบผลิตภัณฑ์และบริการให้แก่ท่าน: </span>
                เพื่อเข้าทำสัญญาและจัดการความสัมพันธ์ตามสัญญาที่มีกับท่าน เพื่อจัดทำรายละเอียดสัญญา ธุรกรรมทางการเงิน
                และบริการต่าง ๆ เกี่ยวกับการชำระเงิน ซึ่งรวมถึงการตรวจสอบรายการธุรกรรม การตรวจยืนยันความถูกต้อง และการยกเลิก
              </li>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.2.15. <span className="privacy-policy-subsection-title">บริการเทคโนโลยี Leap Solutions Asia (LSA)</span>
            </li>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) เพื่อส่งมอบผลิตภัณฑ์และบริการให้แก่ท่าน: </span>
                เพื่อเข้าทำสัญญาและจัดการความสัมพันธ์ตามสัญญาที่มีกับท่าน เพื่อจัดทำรายละเอียดสัญญา ธุรกรรมทางการเงิน
                และบริการต่าง ๆ เกี่ยวกับการชำระเงิน ซึ่งรวมถึงการตรวจสอบรายการธุรกรรม การตรวจยืนยันความถูกต้อง และการยกเลิก
              </li>
            </ul>
            <li className="privacy-policy-text-emphasis">
              2.2.16. <span className="privacy-policy-subsection-title">Shinasup (SNS)</span>
            </li>
            <ul>
              <li>
                <span className="privacy-policy-text-emphasis">1) เพื่อส่งมอบผลิตภัณฑ์และบริการให้แก่ท่าน </span>
                เพื่อเข้าทำสัญญาและจัดการความสัมพันธ์ตามสัญญาที่มีกับท่าน เพื่อจัดทำรายละเอียดสัญญา ธุรกรรมทางการเงิน
                และบริการต่าง ๆ เกี่ยวกับการชำระเงิน ซึ่งรวมถึงการตรวจสอบรายการธุรกรรม การตรวจยืนยันความถูกต้อง และการยกเลิก
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">2) การตลาดและการสื่อสาร </span>เพื่อดำเนินการด้านการตลาด การสื่อสาร
                ข้อเสนอพิเศษ สิทธิพิเศษ การส่งเสริมการขาย ประกาศ ข่าวสาร และข้อมูลกิจกรรมเกี่ยวกับผลิตภัณฑ์และบริการจาก
                กลุ่มเฟรเซอร์ส พร็อพเพอร์ตี้ ตลอดจนบริษัทในเครือและบริษัทย่อยของเรา และ/หรือพันธมิตรทางธุรกิจ
                ตามความต้องการที่ท่านแสดงไว้ไม่ว่าจะโดยตรงหรือโดยอ้อม
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">3) การจับสลากชิงรางวัล </span>การแข่งขัน
                และข้อเสนอ/กิจกรรมส่งเสริมการขายอื่น ๆ เพื่อให้ท่านสามารถเข้าร่วมกิจกรรมส่งเสริมการขาย ข้อเสนอพิเศษ การแข่งขัน
                การจับสลากชิงรางวัล สิทธิพิเศษในการใช้พื้นที่แต่เพียงผู้เดียว และข้อเสนอ/กิจกรรมส่งเสริมการขายอื่น ๆ
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">4) การลงทะเบียนและพิสูจน์ยืนยันตัวตน </span>เพื่อลงทะเบียน พิสูจน์
                ระบุตัวตน และยืนยันตัวท่านเองหรือตัวตนของท่าน
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">5) การสื่อสารและจัดการความสัมพันธ์กับท่าน</span>{' '}
                เพื่อสื่อสารกับท่านในเรื่องเกี่ยวกับผลิตภัณฑ์และบริการที่ท่านได้รับจากเรา
                และเพื่อรับข้อมูลเกี่ยวกับผลิตภัณฑ์และบริการจากท่าน เพื่อประมวลผลและปรับปรุงข้อมูลของท่านให้เป็นปัจจุบัน
                เพื่ออำนวยความสะดวกให้แก่ท่านในการใช้ผลิตภัณฑ์และบริการของเรา เพื่อจัดการคำถามเกี่ยวกับการบริการลูกค้า คำขอ
                ความคิดเห็น คำร้อง การเรียกร้องสิทธิในการรับประกัน ข้อพิพาทหรือการชดใช้ค่าเสียหาย
                การจัดการประเด็นทางเทคนิคและเงื่อนไขทางการค้า
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">6) การประมวลผลธุรกรรมหรือการชำระเงิน </span>เช่น
                เพื่อประมวลผลการชำระเงินหรือรายการธุรกรรม ตลอดจนการเรียกเก็บเงิน การประมวลผล การหักบัญชี การคืนเงิน
                การไกล่เกลี่ยประนีประนอม ซึ่งรวมถึงการจัดเก็บบันทึกเกี่ยวกับการเงิน รายการธุรกรรม และการชำระเงินทั้งหมด
                การออกใบแจ้งหนี้ ใบกำกับภาษี ใบเสร็จรับเงิน และการนำส่งรายการต่าง ๆ ที่กล่าวมา
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">7) การประมวลผลข้อมูลและการวิเคราะห์ข้อมูล </span>
                เพื่อวิเคราะห์ข้อมูลสำหรับการพัฒนาผลิตภัณฑ์และบริการ การวิจัยตลาด การสำรวจ การประเมิน และวิเคราะห์พฤติกรรม
                เพื่อวิเคราะห์ข้อมูลสำหรับการปรับปรุงผลประกอบการทางการตลาด ตลอดจนข้อเสนอและการจำหน่ายผลิตภัณฑ์และบริการของเรา
                เพื่อประเมินความสนใจของท่านจากการปรับปรุง เพื่อวิเคราะห์ข้อมูลสำหรับการปรับปรุงระบบ (เช่น
                พัฒนารูปแบบในการจำแนกกลุ่มลูกค้าเพื่อการให้บริการที่ดียิ่งขึ้น) เพื่อวิเคราะห์ข้อมูลสำหรับเพิ่มโอกาสในทางธุรกิจ
                เพื่อประเมิน พัฒนา จัดการ ปรับปรุง วิจัยและพัฒนาบริการ ผลิตภัณฑ์ ระบบ และการดำเนินธุรกิจ
                ให้แก่ท่านและลูกค้าทุกคนของเรา
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">8) การดำเนินการตามวัตถุประสงค์ในทางธุรกิจ </span>เช่น
                ปรับปรุงข้อมูลลูกค้าของท่านให้เป็นปัจจุบัน เก็บรักษาข้อมูลส่วนบุคคลให้ถูกต้อง
                วิเคราะห์ข้อมูลเพื่อป้องกันความเสี่ยง (เช่น พัฒนารูปแบบการทำนายความเป็นไปได้ของสินเชื่อที่ไม่ก่อให้เกิดรายได้
                หรือทำนายโอกาสที่จะเกิดอุบัติเหตุ) เพื่อประเมินความเสี่ยงลูกค้า การควบคุมความเสี่ยงระดับสถาบัน การตรวจสอบบัญชี
                และการรักษาบันทึกการตรวจสอบบัญชี การวิเคราะห์ความเสี่ยงด้านเครดิต เพื่อเก็บรักษาบันทึกทางธุรกิจ หรือการดำเนินการ
                จัดการ และบำรุงรักษาการดำเนินธุรกิจของเราด้วยประการอื่น เพื่อบำรุงรักษาระบบเทคโนโลยีสารสนเทศ การจัดการระบบสื่อสาร
                การรักษาความปลอดภัยด้านเทคโนโลยีสารสนเทศ และการตรวจสอบความปลอดภัยด้านเทคโนโลยีสารสนเทศ
                การรักษาการบริหารธุรกิจภายในองค์กรตามข้อกำหนด นโยบาย และกระบวนการกำกับดูแลภายในองค์กร
                และเพื่อจัดเก็บบันทึกความถี่ของการเยี่ยมชม
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">9) การปฏิบัติหน้าที่ตามข้อบังคับและตามการกำกับดูแล </span>
                เพื่อปฏิบัติหน้าที่ตามกฎหมาย กระบวนการทางกฎหมาย คำสั่งของหน่วยงานภาครัฐ
                ซึ่งอาจรวมถึงคำสั่งจากหน่วยงานภาครัฐนอกประเทศไทยด้วย และ/หรือ ให้ความร่วมมือกับศาล หน่วยงานกำกับดูแล
                หน่วยงานภาครัฐ และหน่วยงานบังคับใช้กฎหมาย เมื่อเรามีเหตุให้เชื่อได้ว่ากฎหมายกำหนดให้ต้องปฏิบัติตามดังกล่าว
                และเมื่อมีความจำเป็นอย่างยิ่งที่จะต้องเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อการปฏิบัติหน้าที่ตามกฎหมาย
                กระบวนการตามกฎหมาย หรือคำสั่งจากภาครัฐนั้น เพื่อรายงานภาษีและจัดการการรายงานภาษี เพื่อติดต่อกับเจ้าหน้าที่ด้านภาษี
                หน่วยงานกำกับดูแลการบริการทางการเงิน หน่วยงานภาครัฐและหน่วยงานกำกับดูแลอื่น ๆ ตลอดจนการตรวจสอบและป้องกันอาชญากรรม
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">10) การทำงานของเว็บไซต์และแพลตฟอร์มของเรา </span>เช่น
                เพื่อการบริหาร ดำเนินการ ติดตาม สังเกตการณ์ และจัดการเว็บไซต์และแพลตฟอร์มของเรา
                เพื่ออำนวยความสะดวกและเพื่อให้เว็บไซต์และแพลตฟอร์มดังกล่าวสามารถทำงานได้อย่างเหมาะสม มีประสิทธิภาพ และปลอดภัย
                เพื่ออำนวยความสะดวกให้แก่ท่านในการสัมผัสประสบการณ์บนเว็บไซต์และแพลตฟอร์มของเรา
                เพื่อปรับปรุงรูปแบบและเนื้อหาบนเว็บไซต์และแพลตฟอร์มของเรา เพื่อให้ท่านสามารถเข้าถึงระบบของเราที่มีอยู่
                และเพื่อให้ความช่วยเหลือทางเทคนิค
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">11) การคุ้มครองผลประโยชน์ของเรา </span>
                เพื่อรักษาความปลอดภัยและความชอบธรรมในการดำเนินธุรกิจของเรา เพื่อตรวจจับและป้องกันการประพฤติมิชอบในพื้นที่ของเรา
                เช่น การตรวจจับ ป้องกัน และตอบโต้ข้อกล่าวหาเรื่องการฉ้อโกง
                เพื่อกำหนดความเสี่ยงด้านการฉ้อโกงและระบุรายการธุรกรรมที่เป็นการฉ้อโกง ข้อร้องเรียนการละเมิดทรัพย์สินทางปัญญา
                หรือการฝ่าฝืนกฎหมาย เพื่อจัดการและป้องกันการสูญหายของสมบัติและทรัพย์สินของเรา เพื่อดำเนินการตรวจสอบรายชื่อต้องห้าม
                (Sanction List) การจัดการความเสี่ยง การบันทึกและการตรวจสอบภายใน การจัดการทรัพย์สิน ระบบ และการควบคุมอื่น ๆ
                ทางธุรกิจ เพื่อติดตามเหตุการณ์ เพื่อป้องกันและรายงานการกระทำผิดทางอาญา
                และเพื่อรักษาความปลอดภัยและความชอบธรรมในการดำเนินธุรกิจของเรา
                เพื่อเป็นหลักฐานอ้างอิงเกี่ยวกับข้อร้องเรียนหรือการดำเนินคดี
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">12) ธุรกรรมภาคธุรกิจ </span>ในกรณีที่มีการขาย โอน ควบรวมธุรกิจ
                ฟื้นฟูกิจการ หรือเหตุการณ์ที่คล้ายกัน เราอาจโอนข้อมูลส่วนบุคคลของท่านไปยังบุคคลภายนอกหนึ่งรายหรือมากกว่านั้น
                อันเป็นส่วนหนึ่งของการทำธุรกรรม
              </li>
              <li>
                <span className="privacy-policy-text-emphasis">13) ชีวิต </span>เพื่อป้องกันและยับยั้งอันตรายต่อชีวิต ร่างกาย
                หรือสุขภาพของบุคคล หากท่านไม่ได้ให้ข้อมูลส่วนบุคคลเมื่อได้รับการร้องขอจากบริษัทฯ บริษัทฯ
                อาจไม่สามารถจัดหาผลิตภัณฑ์หรือบริการ ให้แก่ท่านได้
              </li>
            </ul>
          </ol>
        </ol>
        <li className="privacy-policy-section-title">บริษัทฯ อาจเปิดเผยหรือโอนข้อมูลส่วนบุคคลของท่านให้ใครได้บ้าง</li>
        <p>
          บริษัทฯ อาจเปิดเผยหรือถ่ายโอนข้อมูลส่วนบุคคลของท่านให้บุคคลภายนอกดังต่อไปนี้ซึ่งจะทำการเก็บรวบรวม ใช้
          และเปิดเผยข้อมูลส่วนบุคคลตามวัตถุประสงค์ในนโยบายนี้ ทั้งนี้บุคคลภายนอกเหล่านี้อาจอยู่ในประเทศไทยหรืออยู่ต่างประเทศก็ได้
          ท่านสามารถเข้าดูนโยบายความเป็นส่วนตัวของบุคคลภายนอกดังกล่าว เพื่อจะได้ทราบเพิ่มเติมว่าบุคคลดังกล่าวเก็บรวบรวม ใช้
          และเปิดเผยข้อมูลส่วนบุคคลของท่านอย่างไร
          เนื่องจากการคุ้มครองข้อมูลส่วนบุคคลของท่านจะอยู่ภายใต้นโยบายความเป็นส่วนตัวของบุคคลภายนอกเหล่านั้นด้วย
        </p>
        <ol>
          <li className="privacy-policy-text-emphasis">3.1. ภาพรวมการจัดการข้อมูลของกลุ่มเฟรเซอร์ส พร็อพเพอร์ตี้ </li>
          <p>
            ในฐานะที่บริษัท เฟรเซอร์ส พร็อพเพอร์ตี้ (ประเทศไทย) จำกัด (มหาชน) เป็นบริษัทใน กลุ่มเฟรเซอร์ส พร็อพเพอร์ตี้
            ซึ่งทั้งหมดจะทำงานร่วมกันในการให้บริการแก่ลูกค้า และใช้ระบบบางส่วนร่วมกัน รวมถึงบริการและระบบที่เกี่ยวข้องกับเว็บไซต์
            บริษัทฯ จึงอาจจำเป็นต้องโอนข้อมูลส่วนบุคคลของท่านให้บริษัทอื่นในกลุ่มเฟรเซอร์ส พร็อพเพอร์ตี้ ทั้งนี้
            รวมถึงและไม่จำกัดเพียงแต่บริษัทที่ได้ลงนามภายใต้ Binding Corporate Rules (BCR) และบริษัทแม่
            ไม่ว่าโดยตรงหรือโดยอ้อมด้วย รวมทั้งอนุญาตให้บริษัทดังกล่าวเข้าถึงข้อมูลส่วนบุคคล
            เพื่อวัตถุประสงค์ที่กำหนดไว้ในนโยบายความเป็นส่วนตัวนี้ และจะทำให้บริษัทอื่นในกลุ่มเฟรเซอร์ส
            พร็อพเพอร์ตี้และบริษัทที่กำหนดไว้ภายใต้ BCR สามารถใช้ความยินยอมที่ บริษัท เฟรเซอร์ส พร็อพเพอร์ตี้ (ประเทศไทย) จำกัด
            (มหาชน) ได้รับมาจากท่านได้เช่นเดียวกัน
          </p>
          <li className="privacy-policy-text-emphasis">3.2. ผู้ให้บริการของบริษัทฯ</li>
          <p>
            บริษัทฯ อาจใช้บริษัท ตัวแทน หรือผู้รับจ้างอื่นเป็นผู้ให้บริการในนามของบริษัทฯ หรือช่วยบริษัทฯ
            ในการจัดหาผลิตภัณฑ์และบริการให้แก่ท่าน บริษัทฯ อาจแบ่งปันข้อมูลส่วนบุคคลของท่านให้แก่ผู้ให้บริการของบริษัทฯ หรือ
            ผู้จัดจำหน่ายสินค้า/บริการ ซึ่งเป็นบุคคลภายนอก รวมถึงแต่ไม่จำกัดเพียง (1) ผู้พัฒนาโปรแกรมคอมพิวเตอร์ ผู้พัฒนาซอฟต์แวร์
            ผู้ให้บริการด้านเทคโนโลยีสารสนเทศ และบริษัทให้บริการสนับสนุนด้านเทคโนโลยีสารสนเทศ (2) บริษัทด้านการตลาด
            บริษัทสื่อโฆษณา บริษัทออกแบบ บริษัทสร้างสรรค์งานโฆษณา และบริษัทด้านการสื่อสาร (3) บริษัทผู้จัดทำแคมเปญ งานกิจกรรม
            และการตลาดต่าง ๆ และบริษัทบริหารจัดการลูกค้าสัมพันธ์ (CRM) (4) ผู้ให้บริการจัดเก็บข้อมูล และผู้ให้บริการระบบคลาวด์ (5)
            ผู้ให้บริการการบริหารทรัพย์สิน (6) บริษัทตัวแทนขาย (7) บริษัทที่ให้บริการด้าน โลจิสติกส์และการขนส่ง (8)
            ผู้ให้บริการด้านการชำระเงินและระบบชำระเงิน (9) บริษัทที่ให้บริการด้านการวิจัย (10) ผู้ให้บริการด้านการวิเคราะห์ (11)
            บริษัททำการสำรวจ (12) ศูนย์บริการข้อมูลลูกค้า (Call Center) (13) ผู้ให้บริการด้านโทรคมนาคมและการสื่อสาร (14)
            ผู้ให้บริการด้านการดำเนินงานภายในซึ่งเป็นบุคคลภายนอก (15) ผู้ให้บริการด้านการพิมพ์ ในการให้บริการต่างๆ ดังกล่าว
            ผู้ให้บริการอาจเข้าถึงข้อมูลส่วนบุคคลของท่านได้ อย่างไรก็ตามบริษัทฯ จะให้ข้อมูลส่วนบุคคลแก่ผู้ให้บริการของบริษัทฯ
            เฉพาะข้อมูลส่วนบุคคลที่จำเป็นต่อการให้บริการเท่านั้น และบริษัทฯ
            ได้ขอให้ผู้ให้บริการดังกล่าวข้างต้นไม่ใช้ข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์อื่น บริษัทฯ
            จะกำกับดูแลให้ผู้ให้บริการที่ร่วมงานกับบริษัทฯ เก็บรักษาข้อมูลส่วนบุคคลของท่านไว้อย่างปลอดภัยตามที่กฎหมายกำหนด
          </p>
          <li className="privacy-policy-text-emphasis">3.3. พันธมิตรทางธุรกิจของบริษัทฯ</li>
          <p>
            บริษัทฯ อาจเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่บริษัทที่เป็นพันธมิตรของบริษัทฯ
            เพื่อจัดหาและยกระดับผลิตภัณฑ์และบริการให้แก่ลูกค้าและคนที่มีโอกาสจะมาเป็นลูกค้า ตัวอย่างของพันธมิตรทางธุรกิจดังกล่าว
            เช่น สถาบันการเงินที่เป็นพันธมิตร บริษัทจัดการระบบการเข้าถึง บริษัทโทรคมนาคม ผู้สนับสนุน พันธมิตรที่ใช้แบรนด์ร่วมกัน
            และบุคคลภายนอกรายอื่นที่บริษัทฯ ทำการตลาดหรือการส่งเสริมการขายร่วมด้วย
          </p>
          <li className="privacy-policy-text-emphasis">3.4. บุคคลภายนอกที่กฎหมายกำหนด</li>
          <p>
            ในบางกรณี บริษัทฯ อาจต้องเปิดเผยหรือแบ่งปันข้อมูลส่วนบุคคลของท่าน
            เพื่อให้เป็นไปตามหน้าที่ทางกฎหมายหรือระเบียบข้อบังคับ บุคคลภายนอกดังกล่าวรวมถึงหน่วยงานของรัฐ ศาล สถานทูต สถานกงสุล
            หน่วยงานกำกับดูแล เจ้าหน้าที่ของรัฐ หรือบุคคลภายนอกรายอื่นที่บริษัทฯ
            เห็นว่าจำเป็นในการปฏิบัติหน้าที่ตามกฎหมายหรือตามระเบียบข้อบังคับ หรือเพื่อคุ้มครองสิทธิของบริษัทฯ สิทธิของบุคคลภายนอก
            ความปลอดภัยส่วนตัวของบุคคล หรือเพื่อตรวจหา ป้องกัน หรือจัดการการฉ้อโกง หรือประเด็นด้านความมั่นคง ปลอดภัย
          </p>
          <li className="privacy-policy-text-emphasis">3.5. ที่ปรึกษาซึ่งเป็นผู้เชี่ยวชาญ</li>
          <p>
            ที่ปรึกษาซึ่งเป็นผู้เชี่ยวชาญประกอบไปด้วยทนายความ ผู้เชี่ยวชาญเฉพาะทาง และผู้สอบบัญชี
            ซึ่งให้ความช่วยเหลือในการดำเนินธุรกิจของบริษัทฯ รวมถึงการต่อสู้คดีหรือฟ้องร้องคดี
          </p>
          <li className="privacy-policy-text-emphasis">3.6. ผู้รับโอนสิทธิ และ/หรือภาระหน้าที่ </li>
          <p>
            ในกรณีที่มีการปรับโครงสร้างองค์กร ฟื้นฟูกิจการ ควบรวมกิจการ การโอนธุรกิจไม่ว่าบางส่วนหรือทั้งหมด การซื้อขาย
            กิจการร่วมค้า การโอนสิทธิ การโอนหรือจำหน่ายธุรกิจ ทรัพย์สิน หรือหุ้นบางส่วนหรือทั้งหมดของบริษัทฯ
            หรือธุรกรรมในลักษณะเดียวกัน บุคคลภายนอกในฐานะผู้รับโอนสิทธิของบริษัทฯ
            จะปฏิบัติตามนโยบายความเป็นส่วนตัวนี้เพื่อให้ความคุ้มครองต่อข้อมูลส่วนบุคคลของท่าน
          </p>
        </ol>
        <li className="privacy-policy-section-title">การโอนข้อมูลส่วนบุคคลระหว่างประเทศ</li>
        <p>
          บริษัทฯ อาจเปิดเผยหรือโอนข้อมูลส่วนบุคคลของท่านไปยังบุคคลภายนอกหรือเครื่องแม่ข่าย (เซิร์ฟเวอร์) ในต่างประเทศ
          โดยที่ประเทศดังกล่าวอาจมีหรือไม่มีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลในระดับเดียวกัน บริษัทฯ
          จะดำเนินการตามขั้นตอนและมาตรการต่าง ๆ เพื่อกำกับดูแลให้การโอนข้อมูลส่วนบุคคลของท่านเป็นไปอย่างปลอดภัย
          และผู้ได้รับข้อมูลส่วนบุคคลมีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่เหมาะสม หรือมีข้อยกเว้นอื่นตามที่กฎหมายอนุญาต บริษัทฯ
          จะขอความยินยอมจากท่านหากกฎหมายกำหนดให้ต้องให้ความยินยอมในกรณีมีการโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ
        </p>
        <li className="privacy-policy-section-title">ระยะเวลาในการเก็บข้อมูลส่วนบุคคลของท่าน</li>
        <p>
          บริษัทฯ
          จะเก็บรักษาข้อมูลส่วนบุคคลของท่านเป็นเวลาเท่าที่จำเป็นต้องใช้ข้อมูลส่วนบุคคลนั้นเพื่อทำตามวัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล
          เพื่อตอบสนองการดำเนินธุรกิจของบริษัทฯ และ/หรือเพื่อปฏิบัติหน้าที่ตามกฎหมายหรือตามกฎระเบียบ ระยะเวลาที่บริษัทฯ
          จะเก็บข้อมูลส่วนบุคคลของท่านจะขึ้นอยู่กับลักษณะของข้อมูลส่วนบุคคล
          ข้อมูลส่วนบุคคลบางอย่างอาจเก็บไว้นานกว่าข้อมูลส่วนบุคคลอื่นหากมีกฎหมายกำหนด
        </p>
        <li className="privacy-policy-section-title">สิทธิของท่านในฐานะเจ้าของข้อมูลส่วนบุคคล</li>
        <p>ภายใต้บังคับของกฎหมายที่เกี่ยวข้องและข้อยกเว้นของกฎหมายดังกล่าว ท่านอาจมีสิทธิดังต่อไปนี้</p>
        <ul>
          <li>
            <span className="privacy-policy-text-emphasis">1) สิทธิในการเข้าถึงข้อมูล: </span>
            ท่านอาจมีสิทธิในการเข้าถึงและขอสำเนาข้อมูลส่วนบุคคลที่บริษัทฯ กำลังเก็บรวบรวม ใช้ และเปิดเผยเกี่ยวกับตัวท่าน
            ทั้งนี้เพื่อความเป็นส่วนตัวและความปลอดภัยของท่าน บริษัทฯ
            อาจกำหนดให้ท่านพิสูจน์ตัวตนก่อนจะให้ข้อมูลส่วนบุคคลที่ขอมาแก่ท่าน
          </li>
          <li>
            <span className="privacy-policy-text-emphasis">2) สิทธิในการแก้ไขข้อมูล: </span>
            ท่านอาจมีสิทธิขอให้มีการแก้ไขข้อมูลส่วนบุคคลที่ไม่ถูกต้อง ไม่ครบถ้วน ทำให้เกิดการเข้าใจผิด
            หรือไม่ได้ปรับปรุงให้เป็นปัจจุบัน ซึ่งเป็นข้อมูลส่วนบุคคลของท่านที่บริษัทฯ เก็บรวบรวม ใช้ เปิดเผย และ/หรือโอน
            (ในประเทศหรือระหว่างประเทศ) ให้ถูกต้อง
          </li>
          <li>
            <span className="privacy-policy-text-emphasis">3) สิทธิในการโอนย้ายข้อมูล: </span>
            ท่านอาจมีสิทธิขอรับข้อมูลส่วนบุคคลที่บริษัทฯ
            มีอยู่เกี่ยวกับท่านในรูปแบบที่สามารถอ่านหรือใช้งานได้โดยทั่วไปด้วยเครื่องมือหรืออุปกรณ์อัตโนมัติ
            และส่งหรือถ่ายโอนข้อมูลส่วนบุคคลนั้นไปให้ผู้ควบคุมข้อมูลส่วนบุคคลรายอื่นในกรณีที่ (ก)
            เป็นข้อมูลส่วนบุคคลที่ท่านจัดส่งให้บริษัทฯ (ข) หากบริษัทฯ กำลังประมวลผลข้อมูลส่วนบุคคลตามความยินยอมของท่าน
            หรือเพื่อดำเนินการตามสัญญาของบริษัทฯ ที่ทำกับท่าน
          </li>
          <li>
            <span className="privacy-policy-text-emphasis">4) สิทธิในการคัดค้าน: </span> ท่านอาจมีสิทธิคัดค้านการเก็บรวบรวม การใช้
            และการเปิดเผยข้อมูลส่วนบุคคลของท่านบางประการ เช่น คัดค้านการทำการตลาดแบบขายตรง
          </li>
          <li>
            <span className="privacy-policy-text-emphasis">5) สิทธิในการระงับการใช้: </span>
            ท่านอาจมีสิทธิระงับการใช้ข้อมูลส่วนบุคคลของท่านในบางกรณี
          </li>
          <li>
            <span className="privacy-policy-text-emphasis">6) สิทธิในการถอนความยินยอม: </span>ท่านมีสิทธิถอนความยินยอมให้บริษัทฯ
            เก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่านที่ได้เคยให้ความยินยอมไว้ได้ตลอดเวลา
          </li>
          <li>
            <span className="privacy-policy-text-emphasis">7) สิทธิในการลบข้อมูลส่วนบุคคล: </span> ท่านมีสิทธิขอให้บริษัทฯ
            ลบข้อมูลส่วนบุคคล หรือทำให้ไม่สามารถระบุตัวตนในข้อมูลส่วนบุคคลที่บริษัทฯ เก็บรวบรวม ใช้ และเปิดเผยเกี่ยวกับท่าน
            อย่างไรก็ตามบริษัทฯ ไม่มีหน้าที่ต้องดำเนินการเช่นนั้นหากบริษัทฯ
            จำเป็นต้องเก็บรักษาข้อมูลส่วนบุคคลดังกล่าวตามภาระหน้าที่ตามกฎหมาย หรือเพื่อก่อตั้งสิทธิเรียกร้องตามกฎหมาย
            ใช้สิทธิเรียกร้องตามกฎหมาย หรือยกขึ้นต่อสู้สิทธิเรียกร้องตามกฎหมาย
          </li>
          <li>
            <span className="privacy-policy-text-emphasis">8) สิทธิในการร้องเรียน: </span>
            ท่านอาจมีสิทธิยื่นคำร้องต่อเจ้าหน้าที่ผู้มีอำนาจหากเห็นว่าการเก็บรวบรวม ใช้
            และเปิดเผยข้อมูลส่วนบุคคลของท่านไม่ชอบด้วยกฎหมาย หรือไม่เป็นไปตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลที่เกี่ยวข้อง
          </li>
        </ul>
        <li className="privacy-policy-section-title">เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</li>
        <p>
          บริษัทฯ ได้มีการดำเนินการปฏิบัติตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
          โดยแต่งตั้งเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data Protection Officer : DPO) เพื่อตรวจสอบการดำเนินการของบริษัทฯ
          ที่เกี่ยวกับการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลให้สอดคล้องกับพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
          และที่จะมีการแก้ไขเพิ่มเติม รวมถึงกฎหมายที่เกี่ยวข้องกับการคุ้มครองข้อมูลส่วนบุคคล
        </p>
        <li className="privacy-policy-section-title">รายละเอียดสำหรับติดต่อบริษัทฯ</li>
        <p>
          หากท่านมีความประสงค์จะติดต่อบริษัทฯ เพื่อใช้สิทธิเกี่ยวกับข้อมูลส่วนบุคคลของท่าน
          หรือหากท่านมีข้อสงสัยประการใดเกี่ยวกับข้อมูลส่วนบุคคลของท่านตามนโยบายความเป็นส่วนตัวนี้ สามารถติดต่อบริษัทฯ
          หรือเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลได้ที่
        </p>
      </ol>
      <p>บริษัท เฟรเซอร์ส พร็อพเพอร์ตี้ (ประเทศไทย) จำกัด (มหาชน)</p>
      <p>944 มิตรทาวน์ ออฟฟิศ ทาวเวอร์ ถนนพระราม 4 ชั้นที่ 22-23 แขวงวังใหม่ เขตปทุมวัน กรุงเทพมหานคร 10330</p>
      <p>
        อีเมล{' '}
        <a href="mailto:th.fpt.dpo@frasersproperty.com" className="privacy-policy-contact-details">
          th.fpt.dpo@frasersproperty.com
        </a>
      </p>
      <p>
        หมายเลขโทรศัพท์{' '}
        <a href="tel:024830000" className="privacy-policy-contact-details">
          02 483 0000
        </a>
      </p>
    </Container>
  );
}
