import { capitalize, toUpper } from 'lodash';

/*
    Formats translation by capitalizing each beginning word after the part that was splitted
    e.g. credit/debit cards => (sentence, '/') => Credit/Debit cards
  */
export const capitalizeTranslationSection = (sentence, splitLocation) => {
  return sentence.split(splitLocation).map(capitalize).join(splitLocation);
};

/*
    Formats translation by captalizing the first word in the sentence and converts all acronyms to upper case
    e.g. i will send you my id card details through sms => (sentence, ' ', ['id, sms']) => I will send you my ID card details through SMS
  */
export const capitalizeTranslationWithAcronyms = (sentence, splitLocation, acronyms) => {
  return capitalize(sentence)
    .split(splitLocation)
    .map((word) => (acronyms.includes(word) ? toUpper(word) : word))
    .join(splitLocation);
};

/*
    Formats translation by captalizing the first word in the sentence and any specified words,
    as well as converts all acronyms to upper case
    e.g. address as on thai national id card => (sentence, ' ', ['id'], ['thai']) => Address as on Thai national ID card
  */
export const capitalizeTranslationWithAcronymsAndWords = (sentence, splitLocation, acronyms, words) => {
  return (
    capitalize(sentence)
      .split(splitLocation)
      // Is current word an acronym? If not, is it one of the words to capitalize?
      .map((word) => (acronyms.includes(word) ? toUpper(word) : words.includes(word) ? capitalize(word) : word))
      .join(splitLocation)
  );
};
