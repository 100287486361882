import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import TopLeftImage from '../../../assets/images/project-info-top-left.png';
import TopRightImage from '../../../assets/images/project-info-top-right.png';
import BottomLeftImage from '../../../assets/images/project-info-bottom-left.png';
import BottomRightImage from '../../../assets/images/project-info-bottom-right.png';
import OneBedroomIconSVG from '../../../assets/one-bedroom.svg';
import OneBedroomExtraIconSVG from '../../../assets/one-bedroom-extra.svg';
import OneBedroomPlusIconSVG from '../../../assets/one-bedroom-plus.svg';
import { useWatchScreenWidth } from '../../../hooks/useWatchScreenWidth';

import '../../../css/ProjectInformationTab.min.css';

const HEADING = 'เติมเต็ม ทุกไลฟ์ สไตล์คนเมือง ให้คุณใกล้กับทุกสิ่งกับคอนโดมิเนียมใหม่!';

export default function ProjectInformationTab() {
  const { screenWidth } = useWatchScreenWidth();
  const addLineBreak = (sentence) => {
    switch (sentence) {
      case HEADING:
        if (screenWidth < 319) {
          return (
            <>
              เติมเต็ม ทุกไลฟ์ สไตล์คนเมือง
              <br />
              ให้คุณใกล้กับทุกสิ่ง กับคอนโดมิเนียมใหม่!
              <br />
            </>
          );
        }
        return (
          <>
            เติมเต็ม ทุกไลฟ์ สไตล์คนเมือง ให้คุณใกล้กับทุกสิ่ง
            <br />
            กับคอนโดมิเนียมใหม่!
          </>
        );
      default:
        return sentence;
    }
  };

  return (
    <>
      <Row>
        <Col className="project-information-tab-heading">
          <p>
            {addLineBreak(HEADING)}
            <span> KLOS RATCHADA 7</span>
          </p>
        </Col>
      </Row>
      <Row className="entire-project-information-row">
        <Col>
          <Image src={`${process.env.REACT_APP_IMAGE_BASE_URL}/img_property_detail.png`} fluid />
        </Col>
        <Col className="detailed-project-information-column">
          <Row xs={3} className="project-information-row">
            <Col xs={3} md={4} xl={2}>
              ชื่อโครงการ
            </Col>
            <Col xs={4} md={4} lg={3} className="project-information-row-separator">
              :
            </Col>
            <Col xs={5} md={4} xl={7}>
              โคลส รัชดา 7
            </Col>
          </Row>
          <Row xs={3} className="project-information-row">
            <Col xs={3} md={4} xl={2}>
              พื้นที่โครงการ
            </Col>
            <Col xs={4} md={4} lg={3} className="project-information-row-separator">
              :
            </Col>
            <Col xs={5} md={4} xl={7}>
              0 - 3 - 14.5 ไร่
            </Col>
          </Row>
          <Row xs={3} className="project-information-row">
            <Col xs={3} md={4} xl={2}>
              รูปแบบ
            </Col>
            <Col xs={4} md={4} lg={3} className="project-information-row-separator">
              :
            </Col>
            <Col xs={5} md={4} xl={7}>
              อาคาร 8 ชั้น 1 อาคาร
            </Col>
          </Row>
          <Row xs={3} className="project-information-row">
            <Col xs={3} md={4} xl={2}>
              จำนวนยูนิต
            </Col>
            <Col xs={4} md={4} lg={3} className="project-information-row-separator">
              :
            </Col>
            <Col xs={5} md={4} xl={7}>
              111 ยูนิต
            </Col>
          </Row>
          <Row xs={3} className="project-information-row">
            <Col xs={3} md={4} xl={2}>
              พื้นที่จอดรถ
            </Col>
            <Col xs={4} md={4} lg={3} className="project-information-row-separator">
              :
            </Col>
            <Col xs={5} md={4} xl={7}>
              ทั้งหมด 43 คัน
            </Col>
          </Row>

          <Row className="room-types-heading">
            <p>ประเภทห้อง</p>
          </Row>
          <Row sm={3}>
            <Col>
              <Row className="room-type-row">
                <Col>
                  <Image src={OneBedroomIconSVG} />
                </Col>
                <Col>One Bedroom</Col>
              </Row>
            </Col>
            <Col>
              <Row className="room-type-row">
                <Col>
                  <Image src={OneBedroomExtraIconSVG} />
                </Col>
                <Col>One Bedroom Extra</Col>
              </Row>
            </Col>
            <Col>
              <Row className="room-type-row">
                <Col>
                  <Image src={OneBedroomPlusIconSVG} />
                </Col>
                <Col>One Bedroom Plus</Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
