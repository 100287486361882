import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import { FrasersLogoFooterSVG } from '../../assets';
import { PhoneIconSVG } from '../../assets';
import { FacebookIconSVG } from '../../assets';
import { LineIconSVG } from '../../assets';
import { InstagramIconSVG } from '../../assets';
import { YouTubeIconSVG } from '../../assets';
import '../../css/Footer.min.css';

export default function Footer() {
  return (
    <footer className="footer-container">
      <Container bsPrefix="footer-logo">
        <Image src={FrasersLogoFooterSVG} fluid />
      </Container>
      <Container className="footer-bottom-container">
        <Row className="footer-icons-row">
          <Col>
            <a href="tel:0632076000">
              <Image src={PhoneIconSVG} fluid />
            </a>
          </Col>
          <Col>
            <a href="https://www.facebook.com/profile.php?id=61551475042677" rel="noreferrer" target="_blank">
              <Image src={FacebookIconSVG} fluid />
            </a>
          </Col>
          <Col>
            <a href="https://lin.ee/RDuKRCZ" rel="noreferrer" target="_blank">
              <Image src={LineIconSVG} fluid />
            </a>
          </Col>
          <Col>
            <a href="https://www.instagram.com/frasers_property_home/" rel="noreferrer" target="_blank">
              <Image src={InstagramIconSVG} fluid />
            </a>
          </Col>
          <Col>
            <a href="https://www.youtube.com/@GoldenlandResidence" rel="noreferrer" target="_blank">
              <Image src={YouTubeIconSVG} fluid />
            </a>
          </Col>
        </Row>
        <Row className="footer-texts-row">
          <Col xs={6}>
            <a href="/privacy-policy" rel="noreferrer" target="_blank">
              PRIVACY POLICY
            </a>
          </Col>
          <Col xs={6}>
            <a href="/terms-and-conditions" rel="noreferrer" target="_blank">
              TERMS & CONDITIONS
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
