import gsap from 'gsap';
import { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';

import { LeftQuoteSVG, RightQuoteSVG } from '../../../assets';
import '../../../css/AmenitiesTab.min.css';
import { customStyles, DropdownIndicator } from '../../../reactSelectCustomStyles';

const BASE_PATH = process.env.REACT_APP_IMAGE_BASE_URL;
const IMAGES = [
  [`${BASE_PATH}/garden/1.png`],
  [`${BASE_PATH}/rooftop/1.png`],
  [`${BASE_PATH}/pool/1.png`],
  [`${BASE_PATH}/fitness/1.png`, `${BASE_PATH}/fitness/2.png`],
  [`${BASE_PATH}/lobby/1.png`],
  [`${BASE_PATH}/co-working/1.png`, `${BASE_PATH}/co-working/2.png`, `${BASE_PATH}/co-working/3.png`],
];
const OPTIONS = [
  { label: 'GARDEN LOBBY', value: 0 },
  { label: 'ROOFTOP GARDEN', value: 1 },
  { label: 'LAP POOL', value: 2 },
  { label: 'ACTIVE FITNESS', value: 3 },
  { label: 'EXCLUSIVE LOBBY', value: 4 },
  { label: 'CO - THINKING SPACE', value: 5 },
];

export default function AmenitiesTab() {
  const [tab, setTab] = useState({
    activeSlide: 0,
    activeTab: 0,
    content: [
      { title: 'GARDEN LOBBY', subtitle: 'เติมความสดชื่น สูดออกซิเจนได้เต็มปอด ในบรรยากาศสงบและเป็นส่วนตัว' },
      { title: 'ROOFTOP GARDEN', subtitle: 'สวนสวยสุดชิล เติมเต็มฟิลวันพักผ่อน' },
      { title: 'LAP POOL', subtitle: 'สระว่ายน้ำ วิวสวยใกล้สวน กลับมาอยู่กับความเป็นตัวเองได้ทุกวัน' },
      { title: 'ACTIVE FITNESS', subtitle: 'ออกกำลังกายเช้า เข้างานต่อสบาย หรือเลิกงานเสร็จมาเข้าฟิตเนสก็สุดใกล้' },
      { title: 'EXCLUSIVE LOBBY', subtitle: 'เติมเต็มเวลาที่อยากพัก เพื่อนทักก็เต็มที่กับ Lobby ที่รองรับทุกความต้องการของคุณ' },
      { title: 'CO - THINKING SPACE', subtitle: 'อิสระกับความคิดที่ไร้กรอบบน Co - Thinking Space สเปซที่ใช่คิดอะไรก็สนุก' },
    ],
  });

  const { control, watch } = useForm({
    amenity: OPTIONS[0],
  });
  const amenityWatch = watch('amenity');
  useEffect(() => {
    setTab((prev) => ({ ...prev, activeTab: amenityWatch }));
  }, [amenityWatch]);
  useEffect(() => {
    gsap.fromTo('.amenities-tab-carousel-container', { opacity: 0 }, { opacity: 1, duration: 0.3, ease: 'circ.in' });
    setTab((prev) => ({ ...prev, activeSlide: 0 }));
  }, [tab.activeTab]);

  return (
    <div className="amenities-tab-container">
      {/* MOBILE */}
      <div className="amenities-tab-dropdown">
        <Controller
          control={control}
          name="amenity"
          render={({ field, value }) => (
            <Select
              components={{ DropdownIndicator }}
              defaultValue={OPTIONS[0]}
              isSearchable={false}
              onChange={(option) => field.onChange(option?.value)}
              options={OPTIONS || []}
              styles={customStyles}
              value={OPTIONS?.find((option) => option?.value === value)}
            />
          )}
        />
      </div>

      <div className="amenities-tab-carousel-container">
        <Carousel
          activeIndex={tab.activeSlide}
          className="amenities-tab-carousel"
          controls={false}
          indicators={(IMAGES[tab.activeTab] ?? IMAGES[0]).length > 1}
          interval={2000}
          onSelect={(activeSlide) => setTab((prev) => ({ ...prev, activeSlide }))}
        >
          {(IMAGES[tab.activeTab] ?? IMAGES[0])?.map((image, index) => (
            <Carousel.Item key={index}>
              <Image alt="#" src={image} fluid />
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="amenities-tab-carousel-subtitle">
          <Image className="subtitle-left-quote" src={LeftQuoteSVG} />
          <p>{tab.content[tab.activeTab]?.subtitle ?? tab.content[0]?.subtitle}</p>
          <Image className="subtitle-right-quote" src={RightQuoteSVG} />
        </div>
      </div>

      {/* DESKTOP */}
      <div className="amenities-tab-image-text-container">
        {tab.content.map((amenity, index) => (
          <button
            className={`text-title-container ${
              tab.activeTab ? (tab.activeTab === index ? 'active' : '') : index === 0 ? 'active' : ''
            }`}
            key={index}
            onClick={() => setTab((prev) => ({ ...prev, activeTab: index }))}
          >
            <p className="text-title">{amenity.title}</p>
          </button>
        ))}
      </div>
    </div>
  );
}
