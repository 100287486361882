import { axiosInstance } from '../../customAxios';

export default async function favoriteUpsert(data) {
  const bodyFormData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    bodyFormData.append(key, value);
  }

  try {
    const response = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL}/api/v1/favorites`, bodyFormData);
    return { status: true, data: response?.data?.result };
  } catch (error) {
    return { status: false, error };
  }
}
