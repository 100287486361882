import axios from 'axios';

export default async function listSubdistricts() {
  try {
    const {
      data: { result },
    } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/mst/subdistricts`);
    return {
      status: true,
      data: result.map((subdistrict) => ({
        label: subdistrict.name_th,
        value: subdistrict.id,
        postalCode: subdistrict.postcode,
        districtValue: subdistrict.district_id,
      })),
    };
  } catch (error) {
    return { status: false, error };
  }
}
