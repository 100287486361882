import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import { ParallaxBanner } from 'react-scroll-parallax';
import { Link } from 'wouter';

import '../../css/banner.min.css';

import { useState } from 'react';
import CountdownTimer from './countdown-timer';
import { sizedBoxVerticalXXS } from '../sized-box';

const heroShot = require('../../assets/images/banner.jpg');
export default function Banner({ isCountdownActive, isCountdownLoading, isRegistrationOpen, timeDifference }) {
  const [showCountdown, setShowCountdown] = useState(true);

  return (
    <Container bsPrefix="banner">
      <ParallaxBanner className="parallax-banner" layers={[{ image: heroShot, speed: -15 }]} />
      <Container bsPrefix="banner-container">
        <Image src={require('../../assets/banner-logo.png')} fluid />
        <p className={`countdown-text ${isCountdownActive && showCountdown ? '' : 'invisible'}`}>นับถอยหลังสู่การจองออนไลน์</p>
      </Container>
      <div className={`countdown-container ${isCountdownActive && showCountdown ? '' : 'invisible'}`}>
        <CountdownTimer
          isLoading={isCountdownLoading}
          timeDifference={timeDifference}
          toggleShowCountdown={() => setShowCountdown(false)}
        />
      </div>
      {!localStorage.getItem('access_token') && (
        <>
          <Container className={`banner-button-container ${isRegistrationOpen ? '' : 'invisible'}`} fluid>
            {sizedBoxVerticalXXS}
            <Link href="/register">
              <Button variant="danger">ลงทะเบียน</Button>
            </Link>
          </Container>
        </>
      )}
    </Container>
  );
}
