/* eslint-disable import/no-named-as-default */
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import logger from 'redux-logger';
import { combineReducers } from 'redux';

import entitiesStore from './entitiesStore';
import domainsStore from './domainStore'; // lose when refetch

const rootReducer = combineReducers({
  entities: entitiesStore,
  domains: domainsStore,
});

export const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['domains'],
  whitelist: ['entities'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const storeWithLogger = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(logger),
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const IS_PROD = true;
export const persistor = persistStore(IS_PROD ? store : storeWithLogger);
// export const persistor = persistStore(storeWithLogger);
