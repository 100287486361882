import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useLocation from 'wouter/use-location';

export default function CountdownCompleteModal({ show, toggleShow }) {
  const [, setLocation] = useLocation();
  const handleConfirm = () => {
    toggleShow();
    setLocation('~/', { replace: true });
  };

  return (
    <Modal centered show={show}>
      <Modal.Header>
        <Modal.Title>กรุณากลับหน้าหลักเพื่อทำรายการใหม่</Modal.Title>
      </Modal.Header>
      <Modal.Body></Modal.Body>
      <Modal.Footer>
        <Button onClick={handleConfirm} variant="danger">
          กลับหน้าหลัก
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
