import axios from 'axios';

export default async function listDistricts() {
  try {
    const {
      data: { result },
    } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/mst/districts`);
    return {
      status: true,
      data: result.map((district) => ({
        label: district.name_th,
        value: district.id,
        provinceValue: district.province_id,
      })),
    };
  } catch (error) {
    return { status: false, error };
  }
}
