import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { getStoredState } from 'redux-persist';
import { persistConfig } from './redux/store';

const storedState = await getStoredState(persistConfig);
const storedLanguage = storedState?.entities?.lang;
i18next.use(initReactI18next).use(Backend).init({
  debug: false,
  fallbackLng: /*storedLanguage ||*/ 'th',
});
