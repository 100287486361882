import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Countdown, { zeroPad } from 'react-countdown';
import { useTranslation } from 'react-i18next';

import {
  StepBookingDetailsIconSVG,
  StepCompleteIconSVG,
  StepFillPersonalInformationIconSVG,
  StepMakePaymentIconSVG,
} from '../../assets';
import { useWatchScreenWidth } from '../../hooks/useWatchScreenWidth';
import CountdownCompleteModal from '../modals/countdown-complete-modal/';

import '../../css/StepProgressBar.min.css';

// Highlight appropriate steps up to currentStep (starting from 1)
export default function StepProgressBar({ currentStep = 0 }) {
  const expireAt = sessionStorage.getItem('expireAt');
  const { t } = useTranslation();

  const { screenWidth } = useWatchScreenWidth();
  const addLineBreak = (sentence) => {
    switch (sentence) {
      case 'รายละเอียดการจอง':
        if (screenWidth < 494 || (screenWidth >= 576 && screenWidth < 625)) {
          return (
            <>
              รายละเอียด
              <br />
              การจอง
            </>
          );
        }
        return 'รายละเอียดการจอง';
      case 'กรอกข้อมูลส่วนตัว':
        if (screenWidth < 477 || (screenWidth >= 576 && screenWidth < 602)) {
          return (
            <>
              กรอกข้อมูล
              <br />
              ส่วนตัว
            </>
          );
        }
        return 'กรอกข้อมูลส่วนตัว';
      default:
        return sentence;
    }
  };

  const [show, setShow] = useState(false);
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      setShow(true);
    } else {
      return (
        <>
          <p id="booking-countdown-text">
            {capitalize(t('time-left-for-booking'))}
            <span id="booking-countdown-time">{` ${zeroPad(minutes)}:${zeroPad(seconds)} `}</span>
            {t('minute')}
          </p>
        </>
      );
    }
  };

  return (
    <>
      <Container className="step-progress-bar-container" fluid>
        <p id="step-progress-bar-heading">แสดงสถานะการจอง</p>
        <Row xs={4} className="step-progress-bar-row">
          <Col>
            <Row className={`step-row ${currentStep >= 1 && 'highlighted'}`}>
              <Col className="step-column">
                <span className={`step-circle ${currentStep >= 1 && 'highlighted'}`}>
                  <Image height={16} width={16} src={StepBookingDetailsIconSVG} />
                </span>
                <div className={`step-line ${currentStep >= 2 && 'highlighted'}`}></div>
              </Col>
              <Col className="step-column">{addLineBreak(capitalize(t('booking-details')), screenWidth)}</Col>
            </Row>
          </Col>
          <Col>
            <Row className={`step-row ${currentStep >= 2 && 'highlighted'}`}>
              <Col className="step-column">
                <span className={`step-circle ${currentStep >= 2 && 'highlighted'}`}>
                  <Image height={16} width={16} src={StepFillPersonalInformationIconSVG} />
                </span>
                <div className={`step-line ${currentStep >= 3 && 'highlighted'}`}></div>
              </Col>
              <Col className="step-column">{addLineBreak(capitalize(t('fill-personal-information')), screenWidth)}</Col>
            </Row>
          </Col>
          <Col>
            <Row className={`step-row ${currentStep >= 3 && 'highlighted'}`}>
              <Col className="step-column">
                <span className={`step-circle ${currentStep >= 3 && 'highlighted'}`}>
                  <Image height={16} width={16} src={StepMakePaymentIconSVG} />
                </span>
                <div className={`step-line ${currentStep === 4 && 'highlighted'}`}></div>
              </Col>
              <Col className="step-column">{capitalize(t('payment'))}</Col>
            </Row>
          </Col>
          <Col>
            <Row className={`step-row ${currentStep === 4 && 'highlighted'}`}>
              <Col className="step-column">
                <span className={`step-circle ${currentStep === 4 && 'highlighted'}`}>
                  <Image height={16} width={16} src={StepCompleteIconSVG} />
                </span>
              </Col>
              <Col className="step-column">{capitalize(t('booking-complete'))}</Col>
            </Row>
          </Col>
        </Row>
        {currentStep < 4 && <Countdown date={Date.now() + (dayjs(expireAt) - dayjs())} renderer={renderer} />}
      </Container>
      <CountdownCompleteModal show={show} toggleShow={() => setShow((show) => !show)} />
    </>
  );
}
