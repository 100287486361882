import axios from 'axios';

export default async function listFloors() {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/floors`);
    return {
      status: true,
      data: response?.data?.result?.map((option) => ({
        label: option.floor_level,
        value: option.id,
        floorplan: {
          plan: option.floor_plan,
          planImage: option.floor_image,
        },
      })),
    };
  } catch (error) {
    return { status: false, error };
  }
}
