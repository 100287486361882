import { useEffect, useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import getBookingPeriod from '../../../api/booking-period';
import { listFloors, listUnitTypes } from '../../../api/master-data';
import { favoriteList } from '../../../api/favorite';
import { NoFavoriteIconSVG } from '../../../assets';
import UnitCard from '../../cards/unit-card';
import CustomPagination from '../../pagination/';

import '../../../css/MyFavorite.min.css';

export default function MyFavorite({ bookingInformation }) {
  const [bookingPeriodID, setBookingPeriodID] = useState(null);
  const [floors, setFloors] = useState(null);
  const [unitTypes, setUnitTypes] = useState(null);
  const [page, setPage] = useState({
    activePage: 1,
    content: [],
    paginationItems: [],
  });

  const activePageRef = useRef(1);
  const contentLengthRef = useRef(0);
  const shouldReloadPage = () => {
    // Reload my favorite page if...
    // The user has unfavorited a unit and tries to go to a different page
    if (!!numUnitCards && numUnitCards !== contentLengthRef.current) return true;
    // if (activePageRef.current > page.activePage && numUnitCards !== contentLengthRef.current) return false;
    return false;
  };

  const [numUnitCards, setNumUnitCards] = useState(page.content?.length);
  useEffect(() => {
    const getData = async () => {
      const { data: bookingPeriod } = await getBookingPeriod();
      const { data: floors } = await listFloors();
      const { data: unitTypes } = await listUnitTypes();
      setBookingPeriodID(bookingPeriod?.id);
      setFloors(floors);
      setUnitTypes(unitTypes);
    };

    const getFavoriteUnits = async () => {
      if (shouldReloadPage()) {
        window.location = window.location;
      }
      const { data } = await favoriteList({ page: page.activePage || 1, per_page: 18 });
      setPage((prev) => ({ ...prev, content: data?.result }));
      activePageRef.current = page.activePage;
      contentLengthRef.current = page.content?.length;
      setNumUnitCards(page.content?.length);

      if (page.paginationItems.length === 0) {
        const paginationItems = [];
        for (let i = 1; i <= Math.ceil(data?.totalData / 18); i++) {
          paginationItems.push(i);
        }
        setPage((prev) => ({ ...prev, paginationItems }));
      }
    };

    if (!bookingPeriodID || !floors) {
      getData();
    }
    getFavoriteUnits();
  }, [page.activePage, page.paginationItems.length]);

  const checkChildren = () => {
    const columns = document.querySelector('#favorite-unit-container-row')?.childNodes;
    let numUnitCardsShowing = 0;
    columns?.forEach((column) => {
      if (column.childElementCount > 0) numUnitCardsShowing++;
    });
    setNumUnitCards(numUnitCardsShowing);
  };
  useEffect(() => {
    checkChildren();
  }, [page.activePage]);

  // Refresh page when there no units left on the current page
  useEffect(() => {
    if (numUnitCards === 0 && page.paginationItems.length > 1) {
      window.location = window.location;
    }
  }, [numUnitCards]);

  return (
    <Container className="my-favorite-page-container" fluid>
      <p id="my-favorite-heading">My Favorite</p>
      {page.content?.length === 0 || numUnitCards === 0 ? (
        <Container className="no-content-container">
          <Image src={NoFavoriteIconSVG} />
          <p>No Favorite</p>
        </Container>
      ) : (
        <Container className="favorite-unit-container">
          <Row id="favorite-unit-container-row">
            {page.content?.map((unit) => (
              <Col sm={12} md={6} lg={4}>
                <UnitCard
                  thumbnail={
                    unit?.thumbnail?.full_path ? `${process.env.REACT_APP_IMAGE_BASE_URL}${unit?.thumbnail?.full_path}` : null
                  }
                  numFavorites={unit.favorite_count}
                  numViews={unit.views}
                  isMyFavorite={unit.is_liked}
                  favoriteRemove={checkChildren}
                  unitType={unitTypes?.find((option) => option.value === unit.unit_types_id)}
                  unitSize={unit.size}
                  unitFloor={floors?.find((option) => option.value === unit.unit_floor_id)}
                  unitNumber={unit.unit_no}
                  isSoldOut={
                    bookingInformation?.data?.unit_id === unit?.unit_id
                      ? bookingInformation?.event === 'BookingSucceed'
                      : unit.sale_status?.status === 'BookingSucceed'
                  }
                  unitPriceNow={unit.net_price}
                  {...(unit.net_price === unit.regular_price ? {} : { unitPriceBefore: unit.regular_price })}
                  bookingPeriodID={bookingPeriodID}
                  unitID={unit.unit_id}
                />
              </Col>
            ))}
          </Row>

          {page.paginationItems.length > 1 && <CustomPagination page={page} setPage={setPage} />}
        </Container>
      )}
    </Container>
  );
}
