import { components } from 'react-select';

import { DropdownArrowSVG } from './assets';

export const customStyles = {
  container: (baseStyles) => ({
    ...baseStyles,
    width: '100%',
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: '#fff',
    border: 'none',
    borderBottom: state.isFocused ? '1px solid #ed2024' : '1px solid #a0a0a0',
    borderRadius: '0px',
    boxShadow: 'none',
    ':hover': {
      borderBottom: '1px solid #a0a0a0',
    },
    padding: '6px 0px 8px',
  }),

  clearIndicator: (baseStyles) => ({
    ...baseStyles,
    padding: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    margin: '0px',
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    borderRadius: '16px',
    zIndex: 3,
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    padding: '0px 16px',
    margin: '16px 4px 16px 0px',

    // Firefox's scrollbar styling (no border-radius option)
    scrollbarColor: '#ed2024 #a0a0a0',
    scrollbarGutter: 'stable',
    scrollbarWidth: 'thin',
    // Other browsers' scrollbar styling
    '::-webkit-scrollbar': {
      width: '4px',
    },
    '::-webkit-scrollbar-track': {
      background: '#a0a0a0',
      borderRadius: '16px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#ed2024',
      borderRadius: '16px',
    },
    maxHeight: '190px',

  }),
  menuPortal: (baseStyles) => ({
    ...baseStyles,
    zIndex: 1,
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: 'none',
    borderBottom: state.isFocused ? '1px solid #ed2024' : '1px solid #a0a0a0',
    color: state.isFocused || state.isSelected ? '#ed2024' : '#333333',
    position: 'relative',
    '::after': {
      content: state.isSelected
        ? `url(
        "data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.2414 9.76022C4.58133 10.0799 5.13273 10.0799 5.47266 9.76022L10.7933 4.75608C11.0689 4.49691 11.0689 4.07648 10.7933 3.81732L10.131 3.19438C9.85543 2.93521 9.40855 2.93521 9.13285 3.19438L5.35617 6.74651C5.08047 7.00581 4.63359 7.00581 4.35789 6.74651L2.86715 5.34446C2.59159 5.08516 2.14457 5.08516 1.86901 5.34446L1.20667 5.96727C0.93111 6.22657 0.93111 6.64686 1.20667 6.90616L4.2414 9.76022Z' fill='%23ED2024'/%3E%3C/svg%3E%0A"
      )`
        : '""',
      position: state.isSelected ? 'absolute' : 'initial',
      right: state.isSelected ? '12px' : 'initial',
    },
    ':last-child': {
      borderBottom: 'none',
    },
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isDisabled ? '#A0A0A0' : '#333333',
    margin: '0px',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    lineHeight: '26px',
  }),
};

// ? Find a better way to do this?
// Changed backgroundColor to match the background color in home page
export const customStylesUnitFilter = {
  ...customStyles,
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: '#ECECEC',
    border: 'none',
    borderBottom: state.isFocused ? '1px solid #ed2024' : '1px solid #a0a0a0',
    borderRadius: '0px',
    boxShadow: 'none',
    '&:hover': {
      borderBottom: '1px solid #a0a0a0',
    },
    padding: '6px 0px',
  }),
};

export const DropdownIndicator = (props) => {
  const { isDisabled } = props;
  return (
    <components.DropdownIndicator {...props}>
      <img
        alt="Dropdown arrow icon"
        src={DropdownArrowSVG}
        style={isDisabled ?
          { filter: 'invert(74%) sepia(8%) saturate(9%) hue-rotate(321deg) brightness(85%) contrast(93%)' } :
          { filter: 'invert(19%) sepia(100%) saturate(6109%) hue-rotate(352deg) brightness(102%) contrast(86%)' }}
      />
    </components.DropdownIndicator>
  );
};
