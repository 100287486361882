import axios from 'axios';
import qs from 'qs';

import { axiosInstance } from '../../customAxios';
import { accessToken } from '../../utils/tokens';

export default async function listUnits(bookingPeriod, data) {
  const axiosInstanceNoAuthorization = axios.create();
  if (!accessToken) {
    delete axiosInstanceNoAuthorization.defaults.headers.common['Authorization'];
  }

  try {
    const response = await (accessToken ? axiosInstance : axiosInstanceNoAuthorization).get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/booking/periods/units/${bookingPeriod}?${qs.stringify(
        data
      )}&sort_column=unit_no&sort_direction=asc`
    );
    return { status: true, data: { result: response?.data?.result, totalData: response?.data?.total_data } };
  } catch (error) {
    return { status: false, error };
  }
}
