import _ from 'lodash';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'wouter';

import { listFloors, listUnitSizes, listUnitTypes } from '../../api/master-data/';
import { listUnits } from '../../api/units';
import UnitCard from '../cards/unit-card';
import '../../css/UnitsSection.min.css';
import CustomPagination from '../pagination/';
import { customStylesUnitFilter, DropdownIndicator } from '../../reactSelectCustomStyles';
import { setUnitsOnSale } from '../../redux/entitiesStore';
import FloorplanLoader from '../floorplan-loader/FloorplanLoader';
import { getFloorplanList } from '../../api/floorplan';
import { setSelectedFloor, setSelectedTab } from '../../redux/domainStore';

export default function UnitsSection({ bookingInformation, bookingPeriodID, timeDifference }) {
  const dispatch = useDispatch();
  const [location, setLocation] = useLocation();
  const unitsOnSale = useSelector(({ entities }) => entities?.unitsOnSale);
  const previousTab = useSelector(({ domains }) => domains?.selectedTab);
  const previousFloor = useSelector(({ domains }) => domains?.selectedFloor);

  const defaultValues = {
    floor: !_.isNil(previousFloor) ? previousFloor : null,
    unitType: '',
    size: '',
  };
  const { control, setValue, watch } = useForm({ defaultValues });
  const floorWatch = watch('floor');
  const unitTypeWatch = watch('unitType');
  const sizeWatch = watch('size');

  const [isLoading, setIsLoading] = useState(true);
  const [floors, setFloors] = useState(null);
  const [unitTypes, setUnitTypes] = useState(null);
  const [unitSizes, setUnitSizes] = useState(null);
  const [hoverPlan, setHoverPlan] = useState(null);

  const [page, setPage] = useState({
    activePage: 1,
    content: [],
    paginationItems: [],
  });
  const findSelectedUnitId = useCallback(
    (unitId, returnUnit = false) => {
      const selectedUnit = _.find(unitsOnSale, (item) => item?.unit_no === unitId);

      if (!returnUnit) {
        setLocation(`/unit-details/${bookingPeriodID}/${selectedUnit?.unit_id}`);
      } else {
        return selectedUnit;
      }
    },
    [unitsOnSale]
  );

  useEffect(() => {
    const getFilters = async () => {
      const { data: floors } = await listFloors();
      const { data: unitTypes } = await listUnitTypes();
      const { data: unitSizes } = await listUnitSizes();
      setFloors(floors);
      setUnitTypes(unitTypes);
      setUnitSizes(unitSizes);
    };

    const getUnits = async () => {
      const { status, data } = await listUnits(bookingPeriodID, {
        page: page.activePage || 1,
        per_page: 18,
        ...(floorWatch && { floor_id: floorWatch }),
        ...(unitTypeWatch && { unit_types_id: unitTypeWatch }),
        ...(sizeWatch && { size: sizeWatch }),
      });
      if (status) setPage((prev) => ({ ...prev, content: data?.result }));

      const paginationItems = [];
      for (let i = 1; i <= Math.ceil(data?.totalData / 18); i++) {
        paginationItems.push(i);
      }
      setPage((prev) => ({ ...prev, paginationItems }));
      setIsLoading(false);
    };

    if (!floors || !unitTypes || !unitSizes) {
      getFilters();
    }
    getUnits();
  }, [floorWatch, page.activePage, sizeWatch, unitTypeWatch]);

  const getFloorplan = useCallback(() => {
    const selectedFloorData = _.find(floors, (item) => item?.value === floorWatch);

    const unitsInSelectedFloor = _.filter(unitsOnSale, (item) => item?.unit_floor_id === floorWatch);

    const units = selectedFloorData?.floorplan?.plan?.units;

    const newData = _.map(units, (o) => {
      const unitData = _.find(unitsInSelectedFloor, (item) => o?.unit_no === _.get(item, 'unit_no'));
      const res = { ...o, status: unitData?.sale_status };
      return res;
    });
    const plan = selectedFloorData?.floorplan?.plan;
    const response = {
      floorplan: {
        plan: {
          width: plan?.width,
          height: plan?.height,
          units: newData,
        },
        planImage: selectedFloorData?.floorplan?.planImage,
        label: selectedFloorData?.label,
        // value: selectedFloorData?.floorplan?.value || [],
      },
    };

    return response;
  }, [floorWatch, floors]);

  const [currentTab, setCurrentTab] = useState(previousTab ? previousTab : 'show-all-units');
  const [currentFloor, setCurrentFloor] = useState(previousFloor ? previousFloor : '');
  useEffect(() => {
    if (!_.isNil(previousTab)) {
      setCurrentTab(previousTab);
    }
  }, [previousTab]);
  useEffect(() => {
    dispatch(setSelectedFloor(floorWatch));
  }, [floorWatch]);
  useEffect(() => {
    if (!_.isNil(previousFloor)) {
      setCurrentFloor(previousFloor);
    }
  }, [previousFloor]);
  const floorSelectRef = useRef(null);
  const unitTypeSelectRef = useRef(null);
  const sizeSelectRef = useRef(null);
  useLayoutEffect(() => {
    // dispatch(setSelectedFloor(!_.isNil(previousFloor) ? previousFloor : currentFloor));
    //

    // Set a default value for floor filter when switching to "show-floors-available" tab
    //

    if (currentTab !== 'show-all-units' && !_.isEmpty(floors)) {
      if (_.isNil(previousFloor)) {
        const defaultFloor = _.head(floors) ?? {};
        setValue('floor', floors && defaultFloor?.value);
        floorSelectRef?.current?.setValue({ ...defaultFloor, label: `ชั้น ${defaultFloor?.label}` });
      } else {
        const defaultFloor = _.find(floors, (item) => item.value === previousFloor) ?? {};
        setValue('floor', floors && defaultFloor?.value);
        floorSelectRef?.current?.setValue({ ...defaultFloor, label: `ชั้น ${defaultFloor?.label}` });
      }
    } else {
      // setValue('floor', '');
      // floorSelectRef?.current?.clearValue();
    }
  }, [floors, currentTab, previousFloor]);
  useLayoutEffect(() => {
    if (currentTab === 'show-all-units') {
      setValue('floor', '');
      floorSelectRef?.current?.clearValue();
      setValue('unitType', '');
      unitTypeSelectRef?.current?.clearValue();
      setValue('size', '');
      sizeSelectRef?.current?.clearValue();
    } else {
      setValue('floor', null);
      // floorSelectRef?.current?.clearValue();
      // dispatch(setSelectedFloor(floorWatch));
    }
  }, [currentTab]);

  // Always reset to the first page when a filter value is changed
  useEffect(() => {
    setPage((prev) => ({ ...prev, activePage: 1 }));
  }, [floorWatch, unitTypeWatch, sizeWatch]);

  const storeUnits = async () => {
    // Save all available units in entitiesStore
    const { data } = await listUnits(bookingPeriodID);
    dispatch(setUnitsOnSale(data?.result));
  };

  useEffect(() => {
    storeUnits();
  }, [floorWatch, currentTab]);

  const [unitHover, setUnitHover] = useState({ unitDetails: {}, unitNumber: null, unitPosition: {} });
  useEffect(() => {
    setUnitHover((prev) => ({ ...prev, unitDetails: findSelectedUnitId(unitHover.unitNumber, true) }));
  }, [unitHover.unitNumber]);

  const hasFilter =
    floorWatch !== defaultValues['floor'] || unitTypeWatch !== defaultValues['unitType'] || sizeWatch !== defaultValues['size'];
  const hasUnits = () => {
    if (page.content?.length > 0) return true;
    if (page.content?.length === 0 && hasFilter) return true;
    return false;
  };
  const showUnits = timeDifference <= 8.64 * Math.pow(10, 7) && hasUnits();

  return (
    // * Only show the units when <= 24 hours until period starts AND there are units to display without filters applied
    showUnits && (
      <Container className="units-section-container" fluid>
        <Container className="units-text-container" fluid>
          <p id="units-heading">เลือกห้องที่คุณสนใจ !</p>
          <p>อย่ารอช้า ทุกยูนิตมีจำนวนจำกัด</p>
        </Container>
        <Tab.Container defaultActiveKey={!_.isNil(previousTab) ? previousTab : 'show-all-units'}>
          <Row className="filter-options-row">
            <Col className="filter-options-column">
              <Nav justify variant="pills">
                <Nav.Item
                  onClick={() => {
                    setCurrentTab('show-all-units');
                    dispatch(setSelectedTab('show-all-units'));
                  }}
                >
                  <Nav.Link eventKey="show-all-units">แสดงยูนิตทั้งหมด</Nav.Link>
                </Nav.Item>

                <Nav.Item
                  onClick={() => {
                    setCurrentTab('show-floors-available');
                    dispatch(setSelectedTab('show-floors-available'));
                  }}
                >
                  <Nav.Link eventKey="show-floors-available">แสดงชั้นที่เปิดขาย</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col className="units-dropdown-filters-column">
              <Form className="units-dropdown-filters-form">
                <Container>
                  <Row xs={1} md={3}>
                    <Col {...(currentTab === 'show-floors-available' ? { style: { maxWidth: '630px', width: '100%' } } : {})}>
                      <Form.Group>
                        <Form.Label>ชั้น</Form.Label>
                        <Controller
                          control={control}
                          name="floor"
                          render={({ field, value }) => {
                            return (
                              <Select
                                components={{ DropdownIndicator }}
                                isClearable={currentTab === 'show-floors-available' ? false : true}
                                isSearchable={false}
                                menuPortalTarget={document.body}
                                ref={floorSelectRef}
                                onChange={(option) => {
                                  field.onChange(option?.value);
                                }}
                                options={floors?.map((floor) => ({ ...floor, label: `ชั้น ${floor.label}` })) || []}
                                placeholder="เลือกชั้น"
                                styles={customStylesUnitFilter}
                                value={floors?.find((option) => option?.value === value)}
                              />
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                    {currentTab === 'show-all-units' && (
                      <Col>
                        <Form.Group>
                          <Form.Label>ประเภทห้อง</Form.Label>
                          <Controller
                            control={control}
                            name="unitType"
                            render={({ field, value }) => (
                              <Select
                                components={{ DropdownIndicator }}
                                isClearable
                                isSearchable={false}
                                menuPortalTarget={document.body}
                                ref={unitTypeSelectRef}
                                onChange={(option) => {
                                  field.onChange(option?.value);
                                }}
                                options={unitTypes || []}
                                placeholder="เลือกประเภทห้อง"
                                styles={customStylesUnitFilter}
                                value={unitTypes?.find((option) => option?.value === value)}
                              />
                            )}
                          />
                        </Form.Group>
                      </Col>
                    )}
                    {currentTab === 'show-all-units' && (
                      <Col>
                        <Form.Group>
                          <Form.Label>ขนาดห้อง</Form.Label>
                          <Controller
                            control={control}
                            name="size"
                            render={({ field, value }) => (
                              <Select
                                components={{ DropdownIndicator }}
                                isClearable
                                isSearchable={false}
                                menuPortalTarget={document.body}
                                ref={sizeSelectRef}
                                onChange={(option) => field.onChange(option?.value)}
                                options={unitSizes || []}
                                placeholder="เลือกขนาดห้อง (ตร.ม.)"
                                styles={customStylesUnitFilter}
                                value={unitSizes?.find((option) => option?.value === value)}
                              />
                            )}
                          />
                        </Form.Group>
                      </Col>
                    )}
                  </Row>
                </Container>
              </Form>
            </Col>
            <Col className="tab-content-column">
              <Tab.Content>
                <Tab.Pane eventKey="show-all-units">
                  <Container className="tab-content-container">
                    <p className="selected-units-tab-heading">ยูนิตทั้งหมด</p>
                    <Row sm={1} md={2} lg={3} className="units-row">
                      {isLoading
                        ? null
                        : page.content?.map((unit, index) => (
                            <Col key={index}>
                              <UnitCard
                                thumbnail={
                                  unit?.thumbnail?.full_path
                                    ? `${process.env.REACT_APP_IMAGE_BASE_URL}${unit?.thumbnail?.full_path}`
                                    : null
                                }
                                numFavorites={unit.favorite_count}
                                numViews={unit.views}
                                isMyFavorite={unit.is_liked}
                                unitType={unitTypes?.find((option) => option.value === unit.unit_types_id)}
                                unitSize={unit.size}
                                unitFloor={floors?.find((option) => option.value === unit.unit_floor_id)}
                                unitNumber={unit.unit_no}
                                isSoldOut={
                                  bookingInformation?.data?.unit_id === unit?.unit_id
                                    ? bookingInformation?.event === 'BookingSucceed'
                                    : unit.sale_status?.status === 'BookingSucceed'
                                }
                                unitPriceNow={unit.net_price}
                                {...(unit.net_price === unit.regular_price ? {} : { unitPriceBefore: unit.regular_price })}
                                bookingPeriodID={bookingPeriodID}
                                unitID={unit.unit_id}
                              />
                            </Col>
                          ))}
                    </Row>
                  </Container>
                  {page.paginationItems.length > 1 && <CustomPagination page={page} setPage={setPage} />}
                </Tab.Pane>
                <Tab.Pane eventKey="show-floors-available">
                  <Container className="tab-content-container" fluid>
                    <Container className="floorplan-holder">
                      {!_.isEmpty(floors) ? (
                        <FloorplanLoader
                          floorplan={getFloorplan()}
                          onClickUnit={(id) => findSelectedUnitId(id)}
                          onHover={(id, x, y) => setHoverPlan({ id, x, y })}
                          setUnitHover={(unitNumber) => setUnitHover(unitNumber)}
                        />
                      ) : (
                        <p className="empty-floors-text">ไม่มีข้อมูลชั้นให้แสดง ณ ขณะนี้</p>
                      )}
                      {unitHover.unitNumber && (
                        <div
                          className="float-unit-card"
                          style={{
                            // bottom: hoverPlan?.y + 200,
                            // left: hoverPlan?.x,
                            ...unitHover.unitPosition,
                          }}
                        >
                          <UnitCard
                            thumbnail={
                              unitHover.unitDetails?.thumbnail?.full_path
                                ? `${process.env.REACT_APP_IMAGE_BASE_URL}${unitHover.unitDetails?.thumbnail?.full_path}`
                                : null
                            }
                            numFavorites={unitHover.unitDetails?.favorite_count}
                            numViews={unitHover.unitDetails?.views}
                            isMyFavorite={unitHover.unitDetails?.is_liked}
                            unitType={unitTypes?.find((option) => option.value === unitHover.unitDetails?.unit_types_id)}
                            unitSize={unitHover.unitDetails?.size}
                            unitFloor={floors?.find((option) => option.value === unitHover.unitDetails?.unit_floor_id)}
                            unitNumber={unitHover.unitDetails?.unit_no}
                            isSoldOut={
                              bookingInformation?.data?.unit_id === unitHover.unitDetails?.unit_id
                                ? bookingInformation?.event === 'BookingSucceed'
                                : unitHover.unitDetails?.sale_status?.status === 'BookingSucceed'
                            }
                            unitPriceNow={unitHover.unitDetails?.net_price}
                            {...(unitHover.unitDetails?.net_price === unitHover.unitDetails?.regular_price
                              ? {}
                              : { unitPriceBefore: unitHover.unitDetails?.regular_price })}
                            bookingPeriodID={bookingPeriodID}
                            unitID={unitHover.unitDetails?.unit_id}
                            showOverlay={false}
                          />
                        </div>
                      )}
                    </Container>
                  </Container>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    )
  );
}
