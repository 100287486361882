import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './css/main.min.css';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, storeWithLogger, persistor } from './redux/store';

import ThemeProvider from 'react-bootstrap/ThemeProvider';
import { ParallaxProvider } from 'react-scroll-parallax';

import './i18n';

const IS_PROD = true;

const root = ReactDOM.createRoot(document.getElementById('root'));
// ! Comment out StrictMode when testing
root.render(
  <React.StrictMode>
    <Provider store={IS_PROD ? store : storeWithLogger}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs']}>
          <React.Suspense fallback="Loading...">
            <ParallaxProvider>
              <GoogleReCaptchaProvider
                language="th"
                // LOCAL: 6LfEcJgpAAAAADUvQAHDAh6xYmcDMhBc04WF3rWo
                // STAGING: 6LfycJgpAAAAAD8KAXIR1YCey-ae6m4nXmD9LCWR
                // * UAT: 6Le_RcopAAAAAM0vlU94KRe8dTBLL0zc_nKePM9U
                // ! PROD: 6Lf6RsopAAAAANLt26xtKMDCZrsMPTgzJz16uxmS
                reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
              >
                <App />
              </GoogleReCaptchaProvider>
            </ParallaxProvider>
          </React.Suspense>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
