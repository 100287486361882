import { logout, refresh } from './api/auth';
import Header from './components/header';
import Footer from './components/footer';
import BookingComplete from './components/pages/booking-complete';
import BookingDetails from './components/pages/booking-details';
import EditProfile from './components/pages/edit-profile';
import Home from './components/pages/home';
import MyBooking from './components/pages/my-booking';
import MyBookingOverview from './components/pages/my-booking-overview';
import MyFavorite from './components/pages/my-favorite';
import NotFound from './components/pages/not-found';
import Payment from './components/pages/payment';
import PersonalInformation from './components/pages/personal-information';
import PrivacyPolicy from './components/pages/privacy-policy/PrivacyPolicy';
import Register from './components/pages/register';
import StepProgressBar from './components/step-progress-bar';
import UnitDetails from './components/pages/unit-details';
import { removeAccessToken, removeRefreshToken, setAccessToken, setRefreshToken } from './utils/tokens';

import { useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import { Route, Router, Switch, useLocation } from 'wouter';
import TermsAndConditions from './components/pages/terms-and-conditions/TermsAndConditions';

function App() {
  const socket = useRef(null);
  const [bookingInformation, setBookingInformation] = useState(null);
  const [periodInformation, setPeriodInformation] = useState(null);
  const [showHeader, setShowHeader] = useState(false);

  async function getNewToken() {
    const { status, data, error } = await refresh(localStorage.getItem('access_token'), localStorage.getItem('refresh_token'));
    if (status) {
      setAccessToken(data?.access_token);
      setRefreshToken(data?.refresh_token);

      socket.current = io(process.env.REACT_APP_WEB_SOCKET_HOST, {
        auth: {
          token: 'Bearer ' + data?.access_token,
        },
        transports: ['websocket'],
        transportOptions: {},
      });

      socket.current.on('connected', async (data) => {
        console.log('Socket: CONNECTED W/ TOKEN', data);

        socket.current.on('new-event', (data) => {
          setBookingInformation(data);
          console.log('Socket: NEW-EVENT', data);
        });

        socket.current.on('period_event_button', (data) => {
          setPeriodInformation(data);
          console.log('Socket: PERIOD_EVENT_BUTTON', data);
        });
      });

      setShowHeader(true);
    } else {
      if (error?.response?.status === 401) {
        const { status } = await logout();
        if (status) {
          removeAccessToken();
          removeRefreshToken();
          window.location = window.location;
        }
      }
    }
  }

  useEffect(() => {
    // If the user is logged in, always refresh token before connecting to a websocket. Else, connect to a socket without a token
    if (localStorage.getItem('access_token')) {
      getNewToken();
    } else {
      socket.current = io(process.env.REACT_APP_WEB_SOCKET_HOST, {
        transports: ['websocket'],
        transportOptions: {},
      });

      socket.current.on('connected', async (data) => {
        console.log('Socket: CONNECTED W/OUT TOKEN', data);

        socket.current.on('new-event', (data) => {
          setBookingInformation(data);
          console.log('Socket: NEW-EVENT', data);
        });

        socket.current.on('period_event_button', (data) => {
          setPeriodInformation(data);
          console.log('Socket: PERIOD_EVENT_BUTTON', data);
        });
      });
      setShowHeader(true);
    }
  }, []);

  setTimeout(() => {
    if (!showHeader) setShowHeader(true);
  }, 3000);

  const [step, setStep] = useState(-1);
  const [location] = useLocation();
  useEffect(() => {
    // Reset scroll poisition whenever there's a route change
    if (location !== '/') {
      document.documentElement.scrollTo(0, 0);
    }
    // Prevents user from going to register page if they are logged in
    // Prevents StepProgressBar from showing up in other routes after having visited /booking/... routes
    if (!location.includes('booking')) {
      setStep(-1);
      return;
    }

    let nextStep = undefined;
    if (location === '/unit-details') {
      nextStep = 0;
    } else if (location.includes('booking-details')) {
      nextStep = 1;
    } else if (location.includes('personal-information')) {
      nextStep = 2;
    } else if (location.includes('payment-cards') || location.includes('payment-promptpay')) {
      nextStep = 3;
    } else if (location.includes('booking-complete')) {
      nextStep = 4;
    } else {
      // Is in an invalid /booking/... route
      nextStep = -1;
    }
    setStep(nextStep);
  }, [location]);

  return (
    <>
      {showHeader && <Header />}

      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Switch>
          <Route path="/">
            <Home bookingInformation={bookingInformation} />
          </Route>
          <Route path="/edit-profile" component={EditProfile} />
          <Route path="/my-booking" component={MyBooking} />
          <Route path="/my-booking-overview/:unitID" component={MyBookingOverview} />
          <Route path="/my-favorite">
            <MyFavorite bookingInformation={bookingInformation} />
          </Route>
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/register" component={Register} />
          <Route path="/terms-and-conditions" component={TermsAndConditions} />
          <Route path="/unit-details/:bookingPeriodID/:unitID">
            <UnitDetails bookingInformation={bookingInformation} periodInformation={periodInformation} />
          </Route>
          {step < 0 && <Route path="/:rest*" component={NotFound} />}
        </Switch>

        {/* /booking/... */}
        <Router base="/booking">
          {step > 0 && <StepProgressBar currentStep={step} />}
          <Route path="/booking-details/:bookingPeriodID/:bookingID/:unitID" component={BookingDetails} />
          <Route path="/personal-information/:bookingPeriodID/:bookingID/:unitID" component={PersonalInformation} />
          <Route path="/payment-cards/:bookingPeriodID/:bookingID/:unitID">
            <Payment method="CC" bookingInformation={bookingInformation} />
          </Route>
          <Route path="/payment-promptpay/:bookingPeriodID/:bookingID/:unitID">
            <Payment method="QR" bookingInformation={bookingInformation} />
          </Route>
          <Route path="/booking-complete/:bookingPeriodID/:unitID" component={BookingComplete} />
        </Router>
      </div>

      <Footer />
    </>
  );
}

export default App;
