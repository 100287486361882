import { axiosInstance } from '../../customAxios';

export default async function cancelBooking(bookingID) {
  try {
    const response = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL}/api/v1/booking/${bookingID}/cancel`);
    return { status: true, data: response?.data?.result };
  } catch (error) {
    return { status: false, error };
  }
}
