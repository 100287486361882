import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Pagination from 'react-bootstrap/Pagination';

import { ChevronLeftSVG, ChevronRightSVG } from '../../assets';

export default function CustomPagination({ page, setPage }) {
  return (
    <Pagination>
      <Button
        bsPrefix="pagination-navigation-button pagination-navigation-button-prev"
        disabled={page.activePage === 1}
        onClick={() => setPage((prev) => ({ ...prev, activePage: prev.activePage - 1 }))}
      >
        <Image src={ChevronLeftSVG} />
      </Button>
      {page.paginationItems.map((item) => (
        <Pagination.Item
          key={item}
          active={page.activePage === item}
          onClick={() => setPage((prev) => ({ ...prev, activePage: item }))}
        >
          {item}
        </Pagination.Item>
      ))}
      <Button
        bsPrefix="pagination-navigation-button pagination-navigation-button-next"
        disabled={page.activePage === page.paginationItems.length}
        onClick={() => setPage((prev) => ({ ...prev, activePage: prev.activePage + 1 }))}
      >
        <Image src={ChevronRightSVG} />
      </Button>
    </Pagination>
  );
}
