import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'wouter';

import { getCustomerUnits } from '../../../api/customer';
import { listFloors, listUnitTypes } from '../../../api/master-data';
import BookingDetailsCard from '../../cards/booking-details-card';
import RoomDetailsSummarySection from '../../room-details-summary-section';
import { sizedBoxVerticalXXS, sizedBoxVerticalL } from '../../sized-box';
import { capitalizeTranslationSection } from '../../../utils/formatTranslation';

import '../../../css/MyBookingOverview.min.css';

export default function MyBookingOverview() {
  const params = useParams();
  const { t } = useTranslation();

  const [unitDetails, setUnitDetails] = useState({});
  useEffect(() => {
    async function getDocumentAndUnitDetails() {
      const {
        data: { result: allCustomerUnits },
      } = await getCustomerUnits();
      const { data: floors } = await listFloors();
      const { data: unitTypes } = await listUnitTypes();
      const currentUnit = allCustomerUnits?.find((unit) => unit.unit_id === params.unitID);
      setUnitDetails({
        ...currentUnit,
        unit_floor: floors?.find((option) => option?.value === currentUnit.unit_floor_id)?.label,
        unit_types: unitTypes?.find((option) => option?.value === currentUnit.unit_types_id)?.label,
      });
    }

    getDocumentAndUnitDetails();
  }, []);

  return (
    <>
      {sizedBoxVerticalL}
      <Container className="my-booking-overview-heading-container" fluid>
        <p className="my-booking-overview-heading">
          {capitalizeTranslationSection(t('online-booking-number'), ' ')}
          <br />
          <span>{unitDetails?.sale_status?.invoice_no || '-'}</span>
        </p>
        <div></div>
      </Container>
      {sizedBoxVerticalXXS}
      <RoomDetailsSummarySection
        images={unitDetails.images ? unitDetails.images : null}
        unitNumber={unitDetails.unit_no}
        roomType={unitDetails.unit_types}
        roomSize={unitDetails.size}
        floor={unitDetails.unit_floor}
      />
      <div className="my-booking-overview-booking-details-card">
        <BookingDetailsCard
          roomPrice={unitDetails?.regular_price}
          discount={unitDetails?.discount}
          netPrice={unitDetails?.net_price}
          depositFees={unitDetails?.booking_price}
          contractFees={unitDetails?.contract_fee}
          downPaymentNumberOfInstallments={unitDetails?.monthly_installment_count}
          downPaymentAmountPerInstallment={unitDetails?.monthly_installment}
          balloonPaymentNumberOfInstallments={unitDetails?.balloon_installment_count}
          balloonPaymentAmountPerInstallment={unitDetails?.balloon_installment}
          totalDownPaymentAmount={unitDetails?.total_down_payment}
          paymentType={unitDetails?.sale_status?.payment_channel}
          amountPaid={unitDetails?.sale_status?.payment_detail?.amount}
          paymentDate={unitDetails?.sale_status?.booked_at}
          ownershipTransferFees={unitDetails?.ownership_transfer_fee}
          fullName={`${unitDetails?.first_name} ${unitDetails?.last_name}`}
          thaiNationalIDCardNumber={unitDetails?.id_card_number}
          phoneNumber={unitDetails?.phone_number}
          email={unitDetails?.email}
          addressNumber={unitDetails?.mailing_address_address_number}
          village={unitDetails?.mailing_address_village}
          group={unitDetails?.mailing_address_group}
          alley={unitDetails?.mailing_address_alley}
          street={unitDetails?.mailing_address_street}
          province={unitDetails?.mailing_address_province}
          district={unitDetails?.mailing_address_district}
          subdistrict={unitDetails?.mailing_address_sub_district}
          postalCode={unitDetails?.mailing_address_postal_code}
        />
      </div>
    </>
  );
}
