import { axiosInstance } from '../../customAxios';
import qs from 'qs';

// ? Should the cards be arranged in a certain way? (i.e. pre-sorted?)
export default async function favoriteList(data) {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/v1/favorites?${qs.stringify(data)}`);
    return { status: true, data: { result: response?.data?.result, totalData: response?.data?.total_data } };
  } catch (error) {
    return { status: false, error };
  }
}
