/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSignedIn: false,
  haveToken: false,
  selectedTab: null,
  selectedFloor: undefined
};

export const domainsStore = createSlice({
  name: 'domains',
  initialState,
  reducers: {
    s: (state, action) => {
      state.isSignedIn = action?.payload;
    },
    setIsSignedIn: (state, action) => {
      state.isSignedIn = action?.payload;
    },
    setHaveToken: (state, action) => {
      state.haveToken = action?.payload;
    },
    signOutStore: (state) => {
      state.isSignedIn = false;
      state.haveToken = false;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action?.payload;
    },
    setSelectedFloor: (state, action) => {
      state.selectedFloor = action?.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setIsSignedIn, setHaveToken, signOutStore, setSelectedTab, setSelectedFloor } = domainsStore.actions;

export default domainsStore.reducer;
