import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import { useLocation, useParams } from 'wouter';

import { cancelBooking } from '../../../api/booking';
import WarningModal from '../../modals/warning-modal';
import { addToHistory, checkHistory } from '../../../utils/history';

export default function Payment({ bookingInformation }) {
  const [location, setLocation] = useLocation();
  const params = useParams();
  const [url, setUrl] = useState(sessionStorage.getItem('webPaymentUrl') ?? '');

  const cancelCurrentBooking = async () => {
    const { status } = await cancelBooking(params.bookingID);
    return status;
  };
  const [showWarning, setShowWarning] = useState(false);
  if (checkHistory('payment') > 1 && cancelCurrentBooking()) setLocation('~/', { replace: true });
  useEffect(() => {
    addToHistory('payment');
    if (window.history.state !== 'back') {
      window.history.pushState('back', '', `/booking${location}`);
    }
    window.onbeforeunload = () =>
      bookingInformation?.data?.unit_id === params.unitID && bookingInformation?.event === 'BookingSucceed' ? null : '';
    window.onpopstate = () => setShowWarning(true);

    return () => {
      window.onbeforeunload = null;
      window.onpopstate = null;
    };
  }, []);

  const onWarningConfirm = async () => {
    if (cancelCurrentBooking()) setLocation('~/', { replace: true });
  };

  useEffect(() => {
    if (bookingInformation?.data?.unit_id !== params.unitID) return;

    if (bookingInformation?.event === 'BookingSucceed') {
      setLocation(`/booking-complete/${params?.bookingPeriodID}/${params?.unitID}`);
    } else if (bookingInformation?.event === 'BookingCustomerCanceled') {
      setLocation('~/', { replace: true });
    }
  }, [bookingInformation]);

  return (
    <>
      <Container fluid className="iframe-container">
        {url === '' ? (
          <Spinner animation="grow" role="status" variant="danger">
            <span className="visually-hidden">Loading payment page</span>
          </Spinner>
        ) : url === -1 ? (
          <p className="text-center">Invalid payment response code</p>
        ) : (
          <iframe src={url} title="2C2P payment page"></iframe>
        )}
      </Container>
      <Container className="d-flex justify-content-center cancel-booking-button">
        <Button variant="danger" onClick={() => setShowWarning(true)}>
          ยกเลิกการจอง
        </Button>
      </Container>
      <WarningModal
        onConfirm={onWarningConfirm}
        onHide={() => {
          window.history.pushState('back', '', `/booking${location}`);
          setShowWarning(false);
        }}
        show={showWarning}
      />
    </>
  );
}
