import gsap from 'gsap';
import { capitalize } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from 'react-bootstrap/Image';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import { useTranslation } from 'react-i18next';
// import { useDispatch } from 'react-redux';
import { useLocation } from 'wouter';

import '../../css/Header.min.css';

import {
  ChevronDownSVG,
  CrossIconSVG,
  FrasersLogoHeaderSVG,
  LogoutIconSVG,
  MobileHamburgerMenuSVG,
  ProfileIconSVG,
  // ThaiFlagSVG,
  // UKFlagSVG,
} from '../../assets';
import { logout } from '../../api/auth';
import { cancelBooking } from '../../api/booking';
import getCustomerDetails from '../../api/customer/getCustomerDetails';
import { removeAccessToken, removeRefreshToken } from '../../utils/tokens';
import { capitalizeTranslationSection } from '../../utils/formatTranslation';
import { useWatchScreenWidth } from '../../hooks/useWatchScreenWidth';
import LoginRegisterModal from '../modals/login-register-modal';
// import { setLanguage } from '../../redux/entitiesStore';
import { sizedBoxVerticalXS } from '../sized-box';
import { TransparentDropdown } from '../TransparentDropdown';
import WarningModal from '../modals/warning-modal';

// const LANGUAGES = {
//   th: { nativeName: 'ไทย' },
//   en: { nativeName: 'English' },
// };

export default function Header() {
  const [location, setLocation] = useLocation();
  const { t /*i18n*/ } = useTranslation();

  const userDataRef = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  useEffect(() => {
    gsap.fromTo('#main-header', { y: -30, autoAlpha: 0 }, { y: 0, autoAlpha: 1 });

    async function getDetails() {
      const { status, data } = await getCustomerDetails();
      if (status) {
        userDataRef.current = data;
        setIsLoggedIn(!!data);
      }
    }

    getDetails();
  }, []);

  // const dispatch = useDispatch();
  // const handleLanguageChange = (lng) => {
  //   i18n.changeLanguage(lng);
  //   dispatch(setLanguage(lng));
  // };

  /*
   If screen size changes from desktop to mobile while the hamburger menu is expanded,
   automatically closes the offcanvas
  */
  const { screenWidth } = useWatchScreenWidth();
  useEffect(() => {
    if (screenWidth >= 576) setShowOffcanvas(false);
  }, [screenWidth]);

  async function handleLogout() {
    const { status } = await logout();
    if (status) {
      removeAccessToken();
      removeRefreshToken();
      setIsLoggedIn(false);
      setLocation('~/', { replace: true });
      window.location = window.location;
    }
    if (showWarning) setShowWarning(false);
  }

  const shouldShowWarningModal = location.includes('/booking/') && !location.includes('booking-complete');
  const buttonPressedRef = useRef('');
  async function handleConfirm(buttonPressed) {
    if (!shouldShowWarningModal) return;
    if (!showWarning) {
      setShowWarning(true);
      buttonPressedRef.current = buttonPressed;
      return false;
    }

    // TODO: Find a better way to do this?
    const { status } = await cancelBooking(location.split('/')[4]);
    if (status) {
      setShowWarning(false);
      if (showOffcanvas) setShowOffcanvas(false);
      switch (buttonPressed) {
        case 'home':
          setLocation('~/');
          break;
        case 'edit-profile':
          setLocation('/edit-profile');
          break;
        case 'my-booking':
          setLocation('/my-booking');
          break;
        case 'my-favorite':
          setLocation('/my-favorite');
          break;
        case 'logout':
          handleLogout();
          break;
        default:
          return;
      }
    }
  }

  return (
    <>
      <Container bsPrefix="header-container" id="main-header" role="header">
        <Row xs={2}>
          <Col>
            {/* If in a booking route, show warning modal. If already in home page, does nothing. Else, go to home page  */}
            <a {...(shouldShowWarningModal ? { onClick: () => handleConfirm('home') } : { href: '/' })}>
              <Image className="header-logo" src={FrasersLogoHeaderSVG} fluid />
            </a>
          </Col>
          <Col>
            <Row xs={2} className="header-right-section-row">
              <Col className="header-right-section-column">
                {isLoggedIn ? (
                  <div className="user-dropdown-container-div">
                    <Dropdown
                      bsPrefix="user-dropdown-container"
                      onClick={() => gsap.fromTo('.dropdown-menu', { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.2 })}
                    >
                      <Dropdown.Toggle as={TransparentDropdown}>
                        <Stack direction="horizontal" bsPrefix="user-toggle-container">
                          <Image src={ProfileIconSVG} />
                          <p className="header-username">{`${capitalize(userDataRef.current?.first_name)} ${capitalize(
                            userDataRef.current?.last_name
                          )}`}</p>
                          <Image className="user-dropdown-arrow" height={12} width={12} src={ChevronDownSVG} />
                        </Stack>
                      </Dropdown.Toggle>
                      <Dropdown.Menu align={{ xs: 'start' }} className="user-dropdown-menu">
                        {['edit-profile', 'my-booking', 'my-favorite'].map((dropdownItem, index) => (
                          <a
                            className="dropdown-item"
                            key={index}
                            {...(shouldShowWarningModal
                              ? { onClick: () => handleConfirm(dropdownItem) }
                              : { href: `/${dropdownItem}` })}
                          >
                            {capitalizeTranslationSection(t(dropdownItem), ' ')}
                          </a>
                        ))}
                        <a
                          className="dropdown-item logout-dropdown-item"
                          onClick={() => (shouldShowWarningModal ? handleConfirm('logout') : handleLogout())}
                        >
                          <Image height={18} width={18} src={LogoutIconSVG} />
                          {capitalize(t('logout'))}
                        </a>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ) : (
                  <Button className="sign-in-button" onClick={() => setShowModal(true)} variant="danger">
                    <Image src={ProfileIconSVG} />
                    {capitalize(t('sign-in'))}
                  </Button>
                )}
              </Col>
              <Col
                sm={3}
                className={`${isLoggedIn ? '' : 'd-none'} header-right-section-column header-right-section-second-column`}
              >
                {/* DESKTOP */}
                {/* <Dropdown className="language-selector-dropdown" drop="down-centered">
                  <Dropdown.Toggle as={TransparentDropdown}>
                    <Stack direction="horizontal" bsPrefix="language-selector-container">
                      <>
                        {LANGUAGES[i18n.resolvedLanguage].nativeName === 'ไทย' && <Image src={ThaiFlagSVG} />}
                        {LANGUAGES[i18n.resolvedLanguage].nativeName === 'English' && <Image src={UKFlagSVG} />}
                        <p>{LANGUAGES[i18n.resolvedLanguage].nativeName}</p>
                        <Image height={12} width={12} src={ChevronDownSVG} />
                      </>
                    </Stack>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="language-selector-dropdown-menu">
                    {Object.keys(LANGUAGES).map((lng) => {
                      // Only display language option not currently using
                      if (lng !== i18n.resolvedLanguage) {
                        return (
                          <Dropdown.Item onClick={() => handleLanguageChange(lng)}>
                            <Container bsPrefix="language-selector-dropdown-item-container">
                              {lng === 'th' && <Image src={ThaiFlagSVG} />}
                              {lng === 'en' && <Image src={UKFlagSVG} />}
                              {LANGUAGES[lng].nativeName}
                            </Container>
                          </Dropdown.Item>
                        );
                      }
                      return null;
                    })}
                  </Dropdown.Menu>
                </Dropdown> */}

                {/* MOBILE */}
                {isLoggedIn && (
                  <Image
                    className="mobile-hamburger-menu"
                    onClick={() => setShowOffcanvas((show) => !show)}
                    src={showOffcanvas ? CrossIconSVG : MobileHamburgerMenuSVG}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <LoginRegisterModal show={showModal} onHide={() => setShowModal(false)} />
      <WarningModal
        show={showWarning}
        onConfirm={() => handleConfirm(buttonPressedRef.current)}
        onHide={() => setShowWarning(false)}
      />

      {/* MOBILE - expanded hamburger menu */}
      <Offcanvas backdrop={false} className="header-offcanvas" placement="bottom" show={showOffcanvas}>
        {sizedBoxVerticalXS}
        <Offcanvas.Body>
          <Row className="offcanvas-body-row">
            {isLoggedIn && (
              <Col>
                <Row className="offcanvas-row">
                  {['edit-profile', 'my-booking', 'my-favorite'].map((dropdownItem, index) => (
                    <a
                      className="dropdown-item"
                      key={index}
                      {...(shouldShowWarningModal
                        ? { onClick: () => handleConfirm(dropdownItem) }
                        : { href: `/${dropdownItem}` })}
                    >
                      <Col>{capitalizeTranslationSection(t(dropdownItem), ' ')}</Col>
                    </a>
                  ))}
                  <a
                    className="dropdown-item logout-dropdown-item"
                    onClick={() => (shouldShowWarningModal ? handleConfirm('logout') : handleLogout())}
                  >
                    <Col style={{ columnGap: 4 }}>
                      <Image height={18} width={18} src={LogoutIconSVG} />
                      {capitalize(t('logout'))}
                    </Col>
                  </a>
                </Row>
              </Col>
            )}
            {/* <Col>
              <Row className="offcanvas-row">
                {Object.keys(LANGUAGES).map((lng) => {
                  // Only display language option not currently using
                  if (lng !== i18n.resolvedLanguage) {
                    return (
                      <Col onClick={() => handleLanguageChange(lng)}>
                        <Container bsPrefix="language-selector-dropdown-item-container">
                          {lng === 'th' && <Image src={ThaiFlagSVG} />}
                          {lng === 'en' && <Image src={UKFlagSVG} />}
                          {LANGUAGES[lng].nativeName}
                        </Container>
                      </Col>
                    );
                  }
                  return null;
                })}
              </Row>
            </Col> */}
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
