import { axiosInstance } from '../../customAxios';

export default async function updateCustomerDetails(id, data) {
  const bodyFormData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    bodyFormData.append(key, value);
  }

  try {
    const response = await axiosInstance.put(`${process.env.REACT_APP_BASE_URL}/api/v1/customers/${id}`, bodyFormData);
    return { status: true, data: response };
  } catch (error) {
    return { status: false, error };
  }
}
