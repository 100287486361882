import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Countdown from 'react-countdown';

import '../../../css/CountdownTimer.min.css';

export default function CountdownTimer({ isLoading, timeDifference, toggleShowCountdown }) {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      toggleShowCountdown();
    }
    return (
      <Container className="countdown-timer-container" fluid>
        <Row xs={4} className="countdown-timer-row">
          <Col>
            <Row className="timer-row">
              <Col className="countdown-number">{days}</Col>
              <Col className="countdown-number-unit">วัน</Col>
            </Row>
          </Col>
          <Col>
            <Row className="timer-row">
              <Col className="countdown-number">{hours}</Col>
              <Col className="countdown-number-unit">ชั่วโมง</Col>
            </Row>
          </Col>
          <Col>
            <Row className="timer-row">
              <Col className="countdown-number">{minutes}</Col>
              <Col className="countdown-number-unit">นาที</Col>
            </Row>
          </Col>
          <Col>
            <Row className="timer-row">
              <Col className="countdown-number">{seconds}</Col>
              <Col className="countdown-number-unit">วินาที</Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  };

  return isLoading || <Countdown date={Date.now() + timeDifference} onComplete={toggleShowCountdown} renderer={renderer} />;
}
