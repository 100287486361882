import Container from 'react-bootstrap/Container';

import '../../../css/TermsAndConditions.min.css';

export default function TermsAndConditions() {
  return (
    <Container className="terms-and-conditions-container" fluid>
      <h1 className="terms-and-conditions-heading">ข้อกำหนดและเงื่อนไข</h1>
      <p>โดยที่บริษัท เฟรเซอร์ส พร็อพเพอร์ตี้ โฮม (ประเทศไทย) จำกัด และบริษัทในเครือ (บริษัท) ได้จัดทำเว็บไซต์</p>
      <p>
        เพื่ออำนวยความสะดวกให้แก่ผู้ใช้บริการที่ลงทะเบียนผ่านทางเว็บไซต์{' '}
        <a href="https://booking.kloscondo.com" rel="noreferrer" target="_blank">
          https://booking.kloscondo.com
        </a>{' '}
        ในการดำเนินการจองซื้อห้องชุด หรือที่ดินมีสิ่งปลูกสร้าง ด้วยช่องทางออนไลน์ (“การจองออนไลน์”)
        รวมถึงรับบริการอื่นใดที่ปรากฏบนเว็บไซต์ภายใต้ข้อกำหนดและเงื่อนไขนี้ ผู้ใช้บริการรับทราบและตกลงว่าในการทำธุรกรรม
        และ/หรือใช้บริการใดๆ ผ่านทางเว็บไซต์ ผู้ใช้บริการตกลงยอมรับและจะปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้บริการทุกประการ
      </p>
      <ol>
        <li>ผู้ใช้บริการจะต้องมีอายุไม่ต่ำกว่า 20 ปีบริบูรณ์</li>
        <li>
          ผู้ใช้บริการจะต้องทำการลงทะเบียนสมัครสมาชิกครั้งแรก กับ{' '}
          <a href="https://booking.kloscondo.com" rel="noreferrer" target="_blank">
            https://booking.kloscondo.com
          </a>{' '}
          เพื่อรับสิทธิพิเศษจากทางบริษัทก่อนทำการจอง โดยบริษัทจะส่งสิทธิพิเศษให้กับผู้ใช้บริการโดยช่องทาง{' '}
          <a href="https://booking.kloscondo.com" rel="noreferrer" target="_blank">
            https://booking.kloscondo.com
          </a>{' '}
          การจองออนไลน์ผู้ใช้บริการสามารถเข้าสู่ระบบและทำการจองได้ทันที โดยข้อมูลของผู้ใช้บริการจะถูกจัดเก็บอยู่ในระบบ
          เพื่อให้ผู้ใช้บริการสามารถใช้บริการเว็บไซต์ได้อย่างครบถ้วน
          ผู้ใช้บริการสามารถสมัครเข้าเป็นสมาชิกได้ตามวิธีที่บริษัทกำหนดไว้ในเว็บไซต์ อย่างไรก็ดี
          บริษัทขอสงวนสิทธิ์ที่จะปฏิเสธการสมัครสมาชิกของผู้ใช้บริการบางท่าน
          และบริษัทมีสิทธิยกเลิกสมาชิกหรือระงับการใช้บริการของผู้ใช้บริการทุกท่านได้ตลอดเวลา เช่น
          กรณีละเมิดข้อกำหนดและเงื่อนไขการให้บริการเว็บไซต์
        </li>
        <li>
          ผู้ใช้บริการรับรองว่า ข้อมูลที่สมัครสมาชิกกับ{' '}
          <a href="https://booking.kloscondo.com" rel="noreferrer" target="_blank">
            https://booking.kloscondo.com
          </a>{' '}
          เป็นข้อมูลที่ถูกต้อง ครบถ้วน ตรงตามความเป็นความจริงและเป็นข้อมูลของผู้ใช้บริการ ทุกประการ
          ทั้งนี้หากภายหลังปรากฏว่าข้อมูลได้มีการเปลี่ยนแปลงไป
          ผู้ใช้บริการตกลงที่จะปรับปรุงแก้ไขข้อมูลดังกล่าวให้ถูกต้องและตรงตามข้อมูลปัจจุบันของผู้ใช้บริการ
        </li>
        <li>
          การทำการจองออนไลน์ที่เกิดขึ้นโดยบุคคลอื่น ที่ไม่ใช่ ผู้ใช้บริการเอง หรือกรณีผู้ใช้บริการ
          นำบัญชีและรหัสผ่านไปให้บุคคลอื่นทำการจอง หรือเกิดจากการแอบอ้างใช้บัญชีการจองจากบุคคลอื่น
          โดยมิได้รับอนุญาตจากผู้ใช้บริการ ถือว่าการทำธุรกรรมดังกล่าวเสร็จสมบูรณ์ หากมีความเสียหายเกิดขึ้น
          ผู้ใช้บริการที่เป็นเจ้าของบัญชีตกลงยอมรับผิด เสมือนเป็นผู้กระทำด้วยตัวเอง
        </li>
        <li>
          ผู้ใช้บริการยินยอมให้บริษัทใช้ข้อมูลส่วนบุคคลของผู้ใช้บริการ
          ที่ได้ระบุในการลงทะเบียนสมัครสมาชิกและการจองออนไลน์ในการจัดการบัญชีการใช้งานของผู้ใช้บริการที่มีกับบริษัท
          รวมถึงตรวจสอบและจัดการธุรกรรมการเงินอันเกี่ยวกับการชำระเงินค่าซื้อห้องชุด หรือที่ดินมีสิ่งปลูกสร้าง สำหรับการจองออนไลน์
          รวมถึงการแจ้งสิทธิประโยชน์ ข่าวสารทางการตลาดและโปรโมชันต่างๆ เกี่ยวกับโครงการหรือบริการอื่นใดของบริษัท
        </li>
        <li>
          บริษัทจะไม่เปิดเผยข้อมูลส่วนตัวใดๆ ของผู้ใช้บริการให้บุคคลภายนอกทราบ
          ยกเว้นกรณีที่บริษัทอาจถูกขอให้เปิดเผยข้อมูลส่วนตัวของผู้ใช้บริการ เช่น
          ในกรณีที่มีมูลให้เชื่อว่าการเปิดเผยดังกล่าวอาจป้องกันการคุกคามทางทุจริตบนธุรกรรมออนไลน์
          หรือได้รับการร้องขอจากหน่วยงานราชการอื่นใด หรือ เป็นไปตามบทบัญญัติของกฏมาย
        </li>
        <li>
          บริษัทจะคุ้มครองความเป็นส่วนตัวและข้อมูลส่วนบุคคลของผู้ใช้บริการตามนโยบายความเป็นส่วนตัวของบริษัท ดังนั้นโปรดอ่าน
          นโยบายความเป็นส่วนตัวของบริษัทอย่างละเอียดรอบคอบ โดยสามารถอ่านได้ที่{' '}
          <a href="https://www.kloscondo.com/th/pdpa" rel="noreferrer" target="_blank">
            https://www.kloscondo.com/th/pdpa
          </a>{' '}
          ทั้งนี้
          บริษัทจะใช้ความระมัดระวังและความใส่ใจอย่างสูงสุดเกี่ยวกับมาตรการรักษาความปลอดภัยของบริษัทเพื่อให้มีการรักษาความปลอดภัยของข้อมูลใดๆ
          ของผู้ใช้บริการ
        </li>
        <li>
          บริษัทไม่รับผิดชอบความเสียหายที่เกิดขึ้นจากความผิดพลาดของระบบคอมพิวเตอร์
          ระบบไฟฟ้าที่ขัดข้องที่เป็นสาเหตุทำให้ผู้ใช้บริการไม่สามารถใช้งานระบบการจองออนไลน์ได้
        </li>
        <li>
          ราคาซื้อขาย ส่วนลด โปรโมชัน และ/หรือรายการส่งเสริมการขาย รวมถึงเงื่อนไขการขายต่างๆ ที่ระบุตามที่ได้รับแจ้งจากบริษัท ณ
          วันที่ผู้ใช้บริการทำรายการจองออนไลน์เป็นราคาที่แน่นอนและเป็นที่สุด ดังนั้น ผู้ซื้อจึงไม่อาจร้องขอเพื่อเปลี่ยนแปลงราคา
          เงื่อนไขการขายหรือยกเลิกการจองออนไลน์ดังกล่าว
        </li>
        <li>
          ภาพถ่าย รูปภาพจำลอง ข้อความจากโฆษณาประชาสัมพันธ์บนเว็บไซต์และตามที่ได้รับแจ้งจากบริษัท อาจคลาดเคลื่อนจากการก่อสร้างจริง
          เช่น สีอาคาร การตกแต่ง บริษัทขอสงวนสิทธิ์ในการปรับเปลี่ยนรายละเอียดตามความเหมาะสม
        </li>
        <li>
          ผู้ใช้บริการยอมรับและตกลงว่าเครื่องหมายการค้า เครื่องหมายบริการ ข้อความ ตลอดจนรูปภาพต่างๆ
          ที่ปรากฏบนเว็บไซต์และตามที่ได้รับแจ้งจากบริษัท เป็นกรรมสิทธิ์ของบริษัท ห้ามผู้ใดทำการลอกเลียนแบบ ทำซ้ำ
          หรือดัดแปลงไม่ว่าทั้งหมดหรือบางส่วน นอกจากนี้
          ผู้ใช้บริการสามารถใช้ทรัพย์สินทางปัญญาข้างต้นเพื่อการจองออนไลน์บนเว็บไซต์เท่านั้น และจะไม่ดำเนินการใดๆ
          ที่เป็นการละเมิดทรัพย์สินทางปัญญาไม่ว่าโดยวิธีการใดๆ ก็ตาม ทั้งนี้ หากพบว่ามีการกระทำใดๆ ที่ฝ่าฝืนข้อกำหนดข้างต้น
          บริษัทมีสิทธิระงับ ยกเลิกการเข้าใช้เว็บไซต์ตามที่เห็นสมควร และ/หรือดำเนินการทางกฎหมายกับผู้ฝ่าฝืนดังกล่าวได้ทันที
        </li>
        <li>
          บริษัทขอสงวนสิทธิ์ในการแก้ไข เปลี่ยนแปลง
          หรือยกเลิกข้อกำหนดและเงื่อนไขนี้ไม่ว่าทั้งหมดหรือบางส่วนได้โดยไม่ต้องแจ้งให้ผู้ใช้บริการทราบล่วงหน้า
        </li>
        <li>
          ผู้ใช้บริการรับทราบและเข้าใจเป็นอย่างดีว่า ชื่อผู้จะซื้อที่จะระบุไว้สัญญาจะซื้อจะขายห้องชุด
          หรือสัญญาจะซื้อจะขายที่ดินมีสิ่งปลูกสร้าง จะต้องเป็นชื่อเดียวกันกับชื่อผู้ใช้บริการในระบบการจองออนไลน์นี้เท่านั้น
        </li>
        <li>
          กรณีในวันทำสัญญาผู้ใช้บริการต้องการระบุให้เป็นการซื้อร่วม สามารถแจ้งความประสงค์ไว้ล่วงหน้าไม่น้อยกว่า 7 วัน
          ก่อนถึงกำหนดวันนัดทำสัญญาให้บริษัททราบว่า ในการเข้าทำสัญญาจะซื้อจะขายห้องชุด หรือสัญญาจะซื้อจะขายที่ดินมีสิ่งปลูกสร้าง
          ผู้ใช้บริการอาจโอนสิทธิตามหนังสือจองนี้/หรือให้ระบุชื่อบุคคลอื่นเป็นคู่สัญญาเพิ่มเติมได้โดยไม่คิดค่าธรรมเนียมการเปลี่ยนชื่อผู้ใช้บริการ
          โดยจำกัดให้มีชื่อผู้ใช้บริการอยู่ภายในสัญญาร่วมด้วยเท่านั้น
        </li>
        <li>
          หากผู้ใช้บริการประสงค์ที่จะเปลี่ยนชื่อผู้ใช้บริการในสัญญา
          หรือโอนสิทธิการจองดังกล่าวบริษัทขอสงวนสิทธิ์ในการเรียกเก็บค่าธรรมเนียมในการเปลี่ยนแปลงดังกล่าวตามเงื่อนไขที่บริษัทกำหนด
        </li>
        <li>
          ผู้ใช้บริการจะต้องเข้าทำสัญญาจะซื้อจะขายห้องชุด หรือสัญญาจะซื้อจะขายที่ดินมีสิ่งปลูกสร้าง ภายใน 14 วัน
          วันนับแต่วันที่ผู้ใช้บริการได้รับการยืนยันการจองออนไลน์หรือระยะเวลาที่บริษัทกำหนด
          โดยบริษัทจะแจ้งให้ผู้ใช้บริการทราบผ่านช่องทาง ทางจดหมายอิเล็กทรอนิกส์ (E-mail) ที่ลูกค้าได้ทำการลงทะเบียนไว้
        </li>
        <li>
          กรณีที่ผู้ใช้บริการไม่เข้าทำสัญญาจะซื้อจะขายห้องชุด
          หรือสัญญาจะซื้อจะขายที่ดินมีสิ่งปลูกสร้างกับบริษัทภายในระยะเวลาที่กำหนด
          ผู้ใช้บริการตกลงให้การจองออนไลน์นี้สิ้นสุดลงและถือว่าผู้ใช้บริการสละสิทธิเกี่ยวกับการจองออนไลน์ทั้งหมดและยินยอมให้บริษัทริบเงินมัดจำที่ชำระมาแล้วทั้งหมดไว้เป็นค่าเสียหายได้ทันทีรวมถึงมีสิทธิเสนอขายห้องชุด
          หรือที่ดินมีสิ่งปลูกสร้าง ให้แก่บุคคลอื่นได้โดยไม่ต้องแจ้งให้ผู้ใช้บริการทราบล่วงหน้า โดยผู้ใช้บริการตกลงจะไม่โต้แย้งใดๆ
          ทั้งสิ้น
        </li>
        <li>
          ผู้ใช้บริการตกลงจะไม่เปลี่ยนแปลงแก้ไข ประเภท แบบ ขนาด ชั้นของห้องชุด หรือที่ดินมีสิ่งปลูกสร้าง รวมถึงย้าย
          หรือยกเลิกโครงการ ไม่ว่ากรณีใดๆ ทั้งสิ้นเว้นแต่จะได้รับความยินยอมเป็นลายลักษณ์อักษรจากบริษัท ทั้งนี้
          วิธีการและค่าธรรมเนียมในการเปลี่ยนแปลงดังกล่าวให้เป็นไปตามที่บริษัทกำหนด
        </li>
        <li>
          ข้อกำหนดและเงื่อนไขนี้ รวมถึงใบยืนยันการจองออนไลน์ทางเว็บไซต์นี้ ถือเป็นส่วนหนึ่งของสัญญาจะซื้อจะขายห้องชุด
          หรือสัญญาจะซื้อจะขายที่ดินมีสิ่งปลูกสร้าง และให้อยู่ภายใต้บังคับแห่งกฎหมายไทย
        </li>
        <li>
          ผู้ใช้บริการจะไม่ดำเนินการใช้เว็บไซต์นี้กระทำในสิ่งที่ผิดกฎหมาย รวมทั้งไม่เผยแพร่ไวรัส หรือโปรแกรม
          ที่ทำให้คอมพิวเตอร์เกิดความเสียหาย
        </li>
        <li>
          ผู้ใช้บริการรับทราบว่าข้อมูลส่วนตัวที่ได้ทำการลงทะเบียนเพื่อใช้ในการจองออนไลน์ จะถูกส่งผ่านข้อมูลในระบบ internet
          ทางบริษัทจะพยายามรักษาข้อมูลและระมัดระวัง ไม่ให้บุคคลภายนอกเข้าถึงข้อมูลได้
        </li>
        <li>
          หากผู้ใช้บริการถูกบุคคลอื่นนำบัญชีและรหัสผ่านไปใช้งาน โดยที่ทางผู้ใช้บริการไม่ยินยอม ผู้ใช้บริการต้องรีบแจ้ง ทางบริษัท
          เพื่อให้ยกเลิกการทำธุรกรรมการจองดังกล่าว โดยแจ้งผ่านอีเมล{' '}
          <a href="mailto:th.fph.klosratchada@frasersproperty.com">th.fph.klosratchada@frasersproperty.com</a> หรือโทรศัพท์หมายเลข{' '}
          <a href="tel:0632076000">063-207-6000</a>
        </li>
        <li>
          เมื่อผู้ใช้บริการ ได้ทำการจองและชำระเงินเรียบร้อยผ่านทางระบบรับชำระเงิน (Payment Gateway) ในรูปแบบชำระผ่านบัตรเครดิต,
          บัตรเดบิต หรือการชำระเงินโดยโอนผ่านบัญชีธนาคาร จะไม่สามารถยกเลิกและคืนเงินได้
        </li>
      </ol>
      <p>การชำระเงิน (Payment Gateway)</p>
      <p>
        ผู้ใช้บริการได้ศึกษา เข้าใจและตรวจสอบข้อมูล ราคา รายละเอียด ข้อกำหนด เงื่อนไขต่างๆเกี่ยวกับห้องชุด
        หรือที่ดินมีสิ่งปลูกสร้าง รวมถึงข้อมูลโครงการทั้งหมดที่ปรากฏบนเว็บไซต์โดยละเอียดแล้ว
        และตกลงปฏิบัติตามข้อกำหนดและเงื่อนไขในการจองซื้อห้องชุด หรือที่ดินมีสิ่งปลูกสร้าง ผ่านระบบการจองออนไลน์บนเว็บไซต์ทุกประการ
        ซึ่งเว็บไซต์เป็นเพียงช่องทางอำนวยความสะดวกให้ผู้ใช้บริการ เชื่อมต่อไปยังระบบชำระเงิน ( Payment Gateway ) เท่านั้น
      </p>
      <ol>
        <li>
          ผู้ใช้บริการตกลงและรับรองว่าได้ให้รายละเอียดข้อมูลบัตรเครดิตที่ถูกต้องและมีผลใช้บังคับเพื่อการชำระเงินออนไลน์ ทั้งนี้
          ข้อมูลหรือการกระทำใดๆที่เป็นเท็จจะถูกปฏิเสธหรือมีผลเป็นการยกเลิกการจองซื้อโดยไม่ต้องแจ้งให้ผู้ใช้บริการทราบและบริษัทไม่จำต้องคืนเงินใดๆ
          ให้แก่ผู้ใช้บริการ
        </li>
        <li>
          ผู้ใช้บริการสามารถชำระเงินจองซื้อห้องชุด หรือที่ดินมีสิ่งปลูกสร้าง ผ่านระบบการจองออนไลน์บนเว็บไซต์ด้วยบัตรเครดิต,
          บัตรเดบิต หรือการชำระเงินโดยโอนผ่านบัญชีธนาคาร ซึ่งอาจจำเป็นต้องใช้รหัส Verified
          เพื่อความปลอดภัยในการชำระเงินออนไลน์และยืนยันเพิ่มเติมถึงความเป็นเจ้าของบัตรของผู้ใช้บริการ
        </li>
        <li>
          ผู้ใช้บริการตกลงยินยอมให้ธนาคารผู้ออกบัตรเครดิตของผู้ใช้บริการทำการเรียกเก็บเงินจากบัตรเครดิตเพื่อชำระหนี้ค่าจองซื้อห้องชุด
          หรือที่ดินมีสิ่งปลูกสร้าง ที่เกิดขึ้นจากการใช้บริการบนเว็บไซต์นี้ตามจำนวนที่ปรากฏในใบสรุปการจองออนไลน์
          และ/หรือใบยืนยันการจองออนไลน์
        </li>
        <li>
          การชำระเงินด้วยบัตรเครดิตผ่านระบบการจองออนไลน์
          จะถือว่าสมบูรณ์ต่อเมื่อบริษัทได้เรียกเก็บยอดเงินดังกล่าวจากธนาคารเจ้าของบัตรของผู้ใช้บริการเรียบร้อยแล้วและให้ถือว่าการชำระเงินดังกล่าวเป็นการชำระเงินมัดจำเพื่อการจองซื้อห้องชุด
          หรือที่ดินมีสิ่งปลูกสร้าง ทั้งนี้บริษัทขอสงวนสิทธิ์ไม่คืนเงินจองดังกล่าวในทุกกรณี
        </li>
        <li>
          หากผู้ใช้บริการต้องขออนุมัติสินเชื่อเพื่อซื้อห้องชุด หรือที่ดินมีสิ่งปลูกสร้าง
          ผู้ใช้บริการตกลงให้ความร่วมมือในการตรวจสอบเครดิตบูโร รวมถึงการยื่นขอสินเชื่อตามระยะเวลา
          และสถาบันการเงินที่บริษัทกำหนดมากกว่า 1 แห่ง
        </li>
        <li>
          ภายหลังจากที่ผู้ใช้บริการชำระเงินผ่านระบบการจองออนไลน์เรียบร้อยแล้ว
          บริษัทจะจัดส่งข้อความหรือเอกสารยืนยันการจองออนไลน์ให้แก่ผู้ใช้บริการทางจดหมายอิเล็กทรอนิกส์ (E-mail) ภายใน{' '}
          <span className="terms-and-conditions-text-emphasis">24</span> ชั่วโมง
          กรณีที่ผู้ใช้บริการไม่ได้รับการยืนยันดังกล่าวผู้ใช้บริการสามารถติดต่อบริษัทได้ทางโทรศัพท์หมายเลข{' '}
          <a href="tel:0632076000">063-207-6000</a>
        </li>
      </ol>
    </Container>
  );
}
