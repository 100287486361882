import dayjs from 'dayjs';
import gsap from 'gsap';
import { capitalize } from 'lodash';
import { useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';

import {
  BookingCardDocumentIconSVG,
  BookingCardReceiptIconSVG,
  UnitBedroomIconSVG,
  UnitSizeIconSVG,
  UnitFloorIconSVG,
} from '../../../assets';
import { formatNumber, getNumDecimals } from '../../../utils/numbers';
import { capitalizeTranslationSection } from '../../../utils/formatTranslation';

import '../../../css/BookingCard.min.css';

export default function BookingCard({
  thumbnail = null,
  bookingNumber = null,
  receipt = null,
  unitProject = null,
  unitNumber = null,
  bookingDate = null,
  unitType = null,
  unitSize = null,
  unitFloor = null,
  unitID = null,
}) {
  require('dayjs/locale/th');
  const [, setLocation] = useLocation();
  const { t } = useTranslation();

  const bookingCardRef = useRef(null);
  const bookingCardHoverAnimation = (instruction) => {
    if (instruction === -1) {
      gsap.fromTo(bookingCardRef.current, { scale: 1.01 }, { duration: 0.3, scale: 1 });
    } else {
      gsap.fromTo(bookingCardRef.current, { scale: 1 }, { duration: 0.3, scale: 1.01 });
    }
  };

  return (
    <Card
      className="booking-card"
      onClick={() => setLocation(`/my-booking-overview/${unitID}`)}
      onMouseEnter={bookingCardHoverAnimation}
      onMouseLeave={() => bookingCardHoverAnimation(-1)}
      ref={bookingCardRef}
    >
      {thumbnail ? (
        <Card.Img className="booking-card-image" src={thumbnail} />
      ) : (
        <div className="empty-booking-card-thumbnail"></div>
      )}
      <Card.Body className="booking-card-body">
        <Container fluid>
          <Row xs={2} className="booking-card-top-row">
            <Col>
              <Row className="booking-number-row">
                <Col>{capitalizeTranslationSection(t('online-booking-number'), ' ')}</Col>
                <Col id="booking-number-text">{bookingNumber || '-'}</Col>
              </Row>
            </Col>
            <Col className="booking-icons-col">
              {/* <Container className="d-flex align-items-center"> // ! Document icon hidden
                <Row
                  className="booking-icon-row"
                  onClick={(e) => {
                    e.stopPropagation();
                    console.log('Document');
                  }}
                >
                  <Col>
                    <Image height={24} width={24} src={BookingCardDocumentIconSVG} fluid />
                  </Col>
                  <Col>{capitalize(t('document'))}</Col>
                </Row>
              </Container> */}
              <Container className="d-flex align-items-center justify-content-end">
                {receipt ? (
                  <Row className={`booking-icon-row ${receipt ? '' : 'opacity-50'}`}>
                    {receipt?.full_path && (
                      <a
                        download
                        href={`${process.env.REACT_APP_IMAGE_BASE_URL}${receipt?.full_path}`}
                        onClick={(e) => {
                          if (!receipt) return;
                          e.stopPropagation();
                        }}
                        target="_blank"
                      >
                        <Col>
                          <Image height={24} width={24} src={BookingCardReceiptIconSVG} fluid />
                        </Col>
                        <Col>{capitalize(t('receipt'))}</Col>
                      </a>
                    )}
                  </Row>
                ) : null}
              </Container>
            </Col>
          </Row>
        </Container>
        <Container className="booking-card-bottom-row-container">
          <Row className="booking-card-bottom-row">
            <Col>
              <Row xs={3} className="booking-card-bottom-text-row">
                <Col xs={3}>{capitalize(t('project'))}</Col>
                <Col xs={1} className="text-center">
                  :
                </Col>
                <Col xs={8} className="booking-info-text">
                  {unitProject}
                </Col>
              </Row>
              <Row xs={3} className="booking-card-bottom-text-row">
                <Col xs={3}>{capitalize(t('unit-number'))}</Col>
                <Col xs={1} className="text-center">
                  :
                </Col>
                <Col xs={8} className="booking-info-text">
                  {unitNumber}
                </Col>
              </Row>
              <Row xs={3} className="booking-card-bottom-text-row">
                <Col xs={3}>{capitalize(t('booking-date'))}</Col>
                <Col xs={1} className="text-center">
                  :
                </Col>
                <Col xs={8} className="booking-info-text">
                  {dayjs(bookingDate).locale('th').format('D MMMM YYYY')}
                </Col>
              </Row>
            </Col>

            <Col>
              <Container>
                <Row className="unit-info-row">
                  <Col>
                    <Image src={UnitBedroomIconSVG} fluid />
                  </Col>
                  <Col>{unitType ?? '-'}</Col>
                </Row>
              </Container>
              <Container>
                <Row className="unit-info-row middle-row">
                  <Col>
                    <Image src={UnitSizeIconSVG} fluid />
                  </Col>
                  <Col>{`${formatNumber(unitSize, getNumDecimals(unitSize))} ${t('square-meter')}`}</Col>
                </Row>
              </Container>
              <Container>
                <Row className="unit-info-row">
                  <Col>
                    <Image src={UnitFloorIconSVG} fluid />
                  </Col>
                  <Col>{`Floor ${unitFloor ?? '-'}`}</Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}
