import Container from 'react-bootstrap/Container';

import { sizedBoxVerticalM } from '../../sized-box/';
import RegisterForm from '../../register-form';

import '../../../css/EditProfile.min.css';

export default function EditProfile() {
  return (
    <Container className="edit-profile-page-container" fluid>
      <p id="edit-profile-heading">Edit Profile</p>
      {sizedBoxVerticalM}
      <RegisterForm submitButtonLabel="ยืนยันการแก้ไข" />
    </Container>
  );
}
